.Modal-holder.Permission.modal.show button.YesBtn.brown {
    border: none;
    padding: 5px 31px;
}
.Modal-holder.Permission.modal.show button.SubmitBtn.green {
    border: none;
    padding: 5px 31px;
} 
.Modal-holder.Permission.modal button.btn.btn-primary {
    border: none;
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #e5e5e5e0;
    color: #00000082;
    border-radius: 100px;
    padding: 0px 7px;
}
.Modal-holder.Permission.modal .main-cross {
    text-align: center;
    position: relative;
    top: 14px;
    z-index: 9;
}
.Modal-holder.Permission.modal .modal-content {
   border: none;
    background: transparent 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
}
.Modal-holder.Permission.modal .log_in_form {
    padding: 36px 12px 21px 12px;
}
.Modal-holder.Permission.modal  .log_in_form .heading-holder p.sub-title {
    text-align: center;
    font-weight: 400;
    margin-bottom: 37px;
}

/* Media Query Started */


@media (min-width:321px) and (max-width:485px) {
    .Modal-holder.Permission.modal  .modal-content {
        width: 245px;
        margin: 0px auto;
    }

}
@media (min-width:486px) and (max-width:575px) {
    .Modal-holder.Permission.modal .modal-content {
        width: 50%;
        margin: 0px auto;
    }

   

}