/* ProductCard css started */

.card-body .saveprise {
  background-color: #ffaa00;
  color: #000;
  font-size: 14px;
  padding: 3px 5px;
  width: fit-content;
  border-radius: 4px;
}
.card-body .saveprise span {
  /* background-color: #fff;
  color: #fff; */
  font-size: 14px;
  padding: 3px 5px;
  width: fit-content;
  border-radius: 4px;
}
.card-body .saveprisefont {
  font-size: 14px;
}

.ProductCardSale .Product-card .Main .Image-Tape .tape {
  padding: 6px 0px;
  height: 100%;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}
.ProductCardSale:hover .Product-card .Main .Image-Tape .tape {
  transform: scale(1.1);
}
.ProductCardSale .Product-card .Main .Image-Tape {
  width: 180px;
  height: 181px;
  overflow: hidden;
}
.ProductCardSale .Product-card .Main {
  padding: 49px 0px 41px 0px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  position: relative;
  text-align: -webkit-center;
}
.ProductCardSale .heart {
  position: absolute;
  top: 17px;
  right: 19px;
}
.ProductCardSale .heart .compare img.tape {
  width: 14px;
  height: 23px;
  transition: transform 0.3s ease-in-out;
}
.ProductCardSale .heart .compare img.tape:hover {
  transform: scaleX(-1);
}
.ProductCardSale .card-body {
  z-index: 0;
  margin-top: -16px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 3px #00000029;
  border-radius: 20px 20px 5px 5px;
}
.ProductCardSale .card-body p.sub-text {
  color: black !important;
  margin-bottom: 0px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ProductCardSale .card-body .rating svg.star-svg {
  height: 25px;
  width: 25px;
}
.ProductCardSale .card-body .price {
  display: flex;
  justify-content: space-between;
}
.ProductCardSale .card-body .price span.real-price {
  text-decoration: line-through;
}
.ProductCardSale .card-body .rating {
  display: flex;
  justify-content: space-between;
}
.ProductCardSale .card-body .Cart-Btn button.cart {
  font-weight: 500;
  padding: 6px 16px;
  background: #ffff00 0% 0% no-repeat padding-box;
  border: 1px solid #f2ed07;
  border-radius: 19px;
  color: black !important;
  width: 100%;
}
.ProductCardSale .card-body .Cart-Btn button.buy {
  font-weight: 500;
  padding: 6px 22px;
  background: #ffaa00 0% 0% no-repeat padding-box;
  border: 1px solid #db9302;
  border-radius: 19px;
  color: black !important;
}
.ProductCardSale .card-body .Cart-Btn .heart-icon {
  margin: 0px 0px 0px 8px;
}
.ProductCardSale .Product-card {
  position: relative;
}
.ProductCardSale .card {
  border: none;
}
.ProductCardSale .card-body .Cart-Btn {
  display: flex;
  justify-content: space-between;
}
section.ProductCardSale {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}
.ProductCardSale .triangle-element {
  border-radius: 4px;
  top: 0px;
  width: 126px;
  height: 126px;
  overflow: hidden;
  position: absolute;
}
.ProductCardSale .triangle-element:after {
  content: "";
  background: transparent linear-gradient(157deg, #ff0000 0%, #1d0000d1 100%) 0%
    0% no-repeat padding-box;
  width: 142%;
  height: 142%;
  position: absolute;
  transform: rotate(45deg) translate(-71%, 0);
}
.ProductCardSale .sale-off {
  position: absolute;
  top: 7px;
  left: 9px;
  right: 0px;
  z-index: 9;
}
.ProductCardSale .sale-off p.off {
  font-size: 26px;
  color: white;
  font-weight: bold;
}
