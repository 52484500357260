.toppick_section {
    padding: 50px 0px;
    position: relative;
}

.toppick_section .toptext_span {
    border-bottom: 5px solid #ffaa00;
    padding-bottom: 5px;
}

.toppick_section .titleedd {
    font-size: 34px;
    padding-bottom: 75px;
}
.toppick_section h2.title-main {
    text-align: center;
    font-weight: 800;
}

.toppick_section .swiper {
    padding-bottom: 2%;
}
.toppick_section span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 25px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
}
.toppick_section span.swiper-pagination-bullet {
    width: 31px;
    height: 7px;
    border-radius: 5px;
    background-color: #ffaa00;
    opacity: 1;
}

.toppick_section .swiper {
    position: unset;
}
.toppick_section .swipe_div_main {
    margin-top: 7%;
}
.toppick_section .swiper-pagination-fraction {
    bottom: 0px;
}

@media (min-width: 0px) and (max-width: 485px) {
    .toppick_section .titleedd {
        font-size: 18px;
        padding-bottom: 30px;
    }
    .ProductCard .card-body .Cart-Btn {
     
        margin-top: 3%;
    }
    .toppick_section .toptext_span {
        border-bottom: 2px solid #ffaa00;
    }
    .toppick_section .swiper {
        padding-bottom: 1%;
    }
    .toppick_section {
        padding: 30px 0px;
    }
    .toppick_section {
        margin-bottom: 36%;
    }
    .toppick_section {
        margin-bottom: 25%;
    }

    .ProductCard .card-body .rating {
        align-items: end;
    }
    .ProductCard .card-body .rating svg.star-svg {
        height: 22px;
        width: 22px;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .toppick_section {
        padding: 35px 0px;
    }
    .toppick_section .titleedd {
        font-size: 20px;
        padding-bottom: 35px;
    }

    .toppick_section .toptext_span {
        border-bottom: 2px solid #ffaa00;
    }
    .toppick_section {
        margin-bottom: 30%;
    }
    .toppick_section {
        margin-bottom: 26%;
    }
    .ProductCard .card-body .rating svg.star-svg {
        height: 22px;
        width: 22px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .toppick_section {
        padding: 40px 0px;
    }
    .toppick_section .titleedd {
        font-size: 23px;
        padding-bottom: 45px;
    }

    .toppick_section .toptext_span {
        border-bottom: 3px solid #ffaa00;
    }
    .toppick_section {
        margin-bottom: 28%;
    }
    .toppick_section {
        margin-bottom: 21%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .toppick_section {
        padding: 45px 0px;
    }
    .toppick_section .titleedd {
        font-size: 26px;
        padding-bottom: 55px;
    }

    .toppick_section .toptext_span {
        border-bottom: 4px solid #ffaa00;
    }
    .toppick_section {
        margin-bottom: 21%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .toppick_section {
        margin-bottom: 21%;
    }
}
