/* TopPicks css started */

.TopPicks h1.title-main {
    text-align: center;
}
section.TopPicks {
    margin-top: 7%;
    margin-bottom: 4%;
}
.TopPicks .swiper {
    padding-bottom: 5%;
    margin-top: 4%;
    margin-bottom: 10%;
}
.TopPicks span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 25px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
}
.TopPicks span.swiper-pagination-bullet {
    width: 31px;
    height: 7px;
    border-radius: 5px;
    background-color: #ffaa00;
    opacity: 1;
}
/* TopPicks css End */

/*************************** Media Query Started ********************************/

@media (min-width: 0px) and (max-width: 320px) {
    .TopPicks .swiper {
        margin-top: 11%;
    }
    .TopPicks .swiper {
        padding-bottom: 12%;
    }
    section.TopPicks {
        margin-bottom: 32%;
      }
}
@media (min-width: 321px) and (max-width: 485px) {
    .TopPicks .swiper {
        margin-top: 11%;
    }
    .TopPicks .swiper {
        padding-bottom: 12%;
    }
    section.TopPicks {
        margin-bottom: 30%;
      }
}
@media (min-width: 486px) and (max-width: 575px) {
    section.TopPicks {
        margin-bottom: 30%;
      }
}
@media (min-width: 576px) and (max-width: 767px) {
    .TopPicks .swiper {
    padding-bottom: 7%;
     margin-bottom: 10%;
     margin-top: 7%;
    }
    section.TopPicks {
        margin-bottom: 25%;
      }
}
@media (min-width: 768px) and (max-width: 991px) {
    section.TopPicks {
        margin-bottom: 25%;
      }
}
@media (min-width: 992px) and (max-width: 1199px) {
section.TopPicks {
  margin-bottom: 25%;
}
}