/* Home Page Css Started */

.HomeBanner .Main-Banner img.banner {
    width: 100%;
    height: 100%;
}
.HomeBanner .Main-Banner {
    position: relative;
}
.HomeBanner .Main-Banner .Button-Explore {
    position: absolute;
    top: 59%;
    left: 5%;
}
.Button-Explore button.explore.explore-btn {
    border: none;
    padding: 9px 22px;
    background: #000000;
    border-radius: 29px;
    font-size: 20px;
}
.HomeBanner .Button-Explore button.explore .heart-icon {
    margin: 0px 12px 0px 30px;
}
.HomeBanner .swiper-pagination {
    text-align: justify !important;
}
.HomeBanner .swiper-pagination-fraction,
.HomeBanner .swiper-pagination-custom,
.HomeBanner .swiper-horizontal > .swiper-pagination-bullets,
.HomeBanner .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 8%) !important;
    top: var(--swiper-pagination-top, auto);
    left: 54px !important;
    width: 100%;
}
.HomeBanner .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
}
.HomeBanner span.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}
.HomeBanner .side-images {
    aspect-ratio: 4/2;
}
.HomeBanner .side-images img.banner2 {
    width: 100%;
    height: 100%;
}
section.HomeBanner {
    margin-top: 2%;
}
/* Home Page Css End */

/****************** Media Query Started *******************/
@media (min-width: 0px) and (max-width: 320px) {
    .HomeBanner .Main-Banner .Button-Explore svg.svg-inline--fa.fa-arrow-right.heart-icon {
        margin: 0px 0px -1px 11px;
    }
    .HomeBanner .side-images {
        margin-top: 7%;
    }
    .HomeBanner .swiper-pagination-fraction,
    .HomeBanner .swiper-pagination-custom,
    .HomeBanner .swiper-horizontal > .swiper-pagination-bullets,
    .HomeBanner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 0px) !important;
        left: 22px !important;
    }
    .HomeBanner span.swiper-pagination-bullet {
        width: 8px;
        height: 8px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .HomeBanner .Main-Banner .Button-Explore svg.svg-inline--fa.fa-arrow-right.heart-icon {
        margin: 0px 10px;
    }
    .HomeBanner .swiper-pagination-fraction,
    .HomeBanner .swiper-pagination-custom,
    .HomeBanner .swiper-horizontal > .swiper-pagination-bullets,
    .HomeBanner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 7%) !important;
        left: 29px !important;
    }
    .HomeBanner span.swiper-pagination-bullet {
        width: 9px;
        height: 9px;
    }
    .HomeBanner .side-images {
        margin-top: 5%;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
    .HomeBanner .Main-Banner .Button-Explore svg.svg-inline--fa.fa-arrow-right.heart-icon {
        margin: 0px 10px;
    }
    .HomeBanner .swiper-pagination-fraction,
    .HomeBanner .swiper-pagination-custom,
    .HomeBanner .swiper-horizontal > .swiper-pagination-bullets,
    .HomeBanner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 13%) !important;
        left: 29px !important;
    }
    .HomeBanner span.swiper-pagination-bullet {
        width: 9px;
        height: 9px;
    }
    .HomeBanner .side-images {
        margin-top: 5%;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .HomeBanner .Main-Banner .Button-Explore svg.svg-inline--fa.fa-arrow-right.heart-icon {
        margin: 0px 10px;
    }
    .HomeBanner .swiper-pagination-fraction,
    .HomeBanner .swiper-pagination-custom,
    .HomeBanner .swiper-horizontal > .swiper-pagination-bullets,
    .HomeBanner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 13%) !important;
        left: 44px !important;
    }
    .HomeBanner span.swiper-pagination-bullet {
        width: 11px;
        height: 11px;
    }
    .HomeBanner .side-images {
        margin-top: 4%;
    }
    section.HomeBanner {
        margin-top: 2% !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .HomeBanner .Main-Banner .Button-Explore svg.svg-inline--fa.fa-arrow-right.heart-icon {
        margin: 0px 10px;
    }
    .HomeBanner .swiper-pagination-fraction,
    .HomeBanner .swiper-pagination-custom,
    .HomeBanner .swiper-horizontal > .swiper-pagination-bullets,
    .HomeBanner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 13%) !important;
        left: 44px !important;
    }
    .HomeBanner span.swiper-pagination-bullet {
        width: 11px;
        height: 11px;
    }
    .HomeBanner .side-images {
        margin-top: 4%;
    }
    section.HomeBanner {
        margin-top: 4% !important;
    }
}
