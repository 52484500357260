.LogIn {
    background-image: url("../../../../public/assests/login/login-banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100vh; */
    min-height: 100vh;
    /* max-height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.Modal-holder .LogIn .log_in_form .Main-starting {
    padding: 50px 50px 1px 47px;
}
.Modal-holder .LogIn .main-close .close {
    color: grey;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100px;
    height: 30px;
    width: 30px;
    text-align: center;
    background-color: #e9e9e9;
}
.Modal-holder .LogIn .main-close {
    text-align: -webkit-right;
    margin: 0px 14px;
    padding-top: 13px;
}
.loginname {
    background: #00000059 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    width: 262px;
    padding: 8px 0px;
}

.Modal-holder .LogIn .log_in_form .note-text {
    font-weight: 400;
    text-align: center;
}

.Modal-holder .LogIn .LogIn-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    height: 100%;
    width: 100%;
}

.logtext {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    font-family: "Merriweather", serif !important;
    text-align: center;
    margin-bottom: 0px;
}

.login_main {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 20px;
}

.xmaekkdiv {
    width: 26px;
    height: 26px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
}

.xxmarrk {
    font-size: 16px;
    color: #000000;
}

.passwordfield_holder {
    position: relative;
}

.eye_icon {
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
    font-size: 16px;
    color: #000000;
}

.login_section .form-control {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: none;
}

.login_section .form-control:focus {
    background: #f5f5f5 0% 0% no-repeat padding-box;
}

.form_div {
    padding: 0px 12%;
}

.forgottext {
    text-align: right;
}

.Modal-holder .LogIn .log_in_form {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    margin: 20% 0px;
}

.Modal-holder .LogIn .log_in_form::before {
    content: "";
    position: absolute;
    top: -10px;

    left: calc(50% - 15px);

    width: 30px;

    height: 10px;

    clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.Modal-holder .LogIn .log_in_form .form-label {
    text-align: left;
    font-weight: 500;
}

.Modal-holder .modal-header {
    background: #000;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50px;
    margin: 0px auto;
    width: 50%;
    padding: 6px 0px;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
}

.Modal-holder .modal-header .modal-title {
    font-size: 20px;
    color: #ffffff;
    margin: 0px auto;
}

.Modal-holder .modal-header .btn-close {
    margin: 0px;
    color: #fff;
    filter: invert(1);
    opacity: 1;
}

.Modal-holder .form-control {
    border: none;
    border-radius: 0px;
    background: #f5f5f5;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    padding: 7px 14px;
}
.Modal-holder .LogIn .bg-color .log_in_form .form-control::placeholder {
    font-size: 15px;
    color: #a2a2a2;
}

.LogIn .bg-color .log_in_form .form-control:focus {
    box-shadow: none;
}

.Modal-holder .modal-content {
    border-radius: 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.login-holder-main .main-close {
    display: none;
}

.Modal-holder .LogIn .bg-color .log_in_form .form-text {
    display: flex;
    justify-content: end;
    align-items: center;
}

.Modal-holder .LogIn .bg-color .log_in_form .Forgot_password {
    font-weight: 600;
    color: #ff0000;
    text-align: right;
    cursor: pointer;
}

.Modal-holder .LogIn .bg-color .log_in_form .log_in_with p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    display: flex;
}

.Modal-holder .LogIn .bg-color .back_to_span {
    margin: 15px 0px 21px 0px;
}

.Modal-holder .LogIn .bg-color .back_to_span p {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    color: #000000;
}

.Modal-holder .LogIn .bg-color .log_in_form .btn-holder {
    text-align: center;
    margin-top: 3px;
    display: flex;
    justify-content: center;
}

.Modal-holder .LogIn .bg-color .log_in_form .create_account {
    margin: 0px 0px;
}

.Modal-holder .LogIn .bg-color .log_in_form .create_account p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
}

.LogIn .bg-color .log_in_form .create_account .login_text span {
    text-align: center;
    font-weight: 500;
}

.Modal-holder .LogIn .bg-color .heading-holder p {
    font-size: 15px;
    font-weight: 500;
    color: black;
}

.Modal-holder .LogIn .bg-color .heading-holder span {
    font-size: 15px;
    font-weight: 700;
}

.Modal-holder .LogIn .bg-color .log_in_form .create_account .cancel-text {
    text-align: center;
    text-decoration: underline;
    font-size: 15px;
    display: flex;
    font-weight: 400;
    color: #323232;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.Modal-holder button.SubmitBtn.green.text {
    border: none;
    padding: 9px 67px;
}

.Modal-holder button.SubmitBtn-main.text {
    border: 1px solid grey;
    padding: 8px 73px;
    color: black;
    border-radius: 5px;
}

.Modal-holder button.SubmitBtn-main.green.text {
    padding: 8px 73px;
    border: none;
    color: white;
}

.Modal-holder .Rate {
    display: flex;
    justify-content: space-between;
}

.Modal-holder .mini-text {
    color: #707070;
}

.Modal-holder .camera {
    border-radius: 5px;
    padding: 4px 9px;
    width: fit-content;
    border: 1px solid grey;
    color: #707070;
}

.LogIn .bg-color .log_in_form .log_in_with .log_in_with {
    margin-top: 10%;
}

.LogIn .bg-color .log_in_form .log_in_with p:before,
.LogIn .bg-color .log_in_form .log_in_with p:after {
    color: white;
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #979797;
    margin: 11px 5px;
}

.LogIn .otp-holder .otp-text-holder {
    margin: 0px 5px;
}

.LogIn .bg-color .log_in_form .social_img_holder {
    text-align: center;
}

.LogIn .bg-color .log_in_form .social_img_holder .social_img {
    height: 39px;
}

.LogIn .bg-color .log_in_form .password-field-holder {
    position: relative;
}

.LogIn .bg-color .log_in_form .password-field-holder .form-control {
    padding-right: 30px;
}

.LogIn .bg-color .log_in_form .eye-icon-holder {
    position: absolute;
    top: 0;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.LogIn .bg-color .log_in_form .eye-icon {
    font-size: 11px;
    color: #6c757d;
}

.Modal-holder .mini-text.form-check {
    margin: 12px 38px;
}

.Modal-holder .form-check-input:checked[type="radio"] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23000%27/%3e%3c/svg%3e");
}

.Modal-holder .form-check-input[type="radio"] + .form-check-label {
    font-size: 18px;
}

.Modal-holder .form-check-input:checked {
    background-color: transparent;
    border-color: black;
    font-size: 21px;
}
.Modal-holder .form-check-input[type="radio"] {
    border-radius: 50%;
    font-size: 21px;
}
.login-box-button {
    padding: 8px 0px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    width: 60%;
    margin: 0px auto;
    text-align: center;
    border-radius: 25px;
    position: absolute;
    top: -28px;
    left: 0px;
    right: 0px;
    outline: 10px solid #f0eee8;
    outline-offset: 0px;
}

.login-box-button p {
    font-family: "Merriweather";
    margin-bottom: 0px;
    color: white;
    font-size: 20px;
}
.Modal-holder .LogIn .back_to_span .back-arrow {
    margin: -1px 10px;
}

/* set new password */
.Modal-holder
    .LogIn
    .log_in_form
    .text-holder
    .btn-holder
    button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn.reset {
    padding: 5px 39px;
}

/* Otp  modal css */
.log_in_form .otp-text-holder {
    margin: 2px 4px;
}
.log_in_form .heading-holder p.sub-title {
    text-align: center;
    font-weight: 400;
}
.log_in_form .otp-text-holder input.form-control {
    width: 36px;
    height: 36px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: none;
}
.otp-holder {
    display: flex;
    justify-content: center;
    margin-top: 31px;
}

.otp-close {
    position: absolute;
    top: -12px;
    right: 0px;
    left: 0px;
    text-align: center;
}
.otp-close .otp {
    width: 23px;
    height: 23px;
    background-color: white;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}
.Otp-main .LogIn .log_in_form {
    padding-top: 22px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    position: relative;
}
/*LogIn form css start*/
@media (min-width: 280px) and (max-width: 320px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }
    .login-box-button {
        top: -18px;
    }
    .modal-content {
        margin-top: 11%;
    }
    .PostSubmission .login-box-button {
        outline: 6px solid #7f7f7f;
        width: 75%;
    }
    ul {
        padding-left: 0rem !important;
    }
    .login-box-button p {
        font-size: 14px;
    }
    .LogIn {
        /* min-height: 132vh; */
    }
    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }

    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }

    .Modal-holder button.SubmitBtn-main.text {
        padding: 8px 28px;
    }

    .Modal-holder button.SubmitBtn-main.green.text {
        padding: 8px 26px;
    }

    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }

    .Modal-holder .form-check-input[type="radio"] + .form-check-label {
        font-size: 12px;
    }

    .Modal-holder .modal-header .modal-title {
        font-size: 16px;
    }

    .Modal-holder button.SubmitBtn.green.text {
        padding: 7px 68px;
    }

    .Modal-holder button.SubmitBtn.green.text {
        padding: 7px 33px;
    }

    .Login .btn-holder button.YesBtn {
        padding: 8px 22px;
    }
    .Modal-holder .LogIn .log_in_form {
        padding: 50px 14px 1px 14px;
    }
    .Modal-holder .LogIn .log_in_form .Main-starting {
        padding: 0px 0px 1px 0px;
    }
    button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn {
        padding: 8px 28px;
    }
    .okay button.orange {
        padding: 4px 32px;
    }

    .Modal-holder .form-check-input:checked {
        font-size: 18px;
    }

    .Modal-holder .form-check-input[type="radio"] {
        font-size: 18px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }
    ul {
        padding-left: 0rem !important;
    }
    .okay button.orange {
        padding: 4px 32px;
    }
    .login-box-button p {
        font-size: 15px;
    }
    .login-box-button {
        top: -19px;
    }
    .Login .btn-holder button.YesBtn {
        padding: 8px 22px;
    }
    .otp-main-start {
        text-align: -webkit-center;
    }
    .Otp-main .LogIn .log_in_form {
        width: min-content;
        display: block;
        padding: 17px 24px;
    }
    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }
    .LogIn {
        /* min-height: 132vh; */
    }
    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }

    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }

    .Modal-holder button.SubmitBtn.green.text {
        padding: 7px 45px;
    }

    .Modal-holder button.SubmitBtn-main.green.text {
        padding: 8px 35px;
    }

    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }

    .Modal-holder .form-check-input[type="radio"] + .form-check-label {
        font-size: 12px;
    }

    .Modal-holder .modal-header .modal-title {
        font-size: 16px;
    }

    .Modal-holder button.SubmitBtn-main.text {
        padding: 8px 35px;
    }
    .Modal-holder .LogIn .log_in_form {
        padding: 50px 14px 1px 14px;
    }
    .Modal-holder .LogIn .log_in_form .Main-starting {
        padding: 0px 36px 1px 38px;
    }
    button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn {
        padding: 8px 28px;
    }

    .Modal-holder .form-check-input:checked {
        font-size: 18px;
    }

    .Modal-holder .form-check-input[type="radio"] {
        font-size: 18px;
    }
}

@media (min-width: 486px) and (max-width: 576px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }
    .login-box-button p {
        font-size: 14px;
    }
    .LogIn {
        /* min-height: 132vh; */
    }
    .otp-main-start {
        text-align: -webkit-center;
    }
    .Otp-main .LogIn .log_in_form {
        width: min-content;
        display: block;
        padding: 17px 24px;
    }
    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }
    .login-box-button {
        top: -24px;
    }
    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }

    .Modal-holder button.SubmitBtn.green.text {
        padding: 7px 56px;
    }

    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }

    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }

    .Modal-holder .form-check-input:checked {
        font-size: 18px;
    }

    .Modal-holder .form-check-input[type="radio"] {
        font-size: 18px;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }
    .login-box-button p {
        font-size: 16px;
    }
    .otp-main-start {
        padding: 0px 8px;
    }
    .LogIn {
        /* min-height: 132vh; */
    }
    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }

    .Modal-holder button.SubmitBtn.green.text {
        padding: 7px 68px;
    }

    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }

    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }

    .Modal-holder .form-check-input:checked {
        font-size: 18px;
    }

    .Modal-holder .form-check-input[type="radio"] {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }

    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section .img-holder {
        left: -21%;
    }
    .otp-main-start {
        padding: 0px 8px;
    }
    .otp-main-start {
        padding: 0px 20px;
    }
    .LogIn {
        min-height: 120vh;
    }
    .login-box-button p {
        font-size: 18px;
    }

    .Modal-holder .form-check-input:checked {
        font-size: 18px;
    }

    .Modal-holder .form-check-input[type="radio"] {
        font-size: 18px;
    }
}
@media (min-width: 992px) and (max-width: 1399px) {
    button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn {
        padding: 8px 54px;
    }
}
