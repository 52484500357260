/* Address Css started */

.Address {
  padding: 37px 26px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.Address .Main-address {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  margin-bottom: 6%;
}

/* .Address .Main-address .address-card-holder {
  display: flex;
  justify-content: space-between;
} */

.Address .Main-address .address-card-holder {
  display: unset;
}

.Address .Main-address .address-card-holder .icon-holder .button-main {
  display: flex;
  justify-content: end;
}

.Address .Main-address .address-card-holder .delet-address-holder {
  margin-top: 0%;
}

.Address .Main-address .address-card-holder .delet-address-holder {
  display: flex;
  justify-content: normal;
  align-items: center;
  /* margin-top: 100%; */
}

.Address .image-map {
  width: 100%;
  height: 100px;
  position: relative;
}

.Address .map {
  width: 100%;
  height: 100%;
}

.Address .circle {
  border-radius: 100px;
  background: #1d44ad;
  width: 34px;
  height: 35px;
  color: white;
  padding: 6px 11px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Address .Edit {
  padding: 10px 9px;
  display: flex;
}

.Address .Main-address .Edit .sub-text {
  color: #323232;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 15px;
}

.Address .Main-address .Edit .text {
  margin-bottom: 0px;
  color: #323232;
}

.Address .Main-address .Edit p.text.sub {
  margin-bottom: 0px;
  cursor: pointer;
  color: black;
  margin-top: 60px;
  border-bottom: 1px solid black;
  width: fit-content;
  font-weight: 500;
}
.Address .form-check-input[type="radio"] {
  border-radius: 50%;
  font-size: 18px;
}
.Address .form-check-input:checked[type="radio"] {
  /* margin-right: 17px; */
  cursor: pointer;
  height: 18px;
  width: 18px;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000%27/%3e%3c/svg%3e") !important;
}
.Address label.form-check-label {
  margin-left: 14px;
}
.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000%27/%3e%3c/svg%3e") !important;
}

.Address .selectOptions__control {
  background-color: #f6f6f6;
  border: none;
}

.Address .form-check-input:checked {
  background-color: white !important;
  border-color: black !important;
  cursor: pointer;
}

.Address .Main-address .Edit .content {
  margin-left: 16px;
}

.Address .Main-address .CartPage {
  background: #ccc;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  font-size: 11px;
  color: #000;
  border-radius: 50%;
}

.Address
  .Main-address
  .address-card-holder
  .icon-holder
  .button-main
  .form-check {
  margin-left: 50%;
  width: 0px;
}

.Address .Main-address .Edit .content .small-text {
  font-weight: 500;
}

.Address form.address-form {
  margin: 0px 61px 0px 41px;
}

.Address .form-control {
  padding: 10px 18px;
  /* border: none; */
  background: #f6f6f6 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  color: #000 !important;
  font-size: 13px !important;
}

.Address .selectOptions__value-container {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  font-size: 13px;
}

/* .Address label.form-check-label {
    margin-left: 16px;
} */

.Address .apply_now_btn {
  text-align: end;
  margin-top: 8%;
}

.Address .apply_now_btn button.tabs-btn {
  padding: 8px 50px;
  border: none;
  border-radius: 5px;
}

.Address .apply_now_btn button.back-btn.back {
  padding: 8px 80px;
  border: none;
  border-radius: 5px;
}

.Address-Content .Address-Content .main-heading-text label.form-check-label {
  margin: 0px 10px;
  font-size: 20px;
  font-weight: bold;
}

.Address .main-address {
  height: 283px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Address .main-address::-webkit-scrollbar {
  display: none;
}

/* Address Css End */

@media (min-width: 0px) and (max-width: 485px) {
  .Address .right_border {
    border-right: none;
    padding: 0px 0px 0px 0px !important;
  }

  .Address .Main-address .address-card-holder .icon-holder .button-main {
    display: flex;
    justify-content: end;
  }

  .Address .Main-address .address-card-holder .delet-address-holder {
    margin-top: 0%;
  }

  .Address .image-map {
    width: 100%;
  }

  .Address {
    padding: 20px 12px;
  }

  .Address .circle {
    width: 24px;
    height: 24px;
    padding: 2px 6px;
  }

  .Address .Main-address {
    margin: 13px 10px;
  }

  .Address-Content {
    padding: 0px 0px 0px 0px;
  }

  .Address form.address-form {
    margin: 0px 4px 0px 6px;
  }

  span.step-text.small-text {
    font-size: 10px !important;
  }

  .Address .apply_now_btn {
    text-align: center;
    margin-top: 8%;
  }

  .Address button.back-btn.back {
    padding: 10px 29px;
  }

  .Address .apply_now_btn button.back-btn.back {
    padding: 8px 40px;
  }

  .Address .apply_now_btn button.tabs-btn {
    padding: 8px 25px;
  }

  .Address .main-address {
    margin: 0px 15px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .Address {
    margin: 0px 0px;
  }

  .Address .Main-address .address-card-holder .icon-holder .button-main {
    display: flex;
    justify-content: end;
  }

  .Address .Main-address .address-card-holder .delet-address-holder {
    margin-top: 0%;
  }

  .Address .right_border {
    border-right: none !important;
    padding: 0px 0px 0px 0px !important;
  }

  .Address form.address-form {
    margin: 0px 0px 0px 0px;
  }

  .Address .main-address {
    margin: 0px 15px;
  }

  .Address .apply_now_btn {
    text-align: center;
    margin-top: 8%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Address form.address-form {
    margin: 1px 2px 0px 0px;
  }
  .Address .main-address {
    height: 297px;
  }

  .Address .Main-address .address-card-holder {
    display: unset;
  }

  .Address .Main-address .address-card-holder .icon-holder .button-main {
    display: flex;
    justify-content: end;
  }

  .Address .Main-address .address-card-holder .delet-address-holder {
    margin-top: 0%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .Address .Edit {
    justify-content: space-between;
  }

  .Address-Content {
    padding: 0px 0px 0px 0px;
  }

  .Address .main-address {
    height: 313px;
  }

  .Address .Main-address .address-card-holder {
    display: unset;
  }

  .Address .Main-address .address-card-holder .icon-holder .button-main {
    display: flex;
    justify-content: end;
  }

  .Address .Main-address .address-card-holder .delet-address-holder {
    margin-top: 0%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Address button.back-btn.back {
    padding: 8px 46px !important;
  }

  .Address button.tabs-btn {
    padding: 8px 22px !important;
  }
}

/* Payment Css Started */
.name {
  display: flex;
}

.adresmain h3 {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
  padding-left: 25px;
  padding-bottom: 15px;
}

.adresmain .adresss {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 25px;
  opacity: 1;
  margin-bottom: 26px;
}

.adresmain p.text {
  color: #323232;
}

.main {
  margin-right: 10px;
}

.checkout-inner-steps .Primary-Contact p.sub-title {
  font-weight: 500;
}

.checkout-inner-steps .Primary-Contact .sub-text {
  color: #020202 !important;
}

.checkout-inner-steps .Primary-Contact {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  /* height: 89%; */
  padding: 66px 27px 78px 38px;
}

.Choose_Payment_Option {
  height: 100%;
  background: #fff6de 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 13px 25px 0px 25px;
  margin-bottom: 40px;
}

.Choose_Payment_Option .radio-section-holder {
  margin-bottom: 20px;
}

.Choose_Payment_Option .radio-section-holder .form-control {
  padding: 10px 18px;
  /* border: none; */
  background: #f6f6f6 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  color: #000 !important;
  font-size: 13px !important;
}

/* Chrome, Safari, Edge, Opera */
.Choose_Payment_Option .radio-section-holder .form-control::-webkit-outer-spin-button,
.Choose_Payment_Option .radio-section-holder .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.Choose_Payment_Option .radio-section-holder .form-control[type=number] {
  -moz-appearance: textfield;
}

.Choose_Payment_Option .radio-section-holder .days-field-show{
margin-top: 20px;
}

.Choose_Payment_Option .payment_method_card .paycredit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Choose_Payment_Option .heading-holder h4 {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: #323232;
  margin-bottom: 20px;
}

.Choose_Payment_Option .radio-btn-input-holder {
  display: flex;
}

.Choose_Payment_Option .radio-btn-input-holder .form-check-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  margin-top: 5px;
}

.Choose_Payment_Option .radio-btn-input-holder input.form-check-input {
  font-size: 20px;
}

.Choose_Payment_Option .radio-btn-input-holder .form-check {
  margin-right: 5%;
}

.Choose_Payment_Option .bodr_bottom {
  margin-top: 32px;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input {
  display: flex;
  justify-content: normal;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 30px;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .form-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  width: 25%;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .form-control {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: #323232;
  margin-left: 10px;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .payment-logo {
  width: 60px;
  height: 30px;
  object-fit: contain;
}

.Choose_Payment_Option
  .payment_method_card
  .flex-radio-input
  .payment-logo-holder {
  margin-left: 10px;
  background: #ffffff;
  border: 0.5px solid #b2b2b2;
  border-radius: 5px;
  padding: 4px 22px;
}

.payment_method_card .flex-radio-input input.form-check-input {
  font-size: 20px;
}

.checkout-inner-steps button.tabs-btn {
  border: none;
  padding: 10px 75px;
  border-radius: 5px;
}

.checkout-inner-steps .apply_now_btn {
  text-align: center;
  margin-top: 16%;
}

.checkout-inner-steps button.back-btn {
  border: none;
  padding: 10px 73px;
  border-radius: 5px;
}

.Choose_Payment_Option .payment_method_card {
  margin-top: 5%;
}

@media (min-width: 280px) and (max-width: 320px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }

  .Choose_Payment_Option .payment_method_card {
    padding: 14px 15px;
  }

  .adresmain h3 {
    font-size: 18px;
    padding-left: 0px;
    padding-bottom: 5px;
  }

  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .checkout-inner-steps button.back-btn.back {
    padding: 8px 29px;
  }

  .checkout-inner-steps button.tabs-btn {
    padding: 8px 23px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }

  .Choose_Payment_Option .payment_method_card {
    padding: 14px 15px;
  }

  .adresmain h3 {
    font-size: 18px;
    padding-left: 0px;
    padding-bottom: 5px;
  }

  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .checkout-inner-steps button.back-btn.back {
    padding: 10px 29px;
  }

  .checkout-inner-steps button.tabs-btn {
    padding: 8px 23px;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }

  .Choose_Payment_Option .payment_method_card {
    padding: 14px 15px;
  }

  .adresmain h3 {
    font-size: 19px;
    padding-left: 0px;
    padding-bottom: 5px;
  }

  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 13px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }

  .adresmain h3 {
    font-size: 19px;
    padding-left: 0px;
    padding-bottom: 5px;
  }

  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }

  .adresmain h3 {
    font-size: 20px;
    padding-left: 0px;
    padding-bottom: 5px;
  }

  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 17px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .checkout-inner-steps button.tabs-btn {
    padding: 10px 19px;
  }

  .checkout-inner-steps button.back-btn {
    padding: 10px 26px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .checkout-inner-steps .back-btn.back {
    padding: 10px 54px;
  }

  .checkout-inner-steps button.tabs-btn {
    padding: 10px 54px;
}
}

/* Payment Css End */

/* Order Review css started */
.OrderReview .Address .form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23000000%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

.OrderReview .Address .form-check-input[type="checkbox"] {
  border: 1px solid black;
  font-size: 20px;
  border-radius: 4px;
}

.OrderReview .Address .OrderDetails {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  padding: 30px 31px;
  width: 100%;
  margin-left: 15px;
  margin-bottom: 1%;
}

.OrderReview .Address img.payment-logo {
  width: 100%;
  height: 100%;
  border-radius: 11px;
}

.OrderReview .Address .image-product {
  height: 89px;
  width: 108px;
  border-radius: 11px;
}

.OrderReview .main-cart {
  padding: 23px 0px;
  width: 100%;
  height: 100%;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrderReview .Address .OrderDetails .Details .mini-text {
  display: flex;
}

.OrderReview .Address .OrderDetails .Details .delete-main {
  padding: 2px 10px;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 100px;
}

.OrderReview .Address .OrderDetails .Details .delete-main .icon {
  color: #878181;
  font-size: 12px;
}

.OrderReview .Address .Deatils-address {
  display: flex;
  align-items: baseline;
}

.OrderReview .Address .check-main .form-check-input[type="checkbox"] {
  font-size: 19px;
}

.OrderReview .Address .total-amount {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 17px 17px;
  width: 100%;
  box-shadow: 0px 0px 7px #00000029;
}

.OrderReview .Order-payment .sub-title {
  font-weight: 500;
  text-align: center;
  color: #000000;
}

.OrderReview .Order-payment .total-amount .order-main {
  display: flex;
  justify-content: space-between;
}

.OrderReview .Address .price-text {
  font-weight: bold;
  color: #ff0000;
}

.OrderReview .Address .place-order button.order {
  width: 100%;
  padding: 7px 0px;
  border: none;
  font-size: 14px;
  color: #ffffff;
  font-family: Nunito Sans;
}

.OrderReview .Deatils-address .OrderDetails .content {
  padding: 2px 14px;
}

.OrderReview .Deatils-address .OrderDetails .content p.commmontext {
  margin-bottom: 10px;
}

.OrderReview .Deatils-address .OrderDetails .content p.price.commmontext {
  font-weight: 500;
  color: #ff0000;
}

.OrderReview .Deatils-address .OrderDetails .content p.small-text {
  margin-bottom: 16px;
}

.OrderReview .Quantity .quantity-no.sub-title {
  padding: 0px 21px;
  margin: 0px 31px;
  width: 51px;
  height: 27px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.OrderReview .Quantity p.small-text.add {
  margin-left: 39px;
}

.OrderReview .Order-payment .total-amount .order-main .small-text {
  margin-bottom: 24px;
}

.OrderReview .Quantity {
  display: flex;
}

.OrderReview .Address .Delete-order {
  text-align: -webkit-right;
}

.OrderReview .Order-payment .apply_now_btn.order {
  display: flex;
  justify-content: center;
}

.OrderReview .Order-payment .apply_now_btn.order .back-btn {
  padding: 9px 63px;
  border: none;
}

.OrderReview .Order-payment .apply_now_btn.order .orange {
  padding: 9px 40px;
  border: none;
}

.OrderReview .Order-payment .Coupon-btn-holder .orange {
  border: none;
  border-radius: 5px;
  padding: 5px 0px;
  width: 100%;
}

.OrderReview
  .Order-payment
  .apply-coupon-holder
  .Coupon-btn-holder
  .small-text {
  font-size: 15px !important;
}

.OrderReview .Order-payment .apply-coupon-holder .Coupon-btn-holder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.OrderReview .Order-payment .apply-coupon-holder .green-text {
  color: green;
  font-size: 12px;
  font-weight: 500;
}

.OrderReview .Order-payment .apply-coupon-holder .red-text {
  color: red;
  font-size: 12px;
  font-weight: 500;
}

/* media Query Started */

@media (min-width: 0px) and (max-width: 767px) {
  .OrderReview .Address .check-main .form-check-input[type="checkbox"] {
    font-size: 16px;
  }

  .OrderReview .Address .Deatils-address {
    display: block;
  }

  .OrderReview .Address .OrderDetails {
    margin-left: 0px;
  }

  .OrderReview .Address .form-check-input[type="checkbox"] {
    margin-bottom: 16px;
  }

  .Order-payment {
    margin-top: 8%;
  }

  .OrderReview .Order-payment .apply_now_btn.order .orange {
    padding: 9px 26px;
  }

  .OrderReview .Order-payment .apply_now_btn.order .back-btn {
    padding: 9px 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .OrderReview .Order-payment .apply_now_btn.order .back-btn {
    padding: 9px 45px;
  }

  .OrderReview .Order-payment .apply_now_btn.order .orange {
    padding: 9px 27px;
  }
}
