.SelectOption .Modal-holder .mini-text.form-check {
    margin: 0px 0px !important;
}
.SelectOption  .Modal-holder .form-check-input:checked {
    background-color: transparent;
    border-color: black;
    font-size: 18px;
}
.SelectOption .form-check-input:checked[type=radio] {
    font-size: 18px;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23FFAA00%27/%3e%3c/svg%3e") !important;
}
.SelectOption  .Modal-holder .form-check-input[type="radio"] + .form-check-label {
    font-size: 18px;
    margin: 7px 22px;
}
.SelectOption .Submit-select button.submit.orange {
    padding: 6px 41px;
    border: none;
    border-radius: 5px;
}
.SelectOption .Submit-select{
    text-align: center;
}

.SelectOption .login-box-button {
    outline: 10px solid #b2b2b2;
}
.SelectOption .select_reason {
    padding: 36px 32px;
}
.SelectOption  .Modal-holder .form-check-input[type="radio"] {
    border-radius: 50%;
    font-size: 21px;
    border-color: black;
}
.SelectOption  .Modal-holder .form-check-input:checked[type="radio"] {
    font-size: 21px;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23000%27/%3e%3c/svg%3e");
}
.SelectOption  .Modal-holder .form-check-input[type="radio"] {
    border-radius: 50%;
    font-size: 16px;
    border-color: black;
    margin: 10px 0px;
}
.SelectOption .form-check {
 padding-left: 0px;
}


@media (min-width:0px) and (max-width:485px) {
    .SelectOption  .Modal-holder .form-check-input[type="radio"] + .form-check-label {
        font-size: 14px;
    }
    .SelectOption  .Modal-holder .form-check-input[type="radio"] {
        /* margin: 0px; */
        font-size: 14px;
    }
    .SelectOption .select_reason {
        padding: 36px 15px;
    }
    .SelectOption .form-check {
        padding-left: 0px;
        margin-bottom: 3px;
        display: flex;
    }

    .SelectOption  .Modal-holder .form-check-input[type="radio"] + .form-check-label {
        margin: 7px 5px;
    }
  
}
@media (min-width:486px) and (max-width:767px) {
    .SelectOption  .Modal-holder .form-check-input[type="radio"] + .form-check-label {
        font-size: 16px;
    }
    .SelectOption  .Modal-holder .form-check-input[type="radio"] {
       margin: 8px 0px;
    }
}