.search-main {
    position: relative;
}
form.search {
    position: relative;
}
.search-main .icon {
    position: absolute;
    top: 2px;
    right: 5px;
}
.search-main  .icon-search {
    font-size: 14px;
    color: grey;
}
.search-main .canva {
    margin: 4px 13px;
}
section.Search-Bar {
    border-radius: 100px;
    width: 30px;
    height: 30px;
    background: #ffaa00 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 6px #00000029;
    padding: 3px 7px;
    color: white;
    margin-top: -4px;
}
.search-main  .icon {
    font-size: 13px;
    color: black;
    padding: 9px 9px;
}
