.Order .cancellation {
    padding: 11px 16px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
}
.Order .Main-cancel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Order p.commmontext {
    margin-bottom: 0px;
}
.Order .text-area {
    margin-top: 2%;
    padding: 21px 27px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
}
.Order .Btn-Cancel button.cancel {
    padding: 7px 80px;
    border: none;
    background: #b7b7b7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: white;
}
.Order .Btn-Cancel button.submit {
    background: #ffaa00 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    padding: 7px 80px;
}
.Order .Btn-Cancel {
    margin-top: 3%;
}
.Order .Main-Order-Card {
    margin-top: 2%;
}

/* Media Query Started */

@media (min-width: 0px) and (max-width: 485px) {
    .Order .cancellation {
        margin-top: 10%;
    }
    .Order .text-area {
        padding: 21px 14px;
    }
    .Order .Btn-Cancel {
        margin-top: 6%;
        text-align: -webkit-center;
        display: grid;
        justify-content: center;
    }
    .Order .Btn-Cancel button.cancel {
        width: 100%;
        padding: 7px 24px;
    }
    .Order .Btn-Cancel button.submit {
        width: 100%;
        padding: 7px 24px;
    }
    .Order .steps {
        margin-top: 13%;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .Order .cancellation {
        margin-top: 10%;
    }
    .Order .text-area {
        padding: 21px 14px;
    }
    .Order .Btn-Cancel {
        margin-top: 6%;
        text-align: -webkit-center;
    
        justify-content: center;
    }
    .Order .Btn-Cancel button.cancel {
        width: auto;
        padding: 7px 40px;
    }
    .Order .Btn-Cancel button.submit {
        width: auto;
        padding: 7px 40px;
    }
    .Order .steps {
        margin-top: 13%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .Order .cancellation {
        margin-top: 5%;
    }
    .Order .Btn-Cancel {
      
        text-align: center;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
}
