.productdetail_section .productdetail_main{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 20px;
}

.productdetail_section .productdetail_main .instock{
    font-size: 14px;
    font-weight: 600;
color: #196E00;
}

.productdetail_section .productdetail_main .tapename{
    font-size: 22px;
    font-weight: 600;
color: #000;
}

.productdetail_section .productdetail_main .detailtext{
    font-size: 18px;
    font-weight: 400;
color: #000;
}

.productdetail_section .productdetail_main .litagg_div li::marker {
color: #FFAA00;
}

.productdetail_main .swiper.swiper-initialized.swiper-vertical.swiper-backface-hidden.mySwiper {
    height: 356px;
}
.productdetail_section .productdetail_main .imgmaindivv {
    background: #E6E6E6 0% 0% no-repeat padding-box;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productdetail_section .productdetail_main .swipermmains{
    position: relative;
}

.productdetail_section .productdetail_main .swipermmains .back-btn{
    position: absolute;
    top:-4px;
    left: calc( 50% - 6px );
    z-index: 1;
    width: 12px;
height: 12px;
font-size: 8px;
border-radius: 50%;
background: #F0D996 0% 0% no-repeat padding-box;
color: #000;
display: flex;
align-items: center;
justify-content: center;
}
.productdetail_section .productdetail_main .swipermmains .next-btn{
    position: absolute;
    bottom:-4px;
    left: calc( 50% - 6px );
    z-index: 1;
    width: 12px;
height: 12px;
border-radius: 50%;
background: #F0D996 0% 0% no-repeat padding-box;
color: #000;
font-size: 8px;
display: flex;
align-items: center;
justify-content: center;
}

/* .imgaggmain .swipingimg {
    max-width: 60%;
    max-height: 60%;
}

.imgaggmain{
    display: flex;
    justify-content: center;
    align-items: center;

} */


/* swiper css start */
.productdetail_section .productdetail_main .Main-div {
    padding: 8px 10px;
    background: #E6E6E6 0% 0% no-repeat padding-box;
    width: 100%;
    height: 100%;
}

.productdetail_section .productdetail_main  .Main-div .camera-imgs img.inner-img {
    max-width: 100%;
    height: 100%;
}

.productdetail_section .productdetail_main  .Main-div .camera-imgs {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    /* aspect-ratio: 1/1; */
}
.productdetail_section .productdetail_main .zoomable {
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    aspect-ratio: 1/1;
}

.productdetail_section .productdetail_main  .zoomable__img {
    transform-origin: var(--zoom-pos-x, 0%) var(--zoom-pos-y, 0%);
    transition: transform 0.15s linear;
    width: 80%;
    height: auto;
    object-fit: contain;
}
.productdetail_section .productdetail_main .zoomable--zoomed .zoomable__img {
    cursor: zoom-in;
    transform: scale(var(--zoom, 2));
}


.productdetail_section .productdetail_main  img.product-img {
    height: 100%;
    width: 100%;
}
.productdetail_section .productdetail_main  .product-detail {
    height: auto;
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
    overflow: hidden;
}

.productdetail_section .productdetail_main  .product-detail .specs {
    height: auto;
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.productdetail_section .productdetail_main  .horizantalSlider {
    display: none;
    margin-top: 20px;
}

.productdetail_section .productdetail_main  .verticalSlider{
    display: block;
}

.productdetail_section .productdetail_main  .swiper-slide {   
    height: auto;
}

.productdetail_section .productdetail_main  .horiswiper{
    position: relative;
}


.productdetail_section .productdetail_main .horiswiper .back-btn{
    position: absolute;
   right:-4px;
    top: calc( 50% - 6px );
    z-index: 1;
    width: 12px;
height: 12px;
font-size: 8px;
border-radius: 50%;
background: #F0D996 0% 0% no-repeat padding-box;
color: #000;
display: flex;
align-items: center;
justify-content: center;
}
.productdetail_section .productdetail_main .horiswiper .next-btn{
    position: absolute;
    
    top: calc( 50% - 6px );
    left: -4px;
    z-index: 1;
    width: 12px;
height: 12px;
border-radius: 50%;
background: #F0D996 0% 0% no-repeat padding-box;
color: #000;
font-size: 8px;
display: flex;
align-items: center;
justify-content: center;
}




@media (min-width: 0px) and (max-width: 485px) {
    .productdetail_section .productdetail_main  .instock {
        font-size: 11px;
       
    }
    .productdetail_section .productdetail_main  .tapename {
        font-size: 15px;
    }
    .productdetail_section .productdetail_main  .detailtext {
        font-size: 12px;
        
    }
    .productdetail_section .productdetail_main  .horizantalSlider {
        display: block;
    }
    .productdetail_section .productdetail_main  .verticalSlider{
        display: none;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .productdetail_section .productdetail_main  .instock {
        font-size: 12px;
       
    }
    .productdetail_section .productdetail_main  .tapename {
        font-size: 16px;
    }
    .productdetail_section .productdetail_main   .detailtext {
        font-size: 13px;
        
    }
    .productdetail_section .productdetail_main  .horizantalSlider {
        display: block;
    }
    .productdetail_section .productdetail_main  .verticalSlider{
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .productdetail_section .productdetail_main .instock {
        font-size: 13px;
       
    }
    .productdetail_section .productdetail_main  .tapename {
        font-size: 18px;
    }
    .productdetail_section .productdetail_main  .detailtext {
        font-size: 14px;
        
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .productdetail_section .productdetail_main  .instock {
        font-size: 14px;
       
    }
    .productdetail_section .productdetail_main  .tapename {
        font-size: 20px;
    }
    .productdetail_section .productdetail_main  .detailtext {
        font-size: 15px;
        
    }
    .productdetail_main .swiper.swiper-initialized.swiper-vertical.swiper-backface-hidden.mySwiper {
        height: 498px;
    }
}


