/* ContactUs css started */

.ContactUs .chooseus_main_div .main {
    position: relative;
    padding-top: 39px;
    margin: 0px 21px;
}
.ContactUs .chooseus_main_div .main .Step-choose {
    padding-bottom: 15%;
    height: auto;
    position: relative;
    padding-top: 68px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    background: #fffaeb 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px #00000029; */
    border: 1px solid #ffe8bb;
    -webkit-mask: radial-gradient(circle 30px at top center, #0000 125%, #000) top left,
        radial-gradient(circle 30px at top center, #0000 181%, #000) top right,
        radial-gradient(circle 30px at bottom left, #0000 162%, #000) bottom left,
        radial-gradient(circle 30px at bottom right, #0000 98%, #000) bottom right;
    -webkit-mask-size: 100% 50%;
    -webkit-mask-repeat: no-repeat;
    /* z-index: -1; */
}


.gmnoprint .gm-bundled-control.gm-bundled-control-on-bottom   .gmnoprint div{
    
    background-color: #000 !important;
}
.gmnoprint button{
    background-color: black !important;
}


:root {
    --gm-button-background-color:white;
}
@media (prefers-color-scheme: dark) {
    :root {
        --gm-button-background-color:#2c4973;
    }
}

.gm-control-active {
    background-color:var(--gm-button-background-color) !important;
}

.gm-control-active {
    background-color:var(--gm-button-background-color) !important;
}

.ContactUs .chooseus_main_div .main .Step-choose .carddheading {
    height: 90px;
    font-weight: 500;
    color: #000000;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
}

.ContactUs .chooseus_main_div .carddtext {
    font-size: 16px;
    font-weight: 500;
    color: #000000 !important;
    font-family: "Roboto", sans-serif !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.ContactUs .chooseus_main_div .main .circles {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: calc(50% - 36px);
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffaa00 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffe8bb;
}
.ContactUs .chooseus_main_div .circle11 {
    width: 47px;
    height: auto;
}

.ContactUs .chooseus_main_div .borderciclee {
    position: absolute;
    z-index: 1;
    top: -39px;
    left: calc(50% - 42px);
    width: 85px;
    height: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px #00000029; */
    border: 1px solid #ffe8bb;
}
.ContactUs .chooseus_main_div .main .circles .icon {
    color: white;
    font-size: 32px;
}
.ContactUs .Map-Main {
    position: relative;
    margin-top: 10%;
}
.ContactUs .Map-Main .gmap_iframe {
    height: 648px;
    width: 100%;
}
.ContactUs .Map-Main .Contact-Us-Form {
    padding: 31px 35px;
    width: -moz-fit-content;
    width: fit-content;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 2px;
}
.ContactUs .Map-Main .Contact-Us-Form label.form-label {
    font-weight: 500;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
}
.ContactUs .Map-Main .Contact-Us-Form .form-control {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    height: 48px;
    border: none;
}
.ContactUs .Map-Main .Contact-Us-Form .submit {
    text-align: center;
    margin-top: 3%;
}
.ContactUs .Map-Main .Contact-Us-Form .submit button.submit {
    padding: 10px 171px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: none;
    color: white;
}
.ContactUs .Map-Main .content-main {
    position: absolute;
    top: -71px;
}
/* ContactUs.css */
/* Example: Ensure specificity and override */
.gm-control-active {
    background-color: black !important;
    height: 28px !important;
    width: 28px !important;
    border-radius: 100px !important;
}

/********************** Media Query started *********************/

@media (min-width: 0px) and (max-width: 485px) {
    .ContactUs .chooseus_main_div .main .Step-choose .carddheading {
        font-size: 14px;
    }
    .ContactUs .Map-Main .Contact-Us-Form .submit button.submit {
        font-size: 13px;
        padding: 7px 36px;
    }
    .ContactUs .Map-Main .Contact-Us-Form label.form-label {
        font-size: 14px;
    }

    .ContactUs .Map-Main .content-main {
        position: unset;
    }
    .ContactUs .Map-Main .Contact-Us-Form {
        margin-top: 3%;
        padding: 27px 26px;
    }
    section.ContactUs {
        margin-bottom: 17%;
    }
    .ContactUs .Map-Main .gmap_iframe {
        height: 100%;
        width: 100%;
    }
    .ContactUs .chooseus_main_div .main .circles {
        width: 58px;
        height: 58px;
        top: 16px;
        left: calc(50% - 29px);
    }
    .ContactUs .chooseus_main_div .borderciclee {
        top: -39px;
        left: calc(50% - 37px);
        width: 75px;
        height: 78px;
    }
    .ContactUs .chooseus_main_div .main .circles .icon {
        font-size: 21px;
    }
    section.ContactUs {
        margin-bottom: 28%;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
    .ContactUs .chooseus_main_div .main .Step-choose .carddheading {
        font-size: 15px;
    }
    .ContactUs .Map-Main .Contact-Us-Form .submit button.submit {
        font-size: 15px;
        padding: 7px 36px;
    }
    .ContactUs .Map-Main .Contact-Us-Form label.form-label {
        font-size: 15px;
    }

    .ContactUs .Map-Main .content-main {
        position: unset;
    }
    .ContactUs .Map-Main .Contact-Us-Form {
        margin-top: 3%;
        padding: 27px 26px;
    }
    section.ContactUs {
        margin-bottom: 16%;
    }
    .ContactUs .Map-Main .gmap_iframe {
        height: 100%;
        width: 100%;
    }
    .ContactUs .chooseus_main_div .main .circles {
        width: 58px;
        height: 58px;
        top: 16px;
        left: calc(50% - 29px);
    }
    .ContactUs .chooseus_main_div .borderciclee {
        top: -39px;
        left: calc(50% - 37px);
        width: 75px;
        height: 78px;
    }
    .ContactUs .chooseus_main_div .main .circles .icon {
        font-size: 21px;
    }
    section.ContactUs {
        margin-bottom: 28%;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .ContactUs .chooseus_main_div .main .Step-choose .carddheading {
        font-size: 16px;
    }
 
    .ContactUs .Map-Main .Contact-Us-Form .submit button.submit {
        font-size: 16px;
        padding: 7px 36px;
    }
    .ContactUs .Map-Main .Contact-Us-Form label.form-label {
        font-size: 16px;
    }

    .ContactUs .Map-Main .content-main {
        position: unset;
    }
    .ContactUs .Map-Main .Contact-Us-Form {
        margin-top: 3%;
        padding: 27px 26px;
    }
    section.ContactUs {
        margin-bottom: 24%;
    }
    .ContactUs .Map-Main .gmap_iframe {
        height: 100%;
        width: 100%;
    }
    .ContactUs .chooseus_main_div .main .circles {
        width: 58px;
        height: 58px;
        top: 16px;
        left: calc(50% - 29px);
    }
    .ContactUs .chooseus_main_div .borderciclee {
        top: -39px;
        left: calc(50% - 37px);
        width: 75px;
        height: 78px;
    }
    .ContactUs .chooseus_main_div .main .circles .icon {
        font-size: 21px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .ContactUs .chooseus_main_div .main .circles {
        width: 58px;
        height: 58px;
        top: 16px;
        left: calc(50% - 29px);
    }
    section.ContactUs {
        margin-bottom: 21%;
    }
    .ContactUs .chooseus_main_div .borderciclee {
        top: -39px;
        left: calc(50% - 37px);
        width: 75px;
        height: 78px;
    }
    .ContactUs .chooseus_main_div .main .Step-choose .carddheading {
        margin-bottom: 28px;
        height: 49px;
        font-size: 16px;
    }
    .ContactUs .Map-Main .content-main {
        position: unset;
        margin-top: 4%;
    }
    .ContactUs .Map-Main {
        margin-top: 5%;
    }
    .ContactUs .Map-Main .gmap_iframe {
        height: 400px;
    }
    .ContactUs .Map-Main .Contact-Us-Form label.form-label {
        font-size: 17px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
section.ContactUs {
    margin-bottom: 21%;
}
}