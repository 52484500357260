.Main-Pdf img.pdf {
    width: 100%;
    height: 100%;
}
.Main-Pdf .modal-footer {
    margin-bottom: 20px;
    border-top: 0px;
    padding: 0px 0px;
    justify-content: center !important;
}
.Main-Pdf button.orange.small-text {
    border: none;
    padding: 3px 24px;
    border-radius: 5px;
}
.Main-Pdf button.cancel {
    padding: 3px 35px;
    border: none;
    color: white;
    background: #6E6A6A 0% 0% no-repeat padding-box;
    border-radius: 5px;
}
.Main-Pdf .modal-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
}