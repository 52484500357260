/* .Adhesive css started */

.Adhesive .Adhesive-Quality img.industry {
    width: 100%;
    height: 100%;
}
.Adhesive .Adhesive-Quality .explore-btn {
    position: relative;
    color: black;
    font-weight: 500;
    text-align: center;
}
section.Adhesive {
    margin-top: 2%;
}
.Adhesive .Adhesive-Quality .industry-main {
    margin-top: 2%;
}
.Adhesive .Adhesive-Quality .banner-two {
    margin-top: 4%;
}
.Adhesive .Adhesive-Quality p.explore-btn:after {
    bottom: 13px;
    margin-left: 10px;
}

.Adhesive .Adhesive-Quality p.explore-btn:after,
.Adhesive .Adhesive-Quality p.explore-btn:before {
    background-color: black;
    content: "";
    height: 2px;
    position: absolute;
    width: 110px;
}

.Adhesive .Adhesive-Quality p.explore-btn:before {
    bottom: 13px;
    margin-left: -120px;
}
/* .Adhesive css End */


@media (min-width:0px) and (max-width:485px) {
    .Adhesive .Adhesive-Quality .explore-btn {
       font-size: 15px;
    }
    .Adhesive .Adhesive-Quality p.explore-btn:after, .Adhesive .Adhesive-Quality p.explore-btn:before {
      width: 60px;
    }
    .Adhesive .Adhesive-Quality p.explore-btn:before {
        bottom: 9px;
        margin-left: -75px;
    }
    .Adhesive .Adhesive-Quality p.explore-btn:after {
        bottom: 10px;
       
    }
}
@media (min-width:486px) and (max-width:767px) {
    .Adhesive .Adhesive-Quality .explore-btn {
        font-size: 16px;
     }
}
@media (min-width:768px) and (max-width:991px) {
    .Adhesive .Adhesive-Quality .explore-btn {
        font-size: 17px;
     }
}
