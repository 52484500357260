
/* personal_section css started */

.personal_section {
    margin-top: 6%;
    margin-bottom: 5%;
}

.personal_div {
    position: relative;
}
.personal_section .btndiv {
    position: absolute;
    bottom: 22%;
    right: 4%;
}

.personal_section .personalbanner {
    width: 100%;
}

.personal_section .personalbanner .personalimage {
    width: 100%;
}
/* personal_section css end */


/********************* Media Query Started *************************/

@media (min-width: 0px) and (max-width: 320px) {
    .personal_section .btndiv {
        bottom: 13%;
    }
    .personal_section .explore-btn {
        font-size: 9px;
        padding: 3px 5px !important;
      }
}

@media (min-width: 321px) and (max-width: 485px) {
    .personal_section .btndiv {
        bottom: 16%;
    }
    .personal_section  .explore-btn {
        font-size: 10px;
        padding: 6px 13px !important;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
    .personal_section .btndiv {
        bottom: 15%;
    }
    .personal_section  .explore-btn {
        font-size: 12px;
        padding: 8px 15px !important;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .personal_section .btndiv {
        bottom: 15%;
    }
    .personal_section  .explore-btn {
        font-size: 12px;
        padding: 8px 15px !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .personal_section .btndiv {
        bottom: 19%;
    }
    .personal_section  .explore-btn {
        font-size: 14px;
        padding: 8px 15px !important;
    }
}
/********************* Media Query End *************************/