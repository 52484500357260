.Log p.footer-text {
    color: black;
    margin-bottom: 0px;
    text-align: center;
    font-weight: 400;
}
.Log p.footer-text.log {
    font-weight: 600;
}
.Log .button-main {
    display: flex;
    justify-content: space-evenly;
    margin-top: 9%;
}
.Log .button-main .cancel {
    border: 1px solid #b9b9b9;
    background-color: transparent;
    border-radius: 5px;
    padding: 5px 23px;
}
.Log .button-main .submit {
    border: none;
    border-radius: 5px;
    padding: 1px 19px;
}
.Log  button.btn.btn-primary {
    color: black;
    left: 47%;
    top: -10px;
    position: absolute;
    padding: 0px 0px;
    width: 27px;
    height: 27px;
    border: none;
    background-color: white;
    border-radius: 100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.Log  .modal-body {

    background-color: transparent !important;
}
.log_in_form {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
}
svg.svg-inline--fa.fa-circle-check.success-icon {
    font-size: 29px;
}
.Log .login-box-button {
    padding: 8px 0px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    width: 60%;
    margin: 0px auto;
    text-align: center;
    border-radius: 25px;
    position: absolute;
    top: -65px;
    left: 0px;
    right: 0px;
    outline: 10px solid #ababab;
    outline-offset: 0px;
}
.Log  button.btn.btn-link {
    padding: 0px 6px;
    z-index: 999999999999;
    top: 5px;
    color: #777777;
    background-color: #EEEEEE;
    left: 86%;
    position: relative;
    border-radius: 100px;
}
.Log .log_in_form {
    padding: 17px 0px;

}
.Log .login-box-button p {
  
    font-size: 18px;
}

/* Media Query Started */

@media (min-width:0px) and (max-width:320px) {
    /* .Log  button.btn.btn-link {
      top: 5px;
      left: 262px;
    } */
    /* .Log-out-modal  .modal-content {
        width: 50%;
        margin: 0px auto;
    }
    .Log .login-box-button {
        padding: 4px 0px;
        top: -47px;
    } */

    .Log .login-box-button p {
        font-size: 15px;
    }
}
@media (min-width:321px) and (max-width:485px) {
    .Log-out-modal  .modal-content {
        width: 245px;
        margin: 0px auto;
    }

    .Log .login-box-button {
        padding: 4px 0px;
        top: -47px;
    }

    .Log .login-box-button p {
        font-size: 15px;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .Log-out-modal .modal-content {
        width: 50%;
        margin: 0px auto;
    }

    .Log .login-box-button {
        padding: 4px 0px;
        top: -47px;
    }

    .Log .login-box-button p {
        font-size: 15px;
    }
}