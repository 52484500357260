.Cart .Order-Summary {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e8e8;
    padding: 46px 21px;
    border-radius: 5px;
}
.Cart h1.sub-title {
    color: #ffaa00;
    text-align: center;
}
.Cart .form-check-input:checked {
    background-color: transparent !important;
    border-color: #0d6efd;
}
.Cart .delete-btn .main-circle {
    margin: 0px 31px;
    border: 1px solid #f2eaea;
    padding: 0px 9px;
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 100px;
}
.Cart .delete-btn {
    display: flex;
    justify-content: space-between;
}
.Cart .delete-btn .delete-icon {
    color: #878181;
    font-size: 12px;
}
.Cart .form-check-input:checked[type="checkbox"] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.Total {
    display: flex;
    justify-content: space-between;
}
.Cart label.form-check-label {
    margin: 3px 10px;
    font-weight: 500;
}
.Cart .sub-title {
    font-weight: 400;
}
.Total.t-main {
    border-bottom: 1px solid #e3e3e3;
}
p.sub-title.t2 {
    color: #ff0000;
}
button.check {
    padding: 6px 55px;
    border: none;
    background: #ffaa00 0% 0% no-repeat padding-box;
    border-radius: 5px;
}
.main-btn {
    text-align: center;
}
button.check:hover {
    background-color: #000000;
    color: white;
}
.Quantity span.conutssdiv {
    background-color: white;
    padding: 2px 3px;
    height: 31px;
    border-radius: 7px;
    margin: 0px 16px;
}
.ficnn {
    font-size: 11px;
}
section.Cart {
    margin-top: 3%;
}

@media (min-width:0px) and (max-width:767px) {
    .Cart .delete-btn .main-circle {
        margin: 0px 2px;
    }
    .Cart .delete-btn {
       margin: 0px 11px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .Cart .delete-btn .main-circle {
        margin: 0px 82px;
       
    }
}