.cattable_section .cattable_div .dropdiv {
  /* display: flex; */
  align-items: center;
  justify-content: end;
}
.cattable_section .nav-link.active {
  background: #000000 0% 0% no-repeat padding-box !important;
  border-radius: 29px !important;
  text-align: center;
  font-weight: 600;
  color: white !important;
}
.cattable_section .nav-link {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 29px !important;
  border: 1px solid #000000;
  text-align: center;
  font-weight: 600;
  width: 177px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
}
.cattable_section .nav-item {
  margin: 0px 14px;
}
.cattable_section .cattable_div .tapeimgg {
  height: 50px;
  width: auto;
}

.cattable_section .cattable_div .conutssdiv {
  background: #fdcf6c 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px;
}
.cattable_section .cattable_div .ficnn {
  font-size: 6px;
  padding-bottom: 4px;
}

.cattable_section .cattable_div .tablees {
  background: #fffbee 0% 0% no-repeat padding-box;
  /* border: 2px solid #707070; */
  border-right: 2px solid #bdbdbd;
  border-left: 2px solid #bdbdbd;
  border-bottom: 2px solid #bdbdbd;
}
.cattable_section .cattable_div .headdingss {
  background: #ffaa00 0% 0% no-repeat padding-box;
  color: hsl(0, 0%, 100%);
  font-size: 17px;
  font-weight: 400;
  vertical-align: middle;
  /* border: 1px solid #FFCE6D; */
  border-right: 1px solid #ffce6d;
}
.cattable_section .cattable_div .first-heading {
  border-bottom-left-radius: 12px;
}
.cattable_section .cattable_main {
  margin-top: 5%;
}
/* Apply border-radius to the last th */
.cattable_section .cattable_div .last-heading {
  border-bottom-right-radius: 12px;
}
.cattable_section .cattable_div table {
  width: 100%;
  border: 1px solid #afafaf;
  border-collapse: collapse;
}
.cattable_section .cattable_div thead tr {
  background: #ffaa00 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: none;
}
.cattable_section .cattable_div tr,
.cattable_section .cattable_div td {
  border: 1px solid #ffce6d;
  text-wrap: nowrap;
}
.cattable_section .cattable_div .formmselect {
  width: auto;
  border-radius: 19px;
}

.cattable_section .cattable_div .form-select:focus {
  box-shadow: none;
}

.cattable_section .cattable_div .bottomtd {
  vertical-align: middle;
  font-weight: 400;
}
.cattable_section .cattable_div .smalltexxx {
  font-size: 15px;
  font-weight: 400;
}

.cattable_section .cattable_div .greencol {
  color: green !important;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.cattable_section .cattable_div .redcol {
  color: red !important;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.cattable_section .cattable_div .pricetttxt {
  color: red !important;
}
.cattable_section .dropdown {
  position: relative;
  margin: 0px 17px;
}
.cattable_section .btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: black !important;
  background-color: #f5f5f5 !important;
  border: transparent !important;
}
.cattable_section .btn:hover {
  color: black;
  background-color: #f5f5f5;
}

.cattable_section .dropdown-toggle {
  font-size: 18px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  width: 221px;
  border: none;
}
.cattable_section .dropdown-toggle {
  border: none;
  background-color: #f5f5f5;
  font-size: 18px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  border-radius: 20px;
}
.cattable_section .dropdown-menu.show {
  text-align: center;
  padding: 3px 3px;
  width: 221px;
  transform: translate(4px, 46px) !important;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 19px;
  border: none;
  transition: all 0.3s ease-in-out;
}

.cattable_section .dropdown-menu .dropdown-item.active,
.cattable_section .dropdown-menu .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 50px;
}

.cattable_section .dropdiv .btn::after {
  transition: all 0.3s ease-in-out;
}

.cattable_section .dropdiv .btn.show::after {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

.cattable_div .table th {
  text-wrap: wrap;
}

.cattable_div .table .bottomtd p {
  cursor: pointer;
}

@media (min-width: 0px) and (max-width: 485px) {
  .cattable_section .cattable_div .smalltexxx {
    font-size: 11px;
  }
  .cattable_section .cattable_div .headdingss {
    font-size: 14px;
  }
  .cattable_section .cattable_div .greencol {
    font-size: 14px;
  }

  .cattable_section .cattable_div .redcol {
    font-size: 14px;
  }

  .cattable_section .dropdown-toggle {
    font-size: 13px;
    width: 150px;
  }
  .cattable_section .dropdown-menu.show {
    width: 150px;
  }
  .cattable_section .nav-link.active {
    padding: 5px 12px;
    font-size: 12px !important;
  }
  .cattable_section .nav-link {
    padding: 5px 12px;
    font-size: 12px !important;
    width: 143px;
  }
  .cattable_section .nav.nav-pills {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 3rem;
    scroll-padding-left: 1rem;
    scrollbar-width: thin;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .cattable_section .cattable_div .smalltexxx {
    font-size: 12px;
  }
  .cattable_section .cattable_div .headdingss {
    font-size: 15px;
  }
  .cattable_section .cattable_div .greencol {
    font-size: 15px;
  }

  .cattable_section .cattable_div .redcol {
    font-size: 15px;
  }
  .cattable_section .dropdown-toggle {
    font-size: 14px;
    width: 160px;
  }
  .cattable_section .dropdown-menu.show {
    width: 160px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .cattable_section .cattable_div .smalltexxx {
    font-size: 13px;
  }
  .cattable_section .cattable_div .headdingss {
    font-size: 17px;
  }
  .cattable_section .cattable_div .greencol {
    font-size: 16px;
  }

  .cattable_section .cattable_div .redcol {
    font-size: 16px;
  }
  .cattable_section .dropdown-toggle {
    font-size: 15px;
    width: 170px;
  }
  .cattable_section .dropdown-menu.show {
    width: 170px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cattable_section .cattable_div .smalltexxx {
    font-size: 14px;
  }
  .cattable_section .cattable_div .headdingss {
    font-size: 19px;
  }
  .cattable_section .cattable_div .greencol {
    font-size: 17px;
  }

  .cattable_section .cattable_div .redcol {
    font-size: 17px;
  }
  .cattable_section .dropdown-toggle {
    font-size: 16px;
    width: 180px;
  }
  .cattable_section .dropdown-menu.show {
    width: 180px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cattable_section .cattable_div .greencol {
    font-size: 19px;
  }

  .cattable_section .cattable_div .redcol {
    font-size: 19px;
  }
  .cattable_section .dropdown-toggle {
    font-size: 17px;
    width: 190px;
  }
  .cattable_section .dropdown-menu.show {
    width: 190px;
  }
}
