/* .WhyUs css started */

.WhyUs {
    padding-bottom: 3%;
    width: 100%;
    background: #f5f5f5 0% 0% no-repeat padding-box;
}
.WhyUs .why-us-title .explore-btn {
    position: relative;
    color: black;
    font-weight: 500;
    text-align: center;
}

.WhyUs .why-us-title p.explore-btn:after {
    bottom: 32px;
    margin-left: 10px;
}

section.WhyUs {
    margin-top: 3%;
}
.WhyUs .why-us-title p.explore-btn:after,
.WhyUs .why-us-title p.explore-btn:before {
    background-color: black;
    content: "";
    height: 2px;
    position: absolute;
    width: 110px;
}

.WhyUs .why-us-title p.explore-btn:before {
    bottom: 31px;
    margin-left: -120px;
}
.WhyUs .why-us-banner img.why-image {
    width: 100%;
}
.WhyUs .why-us-title p.explore-btn {
    padding: 21px 0px;
}
.WhyUs .swiper {
    padding-bottom: 1%;
}
.WhyUs .swiper span.swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
    background-color: black;
    border-radius: 100px;
    height: 6px;
    width: 24px;
}
.WhyUs .swiper span.swiper-pagination-bullet.swiper-pagination-bullet-active-next {
    border-radius: 100px;
    width: 26px;
    background-color: orange;
    height: 6px;
    opacity: 1;
}
.WhyUs .swiper span.swiper-pagination-bullet.swiper-pagination-bullet-active-prev {
    border-radius: 100px;
    width: 26px;
    background-color: orange;
    height: 6px;
    opacity: 1;
}
/* .WhyUs css End */


/* Media Query started */

@media (min-width:0px) and (max-width:485px) {
    .why-us-banner {
        margin-bottom: 6%;
    }
    .WhyUs .why-us-title .explore-btn {
        font-size: 15px;
    }
    .WhyUs .why-us-title p.explore-btn:after, .WhyUs .why-us-title p.explore-btn:before {
      width: 60px;
    }
    .WhyUs .why-us-title p.explore-btn:before {
      
        margin-left: -78px;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .why-us-banner {
        margin-bottom: 6%;
    }
    .WhyUs .why-us-title .explore-btn {
        font-size: 16px;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .why-us-banner {
        margin-bottom: 6%;
    }
    .WhyUs .why-us-title .explore-btn {
        font-size: 16px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .why-us-banner {
        margin-bottom: 6%;
    }
    .WhyUs .why-us-title .explore-btn {
        font-size: 16px;
    }
    }