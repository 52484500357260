.log_in_form {
    border-radius: 5px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
}
 .log_in_form::before {
    content: "";
    position: absolute;
    top: -10px;
    left: calc(50% - 15px);
    width: 30px;
    height: 10px;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
}
.main-close {
    text-align: -webkit-right;
    margin: 0px 14px;
    padding-top: 13px;
}
.main-close .close {
    color: grey;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100px;
    height: 30px;
    width: 30px;
    text-align: center;
    background-color: #e9e9e9;
}
.Main-starting {
    padding: 33px 36px 1px 38px;
}
.PostSubmission .login-box-button {
    outline: 10px solid #b2b2b2;
}
.PostSubmission .log_in_form {
    box-shadow: none;
    padding: 0px;
}
ul.main-Post {
    padding-top: 45px;
}
li.small-text {
    list-style-type: none;
}
.small-text.post {
    font-weight: 600;
}
.okay button.orange {
    padding: 8px 90px;
    border: none;
    border-radius: 5px;
}
.okay{
    text-align: center;
}


@media (min-width:0px) and (max-width:485px) {
    .PostSubmission ul.main-Post {
        padding: 18% 0%;
        height: 515px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .PostSubmission .login-box-button {
        outline: 6px solid #b2b2b2;
    }
    .PostSubmission ul.main-Post ::-webkit-scrollbar {
        display: none;
    }
    .PostSubmission .login-box-button {
       
        width: 77%;
    }
}
@media (min-width:576px) and (max-width:991px) {
    .PostSubmission ul.main-Post {
        padding: 8% 0%;
        height: 515px;
        overflow-x: hidden;
        overflow-y: auto;
    }
   
    .PostSubmission ul.main-Post ::-webkit-scrollbar {
        display: none;
    }
}
