.hoteals_section .hotdeals_maindiv .topplate_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffaa00 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    padding: 5px 20px;
}
.hoteals_section .hotdeals_maindiv .dealtitle {
    font-size: 24px;
    font-weight: 600;
}

.hoteals_section .hotdeals_maindiv .showtext {
    cursor: pointer;
}

.hoteals_section .hotdeals_maindiv .swipe_div_mainss {
    position: relative;
}

.hoteals_section .hotdeals_maindiv .swiperoverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 10%;
    background: transparent linear-gradient(90deg, #ffffff 0%, #ffffff00 100%) 0% 0% no-repeat padding-box;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: start;
}
.hoteals_section .hotdeals_maindiv .swiperoverlay2 {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 10%;
    background: transparent linear-gradient(270deg, #ffffff 0%, #ffffff00 100%) 0% 0% no-repeat padding-box;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: end;
}

.hoteals_section .hotdeals_maindiv .swiperoverlay .back-btn {
    width: 17px;
    height: 17px;
    font-size: 12px;
    border-radius: 50%;
    background: #000 0% 0% no-repeat padding-box;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hoteals_section .hotdeals_maindiv .swiperoverlay2 .next-btn {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #000 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 0px) and (max-width: 485px) {
    .hoteals_section .hotdeals_maindiv .dealtitle {
        font-size: 16px;
    }
    section.hoteals_section {
        margin-bottom: 29%;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .hoteals_section .hotdeals_maindiv .dealtitle {
        font-size: 18px;
    }
    section.hoteals_section {
        margin-bottom: 29%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .hoteals_section .hotdeals_maindiv .dealtitle {
        font-size: 20px;
    }
    section.hoteals_section {
        margin-bottom: 25%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hoteals_section .hotdeals_maindiv .dealtitle {
        font-size: 22px;
    }
    section.hoteals_section {
        margin-bottom: 22%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    section.hoteals_section {
        margin-bottom: 22%;
    }
}