.Successfull_Modal .modal-header {
  border: none;
}

.Successfull_Modal .modal-body {
  border: none;
}

.Successfull_Modal .modal-content {

  border: none;
  box-shadow: 0px 3px 17px #00000012;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.modal-backdrop.show {
  /* opacity: 1; */
  /* background: none; */
}

.Successfull_Modal .modal-body p {
  margin-top: 29px;
  text-align: center;
 
  font-weight: bold;
  color: #000000;
  margin-bottom: 0px;
}

.Successfull_Modal .modal-body h4 {
  font-size: 14px;
  font-weight: bold;
  color: #f05340;
  text-decoration: underline;
}
/* .Successfull_Modal .close .btn-close {
  width: 20px;
  height: 20px;
  background: #F5F5F5 ;
  border: 1px solid #E9E7E7;
  border-radius: 100px;
} */
.Successfull_Modal .close .btn-close {
  width: 18px;
  height: 18px;
  background-color: #e7e7e7;
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
}
.Successfull_Modal .close .btn-close:focus {
  box-shadow: none;
}

