.Rate_Us_Modal .choose-img-section {
  display: flex;
}

.Rate_Us_Modal .img-preview-holder {
  background: #ab755c0f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 74px;
  height: 74px;
  aspect-ratio: 1 / 1;
  border: 1px solid #cccccc85;
  position: relative;
}

.Rate_Us_Modal .img-preview-holder .close-mark-holder {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
}

.Rate_Us_Modal .img-preview-holder .img-preview .preview {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  aspect-ratio: 1 / 1;
}

.Rate_Us_Modal .modbody {
  margin-top: 30px;
}
