.heart-icon.active {
  color: #fff;
}

.subscribe-btn {
  background: #ffffff;
  border-radius: 5px;
  color: #000000;
  border: none;
  padding: 10px 10px;
  font-weight: 700;
}



button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn {
  background: #faaa00 0 0 no-repeat padding-box;
  border: none;
  border-radius: 5px;
  color: #000;
  float: none;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 65px;
  text-align: center;
}

.permission-cancle{
  float: none;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
 
  padding: 8px 40px;
  margin: 0px 2px;
  font-weight: 500;
  color: #000000;
  border: 1px solid #707070;
}

.permission-submit{
  background: #faaa00 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;

  padding: 8px 40px;
  font-weight: 500;
  margin: 0px 2px;
  color: #000000;
  border: none;
}

button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn:hover {
  color: #fff;
}


button.button.button--webcoderskull.button--border-thick.button--text-medium.cancle-btn {
  float: none;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  padding: 2px 45px;
  font-weight: 400;
  color: #000000;
  border: 1px solid #707070;
  /* margin: 0px auto; */
}
button.button.button--webcoderskull.button--border-thick.button--text-medium.cancle-btn:hover {
  color: #fff;
}


.explore {
  border: none;
  padding: 9px 22px;
  background: #000000;
  border-radius: 29px;
}
/* Common button styles */
.button {
  float: left;
  font-weight: 600;
  display: block;
  margin: 1em;
  padding: 8px 18px;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.button:focus {
  outline: none;
}
.button > span {
  vertical-align: middle;
}
.button--webcoderskull {
  -webkit-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
}
.button--webcoderskull::before {
  border-radius: 5px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: -1;
  opacity: 0;
  -webkit-transform: scale3d(0.2, 1, 1);
  transform: scale3d(0.2, 1, 1);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--webcoderskull.button--round-s::before {
  border-radius: 2px;
}

.button--webcoderskull,
.button--webcoderskull::before {
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--webcoderskull:hover {
  color: #fff;
}

.button--webcoderskull:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button {
  border-color: white;
}
button.button.button--webcoderskull.button--border-thick.button--text-medium {
  border: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn.sub-title.submit {
  padding: 5px 44px;
}
/* Borders */
@media (min-width: 0px) and (max-width: 320px) {
  .subscribe-btn {
    font-size: 13px !important;
    padding: 7px 23px !important;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .subscribe-btn {
    font-size: 13px !important;
    padding: 7px 23px !important;
  }

  .permission-submit {
    padding: 5px 25px;
}

.permission-cancle {
  padding: 5px 25px;
}


}

@media (min-width: 486px) and (max-width: 575px) {
  .subscribe-btn {
    font-size: 13px !important;
    padding: 7px 23px !important;
  }

  .permission-submit {
    padding: 5px 25px;
}

.permission-cancle {
  padding: 5px 25px;
}
}

@media (min-width: 576px) and (max-width: 767px) {
  .subscribe-btn {
    font-size: 13px !important;
    padding: 7px 23px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .subscribe-btn {
    font-size: 13px !important;
    padding: 7px 23px !important;
  }
}


@media (min-width: 1199px) and (max-width: 992px) {
  .HomeBanner .Main-Banner .Button-Explore .explore {
    padding: 7px 13px;
    font-size: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .HomeBanner .Main-Banner .Button-Explore .explore {
    padding: 6px 15px;
    font-size: 18px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .HomeBanner .Main-Banner .Button-Explore .explore {
    padding: 7px 9px;
    font-size: 15px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .HomeBanner .Main-Banner .Button-Explore .explore {
    padding: 7px 13px;
    font-size: 15px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .HomeBanner .Main-Banner .Button-Explore .explore {
    font-size: 13px;
    padding: 7px 7px;
  }
  button.button.button--webcoderskull.button--border-thick.button--text-medium.cancle-btn {
    padding: 2px 19px;
     }
     button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn.sub-title.submit {
       padding: 5px 21px;
   }
}

@media (min-width: 0px) and (max-width: 320px) {
  .HomeBanner .Main-Banner .Button-Explore .explore {
    padding: 7px 7px;
    font-size: 8px;
  }
  button.button.button--webcoderskull.button--border-thick.button--text-medium.cancle-btn {
    padding: 2px 19px;
     }
     button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn.sub-title.submit {
       padding: 5px 21px;
   }
}
