.salelive_section {
  padding: 50px 0px;
  position: relative;
}

.salelive_section .toptext_span {
  border-bottom: 5px solid #ffaa00;
}

.salelive_section .toptext_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.salelive_main_div.countdown {
  display: flex;
}
.salelive_section .titleedd {
  font-size: 34px;
}

.salelive_section .coundown_div span {
  background: #f5f5f5;
  border-radius: 2px;
  color: #000;
  font-size: 17px;
  font-weight: 500;
  margin: 0 1px;
  padding: 1px 5px;
}
.salelive_section .swiper {
  padding-bottom: 2%;
}
.salelive_section
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 25px;
  height: 5px;
  border-radius: 5px;
  background-color: black;
}
.salelive_section span.swiper-pagination-bullet {
  width: 31px;
  height: 7px;
  border-radius: 5px;
  background-color: #ffaa00;
  opacity: 1;
}
.salelive_section .swipe_div_main {
  margin-top: 6%;
}
.salelive_section .toptext .coundown_div .counter .timer span {
  margin: 0px 2px;
  border: 1px solid #3b3b3b;
  height: 20px;
  background-color: #f5f5f5;
}
.salelive_section h2.title-main {
  font-weight: 800;
}
.salelive_section .swiper {
  position: unset;
}

.salelive_section .swiper-pagination-fraction {
  bottom: 0px;
}
.salelive_section .salelive_main_div .toptext_div .coundown_div .counter {
  display: flex;
  width: 100%;
  background-color: black;
  padding: 7px 3px;
}

.dotts {
  margin: 0px 1px;
  background: #f5f5f5;
  color: black;
  padding: 0px 4px;
  font-size: 15px;
  font-weight: 500;

  border-radius: 2px;
}
@media (min-width: 0px) and (max-width: 485px) {
  .salelive_section .titleedd {
    font-size: 18px;
  }

  .salelive_section .toptext_span {
    border-bottom: 2px solid #ffaa00;
  }
  .salelive_section .swiper {
    padding-bottom: 0%;
  }
  .salelive_section {
    padding: 36px 0px;
  }
  .dotts {
    margin: -1px 4px;
   
}
.salelive_section h2.title-main {
 line-height: 34px;
}
}

@media (min-width: 486px) and (max-width: 575px) {
  .salelive_section .titleedd {
    font-size: 20px;
  }

  .salelive_section .toptext_span {
    border-bottom: 2px solid #ffaa00;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .salelive_section .titleedd {
    font-size: 23px;
  }
  /* .salelive_section .coundown_div span {
    font-size: 18px;
  } */
  .salelive_section .toptext_span {
    border-bottom: 3px solid #ffaa00;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .salelive_section .titleedd {
    font-size: 26px;
  }
  .salelive_section .coundown_div span {
    font-size: 19px;
  }
  .salelive_section .toptext_span {
    border-bottom: 4px solid #ffaa00;
  }
}
