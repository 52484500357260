/* CustomerReviews css started */

.CustomerReviews .title-main {
    text-align: end;
}

.CustomerReviews .main-title .Top-Reviews {
    align-items: center;
    display: flex;
    padding: 4px 22px;
    width: fit-content;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 19px;
}

.CustomerReviews .main-title .Top-Reviews .text-p {
    font-weight: 500;
    margin-bottom: 0px;
}

.CustomerReviews .main-title .Top-Reviews .icon {
    font-size: 12px;
}

.CustomerReviews .main-title .review {
    text-align: -webkit-right;
}

.CustomerReviews .Rating-title .commmontext {
    border-bottom: 2px solid #b1b1b1;
    width: fit-content;
}

.CustomerReviews .Content-Six-Tab .rating-progressbar {
    display: flex;
}

.CustomerReviews .Content-Six-Tab .progress {
    width: 273px;
    height: 25px;
    margin: 2px 7px;
    margin-top: 7px;
    border-radius: 100px;
    border: 1px solid #9a9a9a;
}

.CustomerReviews .Content-Six-Tab .rating-progressbar .commmontext {
    margin-bottom: 15px;
    font-weight: 500;
}

.CustomerReviews .Content-Six-Tab .rating-progressbar .progress-bar.bg-secondary {
    background-color: #ffaa00 !important;
}

section.CustomerReviews {
    margin-top: 5%;
}

.CustomerReviews .main-title {
    margin-bottom: 4%;
}

.CustomerReviews .Content-Six-Tab {
    margin-top: 4%;
}

.CustomerReviews .star {
    display: flex;
    /* justify-content: space-around; */
    align-items: baseline;
}

.CustomerReviews .star svg.star-svg {
    height: 25px;
    width: 25px;
}

.CustomerReviews .reviews-Card .card-main {
    background: transparent linear-gradient(117deg, #f5f5f5 0%, #ffe8a7 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #f2daaa;
    border-radius: 5px;
    display: flex;
    align-items: baseline;
    padding: 9px 19px;
}

.CustomerReviews .reviews-Card .card-main .image-review img.logo {
    width: 40px;
    height: 40px;
}

.CustomerReviews .reviews-Card .card-main .image-review {
    border: 1px solid black;
    border-radius: 100px;
    padding: 2px 1px;
}

.CustomerReviews .reviews-Card .card-main .content .comment p.sub-text {
    color: black !important;
    font-weight: 500;
}

.CustomerReviews .reviews-Card .card-main .content .Reply_Content {
    background: #fff;
    padding: 5px 7px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.CustomerReviews .reviews-Card .card-main .content .Reply_Content p {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.CustomerReviews .reviews-Card .card-main .content .Reply_Content .image-review {
    border: 1px solid black;
    border-radius: 100px;
    padding: 2px 1px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    margin-bottom: 13px;
    width: 30px;
    height: 30px;
}

.CustomerReviews .reviews-Card .card-main .content .Reply_Content .image-review .logo {
    border-radius: 50px;
    width: 30px;
    height: 30px;
}

.CustomerReviews .reviews-Card .card-main .content {
    margin: 0px 11px;
    width: 100%;
}

.CustomerReviews .reviews-Card .card-main .content .star .rating-star {
    margin: 0px 34px;
}

.CustomerReviews .reviews-Card .card-main .content .like {
    display: flex;
    align-items: baseline;
}

.CustomerReviews .reviews-Card .card-main .content .like .icon2 {
    transform: rotate(177deg);
    margin: 0px 10px;
}

.CustomerReviews .reviews-Card .card-main .content .like .fill {
    color: #ffaa00;
}


.CustomerReviews .reviews-Card .card-main .content .star .commmontext {
    font-weight: 500;
}

.CustomerReviews .ratings-overflow {
    height: 500px;
    overflow-y: scroll;
}

/* CustomerReviews css started */


/************ Media Query Started ***************/
@media (min-width:0px) and (max-width:485px) {
    .CustomerReviews .title-main {
        text-align: center;
    }

    .CustomerReviews .main-title .Top-Reviews {
        margin-top: 11px;
    }

    .CustomerReviews .Content-Six-Tab .progress {
        width: 204px;
        height: 17px;
    }

    .CustomerReviews .reviews-Card .card-main {
        display: block;

    }

    .CustomerReviews .reviews-Card .card-main .image-review {
        width: fit-content;
    }

    .CustomerReviews .reviews-Card .card-main .content .comment p.sub-text {
        font-size: 14px;
    }

    .CustomerReviews .reviews-Card .card-main .content {
        margin: 10px 11px;
    }

    .CustomerReviews .star svg.star-svg {
        height: 19px;
        width: 19px;
    }

    .CustomerReviews .Content-Six-Tab .progress {
        width: 78%;
        height: 15px;
    }
}

@media (min-width:486px) and (max-width:767px) {
    .CustomerReviews .title-main {
        text-align: center;
    }

    .CustomerReviews .Content-Six-Tab .progress {
        width: 83%;
        height: 15px;
    }

    .CustomerReviews .star svg.star-svg {
        height: 21px;
        width: 21px;
    }

    .CustomerReviews .reviews-Card .card-main .content .comment p.sub-text {
        font-size: 14px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .CustomerReviews .Content-Six-Tab .progress {
        width: 83%;
        height: 15px;
    }
}