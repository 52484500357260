/* ChooseUs css Started */

.ChooseUs {
    margin-top: 3%;
}

.ChooseUs .chooseus_main_div {
    padding: 40px 15px 40px 15px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
}

.ChooseUs .chooseus_main_div .main {
    position: relative;
    padding-top: 39px;
}
.ChooseUs .chooseus_main_div .Step-choose {
    height: 177px;
    position: relative;
    padding-top: 77px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029; /* Adjusted box-shadow property */
    border: 1px solid #ffaa00;
    -webkit-mask: radial-gradient(circle 30px at top center, #0000 125%, #000) top left,
        radial-gradient(circle 30px at top center, #0000 181%, #000) top right,
        radial-gradient(circle 30px at bottom left, #0000 162%, #000) bottom left,
        radial-gradient(circle 30px at bottom right, #0000 98%, #000) bottom right;
    -webkit-mask-size: 100% 50%;
    -webkit-mask-repeat: no-repeat;
    z-index: -1;
}

.ChooseUs h1.choose.merriweather-bold {
    margin-top: 5%;
}
.ChooseUs .chooseus_main_div .carddheading {
    height: 49px;
    font-weight: 500;
    color: #000000;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
}

.ChooseUs .chooseus_main_div .carddtext {
    font-size: 16px;
    font-weight: 500;
    color: #000000 !important;
    font-family: "Roboto", sans-serif !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.ChooseUs .chooseus_main_div .circles {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: calc(50% - 36px);
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffaa00;
}
.ChooseUs .chooseus_main_div .circle11 {
    width: 47px;
    height: auto;
}

.ChooseUs .chooseus_main_div .borderciclee {
    position: absolute;
    z-index: 1;
    top: -39px;
    left: calc(50% - 43px);
    width: 93px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffaa00;
}
.ChooseUs .swiper-pagination {
    display: none;
}
.ChooseUs .swiper {
    padding: 0px 10px;
}
.Step-choose-container {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    position: relative;
   
}
/* ChooseUs css End */

/********************* Media Query started *****************/

@media (min-width: 0px) and (max-width: 575px) {
    .choose {
        text-align: center;
    }
    .ChooseUs .chooseus_main_div .carddheading {
        font-size: 18px;
    }
    .ChooseUs h1.choose.merriweather-bold {
        margin-top: 0%;
    }
    .ChooseUs .swiper {
        padding: 0 0px;
    }
}
@media (min-width: 576px) and (max-width: 991px) {
    .choose {
        text-align: center;
    }
    .ChooseUs .chooseus_main_div .carddheading {
        font-size: 18px;
    }
    .ChooseUs h1.choose.merriweather-bold {
        margin-top: 0%;
    }
}
@media (min-width: 992px) and (max-width: 1399px) {
    .ChooseUs h1.choose{
    margin-top: 20px;
}

}



