/* we offer section started */

section.We_Offer {
    margin-top: 5%;
    margin-bottom: 4%;
}
.We_Offer .We_Offer_bg_img_text_holder {
    position: relative;
}

.We_Offer .We_Offer_bg_img_text_holder .We_Offer_bg_img_holder .We_Offer_bg_img {
    width: 100%;
    height: 100%;
}

.We_Offer .We_Offer_bg_img_text_holder .btn-holder {
  position: absolute;
  bottom: 10%;
  right: 7%;
}

/* we offer section end */



/* Media Query started */

@media (min-width:0px) and (max-width:320px) {
  .We_Offer  .explore-btn {
    font-size: 9px;
    padding: 3px 5px !important;
  }
  .We_Offer .We_Offer_bg_img_text_holder .btn-holder {
    bottom: 0%;
}
}
@media (min-width:321px) and (max-width:485px) {
  .We_Offer  .explore-btn {
    font-size: 10px;
    padding: 6px 13px !important;
}
  .We_Offer .We_Offer_bg_img_text_holder .btn-holder {
    bottom: 0%;
}
}
@media (min-width:486px) and (max-width:575px) {
  .We_Offer  .explore-btn {
    font-size: 12px;
    padding: 8px 15px !important;
}
  .We_Offer .We_Offer_bg_img_text_holder .btn-holder {
    bottom: 0%;
}
}
@media (min-width:576px) and (max-width:767px) {
  .We_Offer  .explore-btn {
    font-size: 12px;
    padding: 8px 15px !important;
}
  .We_Offer .We_Offer_bg_img_text_holder .btn-holder {
    bottom: 0%;
}
}
@media (min-width:768px) and (max-width:991px) {
  .We_Offer  .explore-btn {
    font-size: 14px;
    padding: 8px 15px !important;
}
.We_Offer .We_Offer_bg_img_text_holder .btn-holder {
  bottom: 5%;
}
}