.Quantity_discount_modal .mod-body .tttt {
  text-align: center;
}
.Quantity_discount_modal .mod-body .quantdiv {
  margin: 10px 0px;
  border-bottom: 1px solid #ccc;
  justify-content: center;
}
.Quantity_discount_modal .mod-body .quantdiv p {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 500;
}
.Quantity_discount_modal .mod-body .quabox {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px 20px;
  margin: 5px 10px;
}

.Quantity_discount_modal .modal-header .modal-title {
  color: #ffaa00;
}
