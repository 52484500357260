
.QuickInquiry .log_in_form {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    padding: 26px 28px;
}
.QuickInquiry  .Button-Main {
    text-align: center;
    display: flex;
    justify-content: space-around;
}
 .QuickInquiry .Button-Main button.cancel {
    padding: 6px 62px;
    background-color: transparent;
    border: 1px solid #707070;
    border-radius: 5px;
}
.QuickInquiry .Button-Main button.submit.orange {
    padding: 7px 65px;
    border: none;
    border-radius: 5px;
}
.QuickInquiry .log_in_form .login-box-button {
    background: var(--unnamed-color-000000) 0 0 no-repeat padding-box;
    background: #000 0 0 no-repeat padding-box;
    border-radius: 25px;
    box-shadow: 0 3px 6px #00000029;
    color: #fff;
    left: 0;
    margin: 0 auto;
    outline: 10px solid #b2b2b2;
    outline-offset: 0;
    padding: 8px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: -28px;
    width: 60%;
}