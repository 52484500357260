.peoplesay_section {
    /* margin-bottom: 4%;
    margin-top: 10%; */
    position: relative;
    padding: 40px 0px;
}
.peoplesay_section .peoplesay_main {
    width: 100%;
    background-image: url(../../../../public/assests/HomePage/PeopleSays/backinnn.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 50px;
}

.peoplesay_section .peoplesay_main .titlle_div {
    background-color: #fff;
    border-radius: 0px 0px 50px 50px;
    padding-bottom: 15px;
    width: 65%;
}

.peoplesay_section .peoplesay_main .swiperrrdiv {
    padding: 0px 120px;
}
.peoplesay_section .peoplesay_main .img_div {
    width: 100%;

    padding: 0px 20px;
}

.peoplesay_section .peoplesay_main .imgsback {
    position: absolute;
    width: 90%;
    top: 0px;
    border-radius: 25px;
    background: #f8d160 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    height: 90%;
    z-index: -1;
}

.peoplesay_section .peoplesay_main .imgmain {
    width: 100%;
    position: relative;
}
.peoplesay_section .peoplesay_main .swipeimgg {
    width: 100%;
}

.peoplesay_section .peoplesay_main .borderdiv {
    border-bottom: 5px solid #ffaa00;
}

.peoplesay_section .peoplesay_main .quammaimgg {
    width: 100px;
}

.peoplesay_section .peoplesay_main .whatpeptitle {
    font-weight: bold;
    font-size: 34px;
    letter-spacing: 0px;
    color: #000000;
}

.peoplesay_section .peoplesay_main .nametext {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0px;
    color: #000000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 9;
}
.peoplesay_section .peoplesay_main .infotext {
    font-weight: bold !important;
}

.peoplesay_section .peoplesay-mySwiper .swiper-pagination-bullet-active {
    background: #ffaa00;
}

.peoplesay_section .peoplesay-mySwiper {
    position: unset !important;
}

.peoplesay_section .swiper-pagination-fraction,
.swiper-pagination-custom,
.peoplesay_section .swiper-horizontal > .swiper-pagination-bullets,
.peoplesay_section .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 27px !important;
}

.peoplesay_section span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 25px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
}

.peoplesay_section span.swiper-pagination-bullet {
    width: 20px;
    height: 5px;
    border-radius: 5px;
    background-color: #FFAA00;
    opacity: 1;
}

@media (min-width: 0px) and (max-width: 485px) {
    .peoplesay_section .peoplesay_main .titlle_div {
        width: 90%;
        padding-bottom: 10px;
    }
    .peoplesay_section .peoplesay_main .whatpeptitle {
        font-size: 20px;
    }
    .peoplesay_section .peoplesay_main .borderdiv {
        border-bottom: 2px solid #ffaa00;
    }
    .peoplesay_section .peoplesay_main .swiperrrdiv {
        padding: 0px 10px;
    }
    .peoplesay_section .peoplesay_main .nametext {
        font-size: 11px;
    }
    .peoplesay_section .peoplesay_main .quammaimgg {
        width: 60px;
        margin-top: 10px;
        padding-left: 15px;
    }
    .peoplesay_section .peoplesay_main .img_div {
        padding: 0px 40px;
    }
    .peoplesay_section .peoplesay_main {
      
        margin-bottom: 17px;
    }
    .peoplesay_section {
     margin-bottom: 23%;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .peoplesay_section .peoplesay_main .titlle_div {
        width: 80%;
        padding-bottom: 11px;
    }
    .peoplesay_section .peoplesay_main .whatpeptitle {
        font-size: 22px;
    }
    .peoplesay_section .peoplesay_main .borderdiv {
        border-bottom: 2px solid #ffaa00;
    }
    .peoplesay_section .peoplesay_main .swiperrrdiv {
        padding: 0px 10px;
    }
    .peoplesay_section .peoplesay_main .nametext {
        font-size: 12px;
    }
    .peoplesay_section .peoplesay_main .quammaimgg {
        width: 70px;
        margin-top: 10px;
        padding-left: 15px;
    }
    .peoplesay_section .peoplesay_main .img_div {
        padding: 0px 60px;
        text-align: -webkit-center;
    }
    .peoplesay_section .peoplesay_main .imgmain {
     
        width: 221px;
        height: 251px;
    }
    .peoplesay_section .peoplesay_main {
      
        margin-bottom: 22px;
    }
    .peoplesay_section {
        margin-bottom: 23%;
       }
}

@media (min-width: 576px) and (max-width: 767px) {
    .peoplesay_section .peoplesay_main .titlle_div {
        width: 75%;
        padding-bottom: 12px;
    }
    .peoplesay_section .peoplesay_main .whatpeptitle {
        font-size: 25px;
    }
    .peoplesay_section .peoplesay_main .borderdiv {
        border-bottom: 3px solid #ffaa00;
    }
    .peoplesay_section .peoplesay_main .swiperrrdiv {
        padding: 0px 10px;
    }
    .peoplesay_section .peoplesay_main .nametext {
        font-size: 13px;
    }
    .peoplesay_section .peoplesay_main .quammaimgg {
        width: 75px;
        margin-top: 10px;
        padding-left: 15px;
    }
    .peoplesay_section .peoplesay_main .img_div {
        padding: 0px 80px;
    }
    .peoplesay_section .peoplesay_main {
      
        margin-bottom: 28px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .peoplesay_section .peoplesay_main .titlle_div {
        width: 70%;
        padding-bottom: 13px;
    }
    .peoplesay_section .peoplesay_main .whatpeptitle {
        font-size: 28px;
    }
    .peoplesay_section .peoplesay_main .borderdiv {
        border-bottom: 4px solid #ffaa00;
    }
    .peoplesay_section .peoplesay_main .swiperrrdiv {
        padding: 0px 10px;
    }
    .peoplesay_section .peoplesay_main .nametext {
        font-size: 15px;
    }

    .peoplesay_section .peoplesay_main .quammaimgg {
        width: 80px;
        margin-top: 10px;
        padding-left: 15px;
    }
}
