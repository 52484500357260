.Footer {
  background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px -9px 6px #0000000f;
  border-radius: 50px 50px 0px 0px;
  opacity: 1;
  margin-top: 14%;
  padding-top: 30px;
  position: relative;
}
.footer-list-holder {
  margin-top: 1%;
}
.Footer .logo-footer .logo {
  height: 100%;
  width: 100%;
}

.Footer .logo-footer {
  left: 0px;
  right: 0px;
  top: -36%;
  position: absolute;
  width: -moz-fit-content;
  height: 90%;
  width: fit-content;
  margin: 0px auto;
}
.Footer p.text {
  color: white;
}
.Footer .content .icon-footer {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.Footer .content .icon {
  display: flex;
  margin-bottom: 14px;
}
.Footer .logo-content {
  /* margin-top: 113px; */
  padding-top: 25%;
}
.Footer p.footer-text:after {
  background-color: white;
  content: "";
  height: 3px;
  position: absolute;
  width: 31px;
  bottom: 3px;
}
.Footer p.footer-text {
  position: relative;
}
.Footer .content .footer-text .text {
  font-weight: 300;
}
.Footer .content .footer-text .text {
  margin-bottom: 12px;
}
.Footer .footer-text .icon p.text.main {
  margin-bottom: 25px;
}
.Footer .logo-text {
  text-align: -webkit-center;
  position: relative;
}
.Footer .note {
  padding: 6px 0px;
  background: #ffaa00 0% 0% no-repeat padding-box;
  border-radius: 21px 21px 0px 0px;
  text-align: center;
  margin: 0px auto;
  width: 25%;
}
.Footer .main1 {
  display: flex;
  justify-content: end;
}
.Footer .note p.note-text {
  margin-bottom: 0px;
  color: #000000;
  font-size: 10px;
  font-weight: 500;
}

.Footer .footer-last-col {
  display: flex;
  justify-content: end;
}
.Footer .small-text {
  margin-bottom: 8px;
  color: white;
  font-weight: 400;
}
@media (min-width: 0px) and (max-width: 320px) {
  .Footer .logo-footer {
    top: -3%;
    height: 7%;
    margin: 0px auto;
  }
  .Footer .note {
    width: 97%;
  }

  .Footer .logo-content {
    padding-top: 0%;
  }

  .Footer p.footer-text:after {
    display: none;
  }
  .Footer .main1 {
    justify-content: start;
  }
  .Footer .footer-last-col {
    justify-content: start;
  }
  .Footer .small-text {
    color: white;
    font-weight: 300;
    margin-bottom: 11px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Footer .logo-footer {
    top: -5%;
    height: 12%;
    margin: 0px auto;
  }

  .Footer .note {
    width: 97%;
  }
  .Footer .logo-content {
    padding-top: 0%;
  }

  .Footer p.footer-text:after {
    display: none;
  }
  .Footer .main1 {
    justify-content: start;
  }
  .Footer .footer-last-col {
    justify-content: start;
  }
  .Footer .small-text {
    color: white;
    font-weight: 300;
    margin-bottom: 11px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Footer .logo-footer {
    top: -6%;
    height: 15%;
    margin: 0px auto;
  }

  .Footer .note {
    width: 97%;
  }
  .Footer .logo-content {
    padding-top: 0%;
  }

  .Footer p.footer-text:after {
    display: none;
  }
  .Footer .main1 {
    justify-content: start;
  }
  .Footer .footer-last-col {
    justify-content: start;
  }
  .Footer .small-text {
    color: white;
    font-weight: 300;
    margin-bottom: 11px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Footer .logo-footer {
    top: -10%;
    height: 26%;
    margin: 0px auto;
  }

  .Footer .note {
    width: 65%;
  }
  .Footer .logo-content {
    padding-top: 0%;
  }
  .Footer .small-text {
    color: white;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .Footer .footer-last-col {
    padding: 20px 41px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Footer .logo-footer {
    top: -12%;
    height: 33%;
    margin: 0px auto;
  }

  .Footer .note {
    width: 53%;
  }

  .Footer .logo-content {
    padding-top: 0%;
  }
  .Footer .footer-last-col {
    padding: 20px 41px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Footer .logo-footer {
    top: -21%;
    height: 58%;
    margin: 0px auto;
  }

  .Footer .note {
    width: 40%;
  }

  .Footer .logo-content {
    padding-top: 80%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Footer .logo-footer {
    top: -26%;
    height: 66%;
    margin: 0px auto;
  }

  .Footer .note {
    width: 35%;
  }

  .Footer .logo-content {
    padding-top: 65%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Footer .logo-footer {
    top: -28%;
    height: 70%;
    margin: 0px auto;
  }

  .Footer .note {
    width: 35%;
  }

  .Footer .logo-content {
    /* margin-top: 113px; */
    padding-top: 35%;
  }
}
@media (min-width: 1400px) and (max-width: 1440px) {
  .Footer .logo-footer {
    top: -36%;
    height: 83%;
  }
}
