.Modal-holder .LogIn .log_in_form .Main-register {
    padding-top: 7%;
}
.Modal-holder .LogIn .log_in_form .Main-register .nav-link.active {
   background-color: transparent;
}
.Modal-holder .LogIn .log_in_form .Main-register  .border-regis {
    margin: 0px 70px;
    border: 0.2px solid #FFAA00;
}

.Modal-holder .LogIn .log_in_form .Main-register .check-box-holder{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal-holder .LogIn .log_in_form .Main-register .check-box-holder .form-check {
    padding: 10px 27px;
}


.Modal-holder .LogIn .log_in_form .Main-register .form-field-sec-holder{
    padding: 3% 12%;
}
.Modal-holder .LogIn  .create_account .login_text span.sub-text {
    color: black !important;
}
.Modal-holder .LogIn  .create_account .login_text  span.sub-text.open {
font-weight: 600 !important;
}
.Modal-holder .LogIn .tab-content {
    padding: 3% 12%;
     
}
/* .Modal-holder .LogIn .second-main {
    height: 297px;
    overflow-x: hidden;
    overflow-y: auto;
} */
/* WebKit-specific scrollbar styling */
.Modal-holder .LogIn .second-main::-webkit-scrollbar {
    display: none;
}

button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn.sub-title.create {
    padding: 6px 90px;
    margin: 6px;
}
.Modal-holder .LogIn .create {
    text-align: -webkit-center;
}
.Modal-holder .LogIn .back_to_span p {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;
}




@media (min-width:0px) and (max-width:320px) {
    button.button.button--webcoderskull.button--border-thick.button--text-medium.login-btn.sub-title.create {
        padding: 6px 36px;
        margin: 6px;
    }

    .Modal-holder .LogIn .log_in_form .Main-register  .border-regis {
        margin: 0px 0px;
    }
    

    /* .Modal-holder .LogIn .log_in_form .Main-register .check-box-holder {
        display: block;
    } */
    
    .Modal-holder .LogIn .log_in_form .Main-register .check-box-holder .form-check {
        padding: 5px 25px;
    }
    .Modal-holder .LogIn .log_in_form .Main-register .form-field-sec-holder {
        padding: 3% 1%;
    }
}
@media (min-width:321px) and (max-width:485px) {
.Modal-holder .LogIn .log_in_form .Main-register  .border-regis {
    margin: 0px 24px;
    border: 0.2px solid #FFAA00;
}
}
@media (min-width:486px) and (max-width:575px) {
.Modal-holder .LogIn .log_in_form .Main-register  .border-regis {
    margin: -1px 40px;
    border: 0.2px solid #FFAA00;
}
}
@media (min-width:1400px) and (max-width:1500px) {
.Modal-holder .LogIn .log_in_form .Main-register  .border-regis {
    margin: 0px 34px;
    border: 0.2px solid #FFAA00;
}
}
@media (min-width:0px) and (max-width:1399px) {
.Modal-holder .LogIn .second-main {
    height: 297px;
    overflow-x: hidden;
    overflow-y: auto;
}


}