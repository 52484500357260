/* Customize-Tape css started */

.Customize-Tape .Banner-Customize img.custome {
    height: 100%;
    width: 100%;
    display: block;
}
.Customize-Tape .Banner-Customize img.custome2 {
    height: 100%;
    width: 100%;
    display: none;
}
.Customize-Tape .form-check-input {
    border: 1px solid #707070;
    width: 15px;
    height: 15px;
}
.Customize-Tape .form-check-input:checked[type=radio] {
    width: 18px;
    height: 18px;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23FFAA00%27/%3e%3c/svg%3e") !important;
}
.Customize-Tape .form-check-input:checked {
    background-color: transparent !important;
    border-color: #0d6efd;
    width: 18px;
    height: 18px;
}
.Customize-Tape .form-check-input[type=radio] {
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.Customize-Tape .Banner-Customize {
    /* position: relative; */
}
.Customize-Tape .Banner-Customize .Main-customize {
    /* position: absolute; */
    /* top: 64%; */
    /* width: 100%; */
    margin-top: -5%;
}
.Customize-Tape .Banner-Customize .Main-customize .checkout-process-step-form {
    padding: 31px 19px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}

/* Customer information css started */
.Customer-Information label.sub-title.form-label {
    font-weight: 500;
}


.Customer-Information .form-control {
    background: #f5f5f5 0% 0% no-repeat padding-box !important ;
    border-radius: 0px !important;
    border: none !important;
    padding: 11px 0px !important;
}
.Customer-Information .checkout-process-step-form .progress-bar-container .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: #000;
    font-size: 20px;
    border: 1px solid #b2b2b2;
    width: 86%;
    margin: 4px 0px;
}
section.ProductInformation {
    margin-top: 4%;
    /* padding: 37px 35px; */
}

section.ProductInformation .form-check-label {
    /* font-size: 18px; */
    font-weight: 400;
    color: #000000;
}
section.Customer-Information {
    padding: 37px 35px;
}
.Customer-Information .Save-Btn button.tabs-btn {
    border-radius: 5px;
    border: none;
    padding: 7px 45px;
}
.Customer-Information .Save-Btn {
    text-align: center;
    margin-top: 10%;
}
.checkout-process-step-form {
    margin-top: 2%;
    margin-bottom: 4% !important;
}
/* Customize-Tape css End */

/* Production css started */

.ProductInformation .Product .radio-btn-input-holder .sub-title.form-check {
    margin-bottom: 9%;
}

.ProductInformation .apply_now_btn button.tabs-btn {
    border-radius: 5px;
    border: none;
    padding: 6px 41px;
}
.ProductInformation .apply_now_btn button.back-btn {
    border-radius: 5px;
    border: none;
    color: white;
    padding: 6px 72px;
}
.ProductInformation .apply_now_btn {
    text-align: center;
    margin-top: 3%;
}

/* Order Details */
.ProductInformation form.main-product {
    padding: 0px 0px 0px 70px;
}
.ProductInformation p.sub-title {
    margin-left: 42px;
}
.OrderDetail .order-main {
    display: flex;
    justify-content: space-between;
}
.OrderDetail .form-control {
    font-weight: 600;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #000000;
    font-size: 14px;
    padding: 16px 23px;
    border: none;
}
.OrderDetail .Product .main-product .radio-btn-input-holder .sub-title.form-check {
    margin-bottom: 11%;

}
.OrderDetail label.form-check-label {
    margin: 0px 11px;
}
.OrderDetail .apply_now_btn button.back-btn {
    border-radius: 5px;
    border: none;
    padding: 7px 65px;
    color: white;
}
.OrderDetail .apply_now_btn button.tabs-btn {
    border-radius: 5px;
    border: none;
    padding: 7px 65px;
}
.OrderDetail .apply_now_btn {
    text-align: center;
    margin-top: 7%;
}
.OrderDetail {
    padding: 2px 41px;
}



/* Customer Feedback */


.CustomerFeedback .Feedback {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
}
.CustomerFeedback .Feedback h1.title-customer {
    font-size: 58px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.03;
    font-weight: 600;
    text-align: center;
}
.CustomerFeedback .Feedback .form-control {
    background: #f5f5f5 0% 0% no-repeat padding-box !important;
    border-radius: 0px !important;
    border: none !important;
    padding: 20px 33px;
    font-size: 14px;
    color: black;
    font-weight: 500;
}
.CustomerFeedback .Feedback .feed-rating {
    display: flex;
    justify-content: space-between;
}
.CustomerFeedback .Feedback .feed-rating svg.star-svg {
    width: 25px;
    height: 25px;
}
.CustomerFeedback .Feedback  .form-floating>label {
  padding: 14px 20px !important;
  
}
.CustomerFeedback .Feedback .Main-Feed {
    padding: 1px 62px;
    margin-bottom: 12%;
}
.CustomerFeedback .Feedback .submit button.submit.small-text.orange {
    border-radius: 5px;
    border: none;
    padding: 6px 30px;
}
.CustomerFeedback .Feedback  .submit {
    margin-top: 3%;
    text-align: center;
    margin-bottom: 6%;
}



/* Media Query started */

@media (min-width:0px) and (max-width:485px) {
    section.Customer-Information {
        padding: 27px 2px;
    }
    .ProductInformation p.sub-title {
        margin-left: 0px;
    }
    .ProductInformation form.main-product {
        padding: 0px 0px 0px 20px;
    }
    .ProductInformation .apply_now_btn button.back-btn {
      padding: 6px 29px;
    }
    .ProductInformation .apply_now_btn button.tabs-btn {
      padding: 6px 15px;
    }
    section.OrderDetail {
        padding: 2px 0px;
    }
    .OrderDetail .apply_now_btn button.back-btn {
      padding: 7px 25px;
    }
    .OrderDetail .apply_now_btn button.tabs-btn {
      
        padding: 7px 25px;
    }
    .CustomerFeedback .Feedback h1.title-customer {
        font-size: 35px;
    }
    .CustomerFeedback .Feedback .Main-Feed {
        padding: 1px 23px;
       
    }
    .step-container .small-text {
        font-size: 11px !important;
    }
    .Customize-Tape .Banner-Customize .Main-customize {
      top: 106%;
    }
    .Customize-Tape .Banner-Customize img.custome {
       display: none;
    }
    .Customize-Tape .Banner-Customize img.custome2 {
       display: block;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .CustomerFeedback .Feedback h1.title-customer {
        font-size: 38px;
    }
    .OrderDetail .apply_now_btn button.back-btn {
        padding: 8px 32px;
    }
      .OrderDetail .apply_now_btn button.tabs-btn {
        padding: 7px 25px;
      }
      .step-container .small-text {
        font-size: 11px !important;
    }
    .checkout-process-step-form {
        margin-top: 8%;
    }
    .Customize-Tape .Banner-Customize img.custome {
        display: none;
     }
     .Customize-Tape .Banner-Customize img.custome2 {
        display: block;
     }
}
@media (min-width:576px) and (max-width:767px) {
    .checkout-process-step-form {
        margin-top: 8%;
    }
    .step-container .small-text {
        font-size: 11px !important;
    }
    .ProductInformation p.sub-title {
        margin-left: 31px;
    }
    .ProductInformation form.main-product {
        padding: 0px 0px 0px 52px;
    }
    .CustomerFeedback .Feedback h1.title-customer {
        font-size: 40px;
    }
    .Customize-Tape .Banner-Customize img.custome {
        display: none;
     }
     .Customize-Tape .Banner-Customize img.custome2 {
        display: block;
     }
}