/* Voucher css started */

.Voucher .Voucher-banner img.voucher-banner {
    width: 100%;
    height: 100%;
    display: block;
}
.Voucher .Voucher-banner img.voucher-banner2 {
    width: 100%;
    height: 100%;
    display: none;
}
.Voucher  .voucher-cards .card-sale {
 
    background: #d73434 linear-gradient(0deg, #FF3400 0%, #FFC400 100%) 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 0px 6px #00000029; */
    opacity: 1;
    border-radius: 5px;
    position: relative;
}
.Voucher  .voucher-cards{
    position: relative;
}
.Voucher .voucher-cards .card-sale .coupon-heading {
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 13px;
    width: max-content;
    color: white;
    padding: 4px 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -17px;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
.Voucher .voucher-cards .card-sale .coupon-heading span.sub-title.off {
    color: #FF0000;
    font-weight: 400;
}
.Voucher .voucher-cards .card-sale .coupon-heading p.sub-title {
    color: white;
    font-weight: 400;
    margin-bottom: 0px;
}
.Voucher .voucher-cards .card-sale {
    text-align: -webkit-center;
    margin-bottom: 14%;
}
.Voucher .voucher-cards .card-sale .image-main img.coupon-img {
    height: 100%;
    width: 100%;
    padding: 4px 4px;
}
.Voucher .voucher-cards .card-sale .image-main {
    top: 33px;
    position: relative;
    color: #FFFFFF;
    border-style: dashed;
    border-width: 2px;
    width: 199px;
    height: 164px;
    border-radius: 10px;
}
.Voucher .voucher-cards .card-sale .code-main {
    margin-top: 55px;
}
.Voucher .voucher-cards .card-sale .code-main .code {
    display: flex;
    justify-content: space-around;
    margin-left: 8px;
}
.Voucher .voucher-cards .card-sale .code-main .code .copy {
    display: flex;
}
.Voucher .voucher-cards .card-sale .code-main .code2 .countdown .main-time {
    display: flex;
    /* margin: 0px 20px 0px 0px; */
    position: relative;
    left: -15px;
}
.Voucher .voucher-cards .card-sale .code-main .code2 {
    display: flex;
    justify-content: space-around;
}
.Voucher .voucher-cards .card-sale .code-main .code2 .countdown {
    display: flex;
}
.Voucher .voucher-cards .card-sale .coupon-heading .sub-title {
    color: white;
  font-weight: 300;
}
.Voucher .voucher-cards .card-sale .code-main .small-text {
    font-weight: 300;
    color: white;
    margin-bottom: 12px;
}
.Voucher .voucher-cards .card-sale .code-main .code .copy  .icon {
    margin: 0px 13px;
    color: white;
}
.Voucher .voucher-cards .card-sale .white-strip {
    margin-top: 5px;
    width: 198px;
    height: 24px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 21px 21px 0px 0px;
    box-shadow: inset 0px 11px 11px -7px #0000005c;
}
.Voucher .voucher-cards {
    margin-top: 3%;
}


/*********************** Media Query Started *******************************/

@media (min-width:0px) and (max-width:320px) {
    .Voucher  .voucher-cards .card-sale {
        width: min-content;
        padding: 0px 39px;
    }
    .Main-voucher {
        text-align: -webkit-center;
    }
    .Voucher .voucher-cards {
        margin-top: 12%;
    }
    section.Voucher {
        margin-bottom: 26%;
    }
    .Voucher .Voucher-banner img.voucher-banner {
        display: none;
    }
    .Voucher .Voucher-banner img.voucher-banner2 {
        display: block;
    }
}
@media (min-width:321px) and (max-width:485px) {
    .Voucher  .voucher-cards .card-sale {
        width: min-content;
        padding: 0px 39px;
    }
    .Main-voucher {
        text-align: -webkit-center;
    }
    .Voucher .voucher-cards {
        margin-top: 12%;
    }
    section.Voucher {
        margin-bottom: 26%;
    }
    .Voucher .Voucher-banner img.voucher-banner {
        display: none;
    }
    .Voucher .Voucher-banner img.voucher-banner2 {
        display: block;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .Voucher  .voucher-cards .card-sale {
        width: min-content;
        padding: 0px 39px;
    }
    .Main-voucher {
        text-align: -webkit-center;
    }
    .Voucher .voucher-cards {
        margin-top: 12%;
    }
    section.Voucher {
        margin-bottom: 26%;
    }
    .Voucher .Voucher-banner img.voucher-banner {
        display: none;
    }
    .Voucher .Voucher-banner img.voucher-banner2 {
        display: block;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .Voucher .voucher-cards {
        margin-top: 12%;
    }
    section.Voucher {
        margin-bottom: 26%;
    }
    .Voucher .Voucher-banner img.voucher-banner {
        display: none;
    }
    .Voucher .Voucher-banner img.voucher-banner2 {
        display: block;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .Voucher .voucher-cards .card-sale .image-main {
        width: 161px;
        height: 139px;
    }
    .Voucher .voucher-cards .card-sale .coupon-heading {
      padding: 3px 11px;
      left: 50%;
      transform: translateX(-50%);
      }
      .Voucher .voucher-cards {
        margin-top: 10%;
    }
    section.Voucher {
        margin-bottom: 24%;
    }
    .Voucher .Voucher-banner img.voucher-banner {
        display: none;
    }
    .Voucher .Voucher-banner img.voucher-banner2 {
        display: block;
    }
}
@media (min-width:992px) and (max-width:1199px) {
    section.Voucher {
        margin-bottom: 24%;
    }
}