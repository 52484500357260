/* inquriy_section css started */

.inquriy_section .inquiry_main{
    border-radius: 10px;
    padding: 41px 130px;
    width: 100%;
    background-image: url(../../../../public/assests/HomePage/Personalise/inqury_back.png);
    background-size: cover; 
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inquriy_section .inquiry_main .inquiry_text_div{
    border-left: 8px solid #fff;
    padding-left: 20px;
}

.inquriy_section .inquiry_main .lookingtext{
    font-size: 25px;
    color: #fff;
}
.inquriy_section .inquiry_main .heretext{
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}
section.inquriy_section {
    margin-top: 9%;
    margin-bottom: 8%;
}
.inquriy_section .inquiry_main .inqbtndiv .button--webcoderskull::before {
    background-color: #FFAA00;
   
}
.inquriy_section .inquiry_main .inqbtndiv button.button.button--webcoderskull.button--border-thick.button--text-medium {
    margin: 0px 0px;
    color: black !important;
}
/* inquriy_section css end */



/********************** Media Query started ***********************/

@media (min-width:0px) and (max-width:320px){
    .inquriy_section .inquiry_main .lookingtext {
        font-size: 13px;
        margin-bottom: 0px;
    }
    .inquriy_section .inquiry_main .heretext {
        font-size: 14px;
    }
    .inquriy_section .inquiry_main .inquiry_text_div{
        border-left: 0px solid #fff;
        padding-left: 2px;
    }
    .inquriy_section .inquiry_main {
        padding: 11px 11px;
        display: inline-block;
    }
    .inquriy_section  .explore-btn {
        font-size: 9px;
        padding: 3px 5px !important;
      }
      .inquriy_section .inqbtndiv {
        margin-top: 7px;
    }
}
@media (min-width:321px) and (max-width:485px){
    .inquriy_section .inquiry_main .lookingtext {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .inquriy_section .inquiry_main .heretext {
        font-size: 14px;
    }
    .inquriy_section .inquiry_main .inquiry_text_div{
        border-left: 0px solid #fff;
        padding-left: 2px;
    }
    .inquriy_section .inquiry_main {
        display: inline-block;
        padding: 11px 12px;
      
    }
    .inquriy_section .inqbtndiv {
        margin-top: 7px;
    }
    .inquriy_section  .explore-btn {
        font-size: 12px;
        padding: 6px 13px !important;
    }
}

@media (min-width:486px) and (max-width:575px){
    .inquriy_section .inquiry_main .lookingtext {
        font-size: 18px;
    }
    .inquriy_section .inquiry_main .heretext {
        font-size: 15px;
    }
    .inquriy_section .inquiry_main .inquiry_text_div{
        border-left: 4px solid #fff;
    }
    .inquriy_section .inquiry_main {
        flex-wrap: wrap;
        padding: 23px 22px;
    }
     .inquriy_section  .explore-btn {
        font-size: 14px;
        padding: 8px 15px !important;
    }
    .inquriy_section  .inqbtndiv {
        margin-top: 3%;
        margin-left: 5%;
    }
}

@media (min-width:576px) and (max-width:767px){
    .inquriy_section .inquiry_main .lookingtext {
        font-size: 19px;
    }
    .inquriy_section .inquiry_main .heretext {
        font-size: 16px;
    }
    .inquriy_section .inquiry_main .inquiry_text_div{
        border-left: 5px solid #fff;
    }
    .inquriy_section .inquiry_main{
        flex-wrap: wrap;
        padding: 23px 31px;
     }
     .inquriy_section  .explore-btn {
        font-size: 15px;
        padding: 8px 15px !important;
    }
    .inquriy_section  .inqbtndiv {
        margin-top: 3%;
        margin-left: 5%;
    }
}
@media (min-width:768px) and (max-width:991px){
    .inquriy_section .inquiry_main .lookingtext {
        font-size: 20px;
    }
    .inquriy_section .inquiry_main .heretext {
        font-size: 18px;
    }
    .inquriy_section .inquiry_main .inquiry_text_div{
        border-left: 6px solid #fff;
    }
    .inquriy_section .inquiry_main{
        flex-wrap: wrap;
        padding: 19px 47px;
     }
     .inquriy_section  .explore-btn {
        font-size: 16px;
        padding: 8px 15px !important;
    }
    .inquriy_section  .inqbtndiv {
        margin-top: 3%;
        margin-left: 5%;
    }
}
@media (min-width:992px) and (max-width:1199px){
  
    .inquriy_section .inquiry_main{
        flex-wrap: wrap;
     }
     .inquriy_section  .explore-btn {
        font-size: 16px;
        padding: 8px 15px !important;
    }
    .inquriy_section  .inqbtndiv {
        margin-top: 3%;
        margin-left: 5%;
    }
}
@media (min-width:1200px) and (max-width:1399px){
    .inquriy_section  .explore-btn {
        font-size: 16px;
        padding: 8px 15px !important;
    }
}