/* progress bar css start*/
.checkout-process-step-form {
    margin-top: 2%;
    /* margin-bottom: 12%; */
  }
  
  .checkout-process-step-form .checkout-process-step-form_Tab .nav-pills .nav-link {
    text-align: left;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #b2b2b2;
    padding: 5px 150px 5px 0px;
  }
  
  .checkout-process-step-form .checkout-inner-steps {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dee2e6;
    padding: 40px 25px;
    border-radius: 5px;
  }
  
  .checkout-process-step-form .checkout-process-step-form_Tab .nav-pills .nav-link.active {
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    background: none;
    border-bottom: 2px solid #f05340;
    border-radius: 0px;
  }
  
  .checkout-process-step-form .progress-bar-container .step-row {
    display: flex;
    align-items: center;
    justify-content: normal;
    width: 100%;
  }
  
  .checkout-process-step-form .steps {
    margin-top: 2%;
  }
  
  .checkout-process-step-form .progress-bar-container .step-container {
    position: relative;
    width: 100%;
    margin-right: 10px;
    color: #B2B2B2;
  }
  
  .checkout-process-step-form .progress-bar-container .progress-bar-container {
    margin-bottom: 25px;
  }
  

  /* .checkout-process-step-form .progress-bar-container span.step-text {
    font-weight: bold;
} */
.checkout-process-step-form .progress-bar-container .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  color: #000;
  font-size: 20px;
  border: 1px solid #b2b2b2;
  width: 95%;
  margin: 4px 0px;
}
.checkout-process-step-form .progress-bar-container  .step-container.active {
  font-weight: bold;
  color: black;
}
  .checkout-process-step-form .progress-bar-container .circle.completed {
    border: 1px solid #FFAA00;
  }
  
  .checkout-process-step-form .step-container.active .circle {
    border: 1px solid #FFAA00;
    margin: 4px 0px;
}
  
  .checkout-process-step-form .checkout-inner-steps .main-btn-div .save-btn {
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 5px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #212121;
    color: #ffffff;
    padding: 7px 22px;
    margin: 0px 5px;
  }
  
  .checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
    background: #B7B7B7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 1px solid #B7B7B7;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 7px 50px;
    margin: 0px 5px;
  }
  
  .checkout-process-step-form .checkout-inner-steps .main-btn-div {
    text-align: end;
    /* margin-top: 4%; */
  }
  
  .checkout-process-step-form .checkout-inner-steps .form-check-input:checked {
    background-color: white;
    border-color: #000;
}
  .checkout-process-step-form .checkout-inner-steps .form-check-input:checked:focus {
    box-shadow: none;
  }
  
  .checkout-process-step-form .checkout-inner-steps .form-check-input {
    width: 19px;
    height: 19px;
}
  
  /* progress bar css end */
  
  @media (min-width: 280px) and (max-width: 320px) {
    .checkout-process-step-form .checkout-inner-steps .main-btn-div {
      text-align: center;
    }
  
    .checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
      margin-bottom: 11%;
    }
  
    .checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
      padding: 6px 34px;
      margin: 0px 1px;
    }
  
    .checkout-process-step-form .checkout-inner-steps .main-btn-div .save-btn {
  
      padding: 5px 10px;
      margin: 0px 2px;
    }
  
    .checkout-process-step-form .checkout-inner-steps {
      padding: 24px 10px;
      border-radius: 5px;
    }
  
    .checkout-process-step-form {
      margin-bottom: 40% !important;
    }
  }
  
  @media (min-width: 321px) and (max-width: 485px) {
    .checkout-process-step-form .checkout-inner-steps .main-btn-div {
      text-align: center;
    }
  
    .checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
      margin-bottom: 11%;
    }
  
    .checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
      padding: 6px 34px;
      margin: 0px 1px;
    }
  
    .checkout-process-step-form .checkout-inner-steps .main-btn-div .save-btn {
      padding: 5px 15px;
      margin: 0px 2px;
    }
  
    .checkout-process-step-form .checkout-inner-steps {
      padding: 24px 10px;
      border-radius: 5px;
    }
  
   
    .checkout-process-step-form {
      margin-bottom: 25% !important;
  }
  }
  
  @media (min-width: 486px) and (max-width: 575px) {
    .checkout-process-step-form .checkout-inner-steps .main-btn-div {
      text-align: center;
    }
  
    .checkout-process-step-form .checkout-inner-steps {
      padding: 24px 10px;
      border-radius: 5px;
    }
  
    .checkout-process-step-form {
      margin-bottom: 30% !important;
    }
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    .checkout-process-step-form .checkout-inner-steps .main-btn-div {
      text-align: center;
    }
  
    .checkout-process-step-form {
      margin-bottom: 25% !important;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .checkout-process-step-form .checkout-inner-steps .main-btn-div {
      text-align: center;
    }
  
    .checkout-process-step-form {
      margin-bottom: 20% !important;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .checkout-process-step-form {
      margin-bottom: 15% !important; 
    }
  }