/* ProductSlider css started */

p.rating-txtttt {
  font-size: 13px;
  font-weight: 600;
}
.main-div-ratinggg {
  position: relative;
}

.main-div-ratinggg p.commmontext {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 1%;
}

.main-div-ratinggg .arrow-class {
  cursor: pointer;
}

.main-div-ratinggg .below-drop-down-div {
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0px 3px 6px #ccc;
  position: absolute;
  z-index: 1;
}

.main-div-ratinggg .below-drop-down-div .Content-Six-Tab .rating-progressbar {
  display: flex;
}

.main-div-ratinggg
  .below-drop-down-div
  .Content-Six-Tab
  .rating-progressbar
  .commmontext {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 14px;
}

.main-div-ratinggg .blk-border {
  border-bottom: 0.5px solid #ccc;
  margin-top: 15px;
}

.main-div-ratinggg .customer-reviews-txttt {
  font-size: 12px;
  color: orange;
  cursor: pointer;
}

.main-div-ratinggg .below-drop-down-div .Content-Six-Tab .progress {
  width: 150px;
  height: 15px;
  margin: 2px 7px;
  margin-top: 7px;
  border-radius: 100px;
  border: 1px solid #9a9a9a;
}

.main-div-ratinggg
  .below-drop-down-div
  .Content-Six-Tab
  .rating-progressbar
  .progress-bar.bg-secondary {
  background-color: #ffaa00 !important;
}

.ProductSlider .drop {
  display: flex;
  justify-content: end;
  align-items: baseline;
}

section.ProductSlider {
  margin-top: 1%;
}

.ProductSlider .slider-description .text-p {
  margin-bottom: 5px;
  font-size: 15px;
}

.ProductSlider .slider-description .text-p-coupon-not-apply {
  margin-bottom: 5px;
  font-size: 15px;
  color: #000;
  font-weight: 600;
}

.ProductSlider .slider-description .text-p-coupon-apply {
  margin-bottom: 5px;
  font-size: 15px;
  color: rgb(66, 224, 66);
  font-weight: 600;
}

.ProductSlider .Payment-Collection .cart button.cart {
  font-weight: 500;
  border: 1px solid #f2ed07;
  border-radius: 20px;
  width: 100%;
  padding: 6px 0px;
  font-size: 16px;
  background-color: #ffff00;
}

.ProductSlider svg.star-svg {
  width: 20px;
  height: 20px;
}

.ProductSlider .dropdown-toggle {
  border: none;
  background-color: #f5f5f5;
  font-size: 18px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  border-radius: 20px;
}

.ProductSlider .btn:hover {
  color: black;
  background-color: #f5f5f5;
  border: none;
}

.ProductSlider .dropdown-toggle {
  font-size: 18px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  width: 221px;
}

.ProductSlider .drop .text-p {
  /* margin: 12px 13px; */
  font-weight: 400;
}

.ProductSlider .main-div {
  border: 1px solid #000000;
  padding: 0px 2px;
  cursor: pointer;
}

.ProductSlider .below-div-class {
  height: 300px;
  overflow-y: scroll;
  width: 50px;
}

/* .ProductSlider .Policy-Main .col-form-label {
  letter-spacing: 0px;
  color: #2a4600;
  font-size: 16px;
  font-weight: 500;
} */
/* .ProductSlider label.check-text.form-label {
  width: fit-content;
  padding: 0px 0px;
  margin-bottom: 8px;
  font-size: 19px;
  font-weight: 600;
  color: #2a4600;
  border-bottom: 1.5px solid #2a4600;
  line-height: -9px;
} */
.ProductSlider .main {
  margin-top: 1%;
}

/* .Coupontxt {
  background-color: #ffaa00;
  padding: 3px;
  border-radius: 3px;
  width: fit-content;
} */

.Coupontxt {
  background-color: #ffaa00;
  padding: 3px;
  border-radius: 3px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
}

.ProductSlider .Policy-Main input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ProductSlider .Policy-Main .dot-holder {
  font-size: 12px;
}

.ProductSlider .Policy-Main .check-text {
  /* width: fit-content;
  padding: 0px 0px;
  margin-bottom: 8px;
  font-size: 19px;
  font-weight: 600;
  color: #2a4600;
  border-bottom: 1.5px solid #2a4600;
  line-height: -9px; */
}

.ProductSlider li.service-dot::before {
  background-color: #2a4600 !important;
}

.ProductSlider form.color-main {
  margin: 0px 7px;
}

.ProductSlider .dropdown-menu.show {
  text-align: center;
  padding: 3px 3px;
  width: 211px;
  transform: translate(4px, 46px) !important;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 19px;
  border: none;
}

.ProductSlider .dropdown-item:focus,
.ProductSlider .dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: transparent !important;
}

.ProductSlider .slider-description p.text-p.rate {
  font-weight: 500;
  margin-bottom: 5px;
}

/* desciption css started */

.ProductSlider h1.explore-btn {
  color: black;
  line-height: 29px;
}

.ProductSlider .border-main {
  border-bottom: 1px solid #636363;
  margin: 11px 0px;
}

.ProductSlider .price {
  display: flex;
  justify-content: left;
}

.ProductSlider .form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFAA00'/%3E%3C/svg%3E") !important;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.ProductSlider .form-check-input:checked {
  background-color: white;
  border-color: black;
}

.ProductSlider .form-check-input[type="radio"] {
  font-size: 20px;
}

.ProductSlider .radio-btn-input-holder {
  /* display: flex; */
  /* justify-content: space-between; */
}

.ProductSlider label.form-check-label {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  margin: 0px 0px;
}

.ProductSlider .check-text p.service.small-text {
  border-bottom: 0px;
  font-weight: 400;
  color: #2a4600;
  text-decoration: auto;
}

/* Payment-Collection  css started */

.ProductSlider .Payment-Collection {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  padding: 15px 12px;
}

.ProductSlider .Payment-Collection p.price1 {
  color: #ff0000;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-bottom: 0px;
}

.ProductSlider .Payment-Collection .sold {
  display: flex;
  justify-content: space-between;
}

.ProductSlider .Payment-Collection span.free {
  font-weight: 600;
}

.ProductSlider .Payment-Collection p.text-p.main {
  color: #600000;
}

/* .ProductSlider .Payment-Collection .cart button.cart {
    border: 1px solid #f2ed07;
    border-radius: 20px;
    width: 100%;
    padding: 6px 0px;
    background-color: #ffff00;
} */
.ProductSlider .Payment-Collection .cart {
  padding: 0px 11px;
}

.ProductSlider .Payment-Collection .heart-icon {
  margin: 0px 20px;
}

.ProductSlider .Payment-Collection .cart button.buy {
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #ffaa00;
  border-radius: 20px;
  width: 100%;
  padding: 6px 0px;
  background-color: #ffaa00;
}

.ProductSlider .Payment-Collection .lock {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.ProductSlider .Payment-Collection .lock p.text-p {
  color: #00608b;
  margin: 6px 11px;
}

.ProductSlider .Payment-Collection .lock .icon-lock {
  color: #00608b;
}

.ProductSlider .Payment-Collection button.wish {
  font-size: 16px;
  padding: 6px 0px;
  background-color: transparent;
  width: 100%;
  border: 1px solid #000000;
  border-radius: 19px;
  font-weight: 500;
}

.ProductSlider .Payment-Collection .wishlist {
  padding: 0px 11px;
}

.ProductSlider .slider-description {
  border-bottom: 2px solid #bbbbbb;
}

.ProductSlider .Policy-Main .form-control {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #707070 !important;
  border-radius: 3px !important;
}

.ProductSlider .Policy-Main .policy {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffaa00;
  border-radius: 5px;
}

/* Swiper css started */

.ProductSlider .camera-sec .camera-slider .mySwiper .Main-div .camera-imgs {
  min-width: 84px;
  min-height: 84px;
  background: #e6e6e6 0% 0% no-repeat padding-box;
}

.ProductSlider .camera-sec .camera-slider .mySwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductSlider
  .camera-sec
  .camera-slider
  .mySwiper
  .Main-div
  .camera-imgs
  img.inner-img {
  margin: 16px 0px;
  width: 52px;
  height: 52px;
  object-fit: contain;
}

.ProductSlider .camera-sec .card-sec .product-detail .specs {
  padding: 49px 0px;
}

.ProductSlider .card-sec {
  /* height: 100%; */
  background: #e6e6e6 0% 0% no-repeat padding-box;
  text-align: -webkit-center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductSlider .camera-sec .card-sec .product-detail {
  width: 100%;
  /* height: 290px; */
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.ProductSlider .camera-sec .card-sec .product-detail .zoomable__img {
  width: 100%;
  height: 100%;
}

.ProductSlider .camera-sec .card-sec .product-detail img.zoomable__img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.ProductSlider .camera-sec .camera-slider .silder-btn {
  position: relative;
}

.ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 429px;
  z-index: 99;
  left: 30px;
}

.ProductSlider .camera-sec .camera-slider .mySwiper .swiper-slide {
  height: 70px !important;
}

.ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  z-index: 99;
  right: 29px;
}

.ProductSlider .dropdown,
.ProductSlider .dropdown-center,
.ProductSlider .dropend,
.ProductSlider .dropstart,
.ProductSlider .dropup,
.ProductSlider .dropup-center {
  position: relative;
  margin: 0px 17px;
}

.ProductSlider .swiper-button-prev,
.ProductSlider .swiper-button-next {
  display: none;
}

.ProductSlider .camera-sec .camera-slider .mySwiper {
  height: 465px !important;
  padding: 50px 0px;
}

.ProductSlider .Desktop-View {
  display: block;
}

.sidebar-swiper-mobile-view {
  display: none;
}

.ProductSlider .Policy-Main .policy {
  padding: 12px 19px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffaa00;
  border-radius: 5px;
  justify-content: center;
  align-items: baseline;
  /* width: max-content; */
  margin-bottom: 3%;
}

.ProductSlider .Policy-Main .circle {
  /* color: white; */
  /* padding: 6px 10px; */
  border-radius: 100px;
  width: 36px;
  height: 36px;
  /* background: #ffaa00 0% 0% no-repeat padding-box; */
}

.ProductSlider .refund {
  margin: 0px 11px;
}

.ProductSlider .about {
  color: #000000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
}

.ProductSlider ul {
  padding-left: 0px !important;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    width: 0;
    /* border-left: 2px dashed rgb(165, 165, 165); */
    /* Changed to border-left with dashed style */
    position: absolute;
    left: 3px;
    top: 5px;
    height: calc(88% - 10px);
  }

  li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;

    &:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      background: black;
      position: absolute;
      left: 0;
      top: 5px;
      border-radius: 10px;
    }
  }
}

.ProductSlider li.sub-text {
  margin-bottom: 23px;
  color: black !important;
}

.ProductSlider span.main-price.price-sale {
  text-decoration: line-through;
}

.ProductSlider
  .main-price
  .radio-btn-input-holder
  .transparent
  .form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27%23C2B89D%27/%3E%3C/svg%3E") !important;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.ProductSlider
  .main-price
  .radio-btn-input-holder
  .brown
  .form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27%23B96900%27/%3E%3C/svg%3E") !important;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

label.check-text.commmontext.form-label {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2a4600;
  border-bottom: 1px solid green;
  width: fit-content;
  padding: 0px 0px;
  margin-bottom: 9px;
}
.mainpriseee {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 6px;
}
.ProductSlider .discountboxx {
  box-shadow: 0px 0px 10px 0px #00000029;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  margin-top: 15px;
}

.ProductSlider .discountboxx .logooo {
  width: 100px;
  margin: auto;
}

.ProductSlider .discountboxx .accntbtn {
  border-radius: 50px;
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  background-color: #fff;
}

.ProductSlider .buymoresliderdiv {
}

.ProductSlider .buymoresliderdiv .slideflexx {
  display: flex;
  width: 100%;
}

.ProductSlider .buymoresliderdiv .slideflexx .titledivvv {
  min-width: 100px;
}

.ProductSlider .buymoresliderdiv .slideflexx .titledivvv .tettt {
  font-size: 14px;
  margin-bottom: 5px;
}

.ProductSlider .buymoresliderdiv .slideflexx .sliderdivv {
  width: 77%;
}

.ProductSlider .buymoresliderdiv .slideflexx .sliderdivv .buymorbox {
  position: relative;
  border: 1px solid #ccc;
  padding: 5px;
}

.ProductSlider .buymoresliderdiv .slideflexx .sliderdivv .buymorbox .unitpp {
  font-size: 14px;
  margin-bottom: 0px;
}

.ProductSlider .buymoresliderdiv .slideflexx .sliderdivv .buymorbox .savemorep {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.ProductSlider .buymoresliderdiv .swiper-wrapper {
  padding-bottom: 40px;
}

.ProductSlider
  .buymoresliderdiv
  .slideflexx
  .sliderdivv
  .buymorbox
  .form-check {
  position: absolute;
  top: 0px;
  right: 0px;
}

.ProductSlider .buymoresliderdiv .slideflexx .swiper-pagination-bullet-active {
  background: #ffaa00 !important;
}

.prodselectvdo {
  min-width: 84px;
  height: 84px;
}

.ProductDescription img {
  width: 100%;
}
.aplus-module-wrapper {
  width: 100% !important;
}

/******************** Media Query started ********************/

@media (min-width: 768px) and (max-width: 1200px) {
  .ProductSlider .camera-sec .camera-slider .mySwiper .Main-div .camera-imgs {
    min-width: 84px;
    min-height: 84px;
    background: #e6e6e6 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .prodselectvdo {
    min-width: 69px;
    height: 45px;
  }
}

@media (min-width: 0px) and (max-width: 767.98px) {
  .prodselectvdo {
    min-width: 50px;
    height: 50px;
  }

  .ProductSlider .camera-sec .camera-slider .mySwiper .Main-div .camera-imgs {
    min-width: 84px;
    min-height: 84px;
    background: #e6e6e6 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 0px) and (max-width: 320px) {
  .Desktop-View {
    display: none !important;
  }

  .ProductSlider .Policy-Main .policy {
    justify-content: start;
  }

  .sidebar-swiper-mobile-view {
    display: block;
    padding: 13px;
  }

  .ProductSlider .camera-sec .camera-slider .mySwiper {
    height: 81px !important;
    padding: 0px 0px;
  }

  /* .ProductSlider .camera-sec .card-sec .product-detail {
        width: 176px;
        height: 274px;
        padding: 45px 0px;
    } */

  .ProductSlider .card-sec {
    padding: 0px;
  }

  .ProductSlider .camera-sec .card-sec .product-detail .specs {
    padding: 0px 0px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
    bottom: 26px;
    left: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
    bottom: 50%;
    bottom: 26px;
    right: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    font-size: 11px;
  }

  .ProductSlider .dropdown-toggle {
    font-size: 13px;
  }

  .ProductSlider .drop .dropdown-toggle {
    width: 155px;
    background-color: #ebebeb;
    border: none;
    border-radius: 100px;
  }

  .ProductSlider h1.explore-btn {
    line-height: 22px;
    font-size: 14px;
  }

  .ProductSlider label.form-check-label {
    font-size: 10px;
  }

  .ProductSlider .form-check-input[type="radio"] {
    font-size: 15px;
  }

  .ProductSlider .Payment-Collection p.price1 {
    font-size: 18px;
  }

  .ProductSlider .Payment-Collection {
    margin-top: 18px;
  }

  .ProductSlider .Payment-Collection .cart button.cart {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection .cart button.buy {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection button.wish {
    font-size: 14px;
  }

  .ProductSlider .dropdown-menu.show {
    width: 157px;
    transform: translate(4px, 37px) !important;
  }

  .ProductSlider li.sub-text {
    text-align: justify;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .transparent
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .brown
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider .Policy-Main .form-control {
    width: auto;
    text-align: unset;
  }

  .ProductSlider label.check-text.commmontext.form-label {
    margin-bottom: 9px;
    margin-left: 16px;
  }

  .Policy-Main {
    margin-top: 16px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Desktop-View {
    display: none !important;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .transparent
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .sidebar-swiper-mobile-view {
    display: block;
    padding: 13px;
  }

  .ProductSlider .camera-sec .camera-slider .mySwiper {
    height: 81px !important;
    padding: 0px 0px;
  }

  /* .ProductSlider .camera-sec .card-sec .product-detail {
        width: 176px;
        height: 274px;
        padding: 45px 0px;
    } */

  .ProductSlider .card-sec {
    padding: 0px;
  }

  .ProductSlider .camera-sec .card-sec .product-detail .specs {
    padding: 0px 0px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
    bottom: 26px;
    left: -24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
    bottom: 26px;
    right: -24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    font-size: 11px;
  }

  .ProductSlider .dropdown-toggle {
    font-size: 13px;
  }

  .ProductSlider .Policy-Main .policy {
    justify-content: start;
  }

  .ProductSlider .drop .dropdown-toggle {
    width: 155px;
    background-color: #ebebeb;
    border: none;
    border-radius: 100px;
  }

  .ProductSlider h1.explore-btn {
    line-height: 22px;
    font-size: 16px;
  }

  .ProductSlider label.form-check-label {
    font-size: 14px;
  }

  .ProductSlider .form-check-input[type="radio"] {
    font-size: 15px;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .brown
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider .Payment-Collection p.price1 {
    font-size: 18px;
  }

  .ProductSlider .Payment-Collection {
    margin-top: 18px;
  }

  .ProductSlider .Payment-Collection .cart button.cart {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection .cart button.buy {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection button.wish {
    font-size: 14px;
  }

  .ProductSlider .dropdown-menu.show {
    width: 157px;
    transform: translate(4px, 37px) !important;
  }

  .ProductSlider li.sub-text {
    text-align: justify;
  }

  .ProductSlider .Policy-Main .form-control {
    width: auto;
    text-align: unset;
  }

  .ProductSlider label.check-text.commmontext.form-label {
    margin-bottom: 9px;
    margin-left: 16px;
  }

  .Policy-Main {
    margin-top: 16px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Desktop-View {
    display: none !important;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .transparent
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .sidebar-swiper-mobile-view {
    display: block;
    padding: 13px;
  }

  .ProductSlider .camera-sec .camera-slider .mySwiper {
    height: 81px !important;
    padding: 0px 0px;
  }

  /* .ProductSlider .camera-sec .card-sec .product-detail {
        width: 176px;
        height: 274px;
        padding: 45px 0px;
    } */

  .ProductSlider .card-sec {
    padding: 0px;
  }

  .ProductSlider .camera-sec .card-sec .product-detail .specs {
    padding: 0px 0px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
    bottom: 26px;
    left: -24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
    bottom: 26px;
    right: -24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    font-size: 11px;
  }

  .ProductSlider .dropdown-toggle {
    font-size: 13px;
  }

  .ProductSlider .drop .dropdown-toggle {
    width: 155px;
    background-color: #ebebeb;
    border: none;
    border-radius: 100px;
  }

  .ProductSlider h1.explore-btn {
    line-height: 23px;
    font-size: 18px;
  }

  .ProductSlider label.form-check-label {
    font-size: 14px;
  }

  .ProductSlider .form-check-input[type="radio"] {
    font-size: 15px;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .brown
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider .Payment-Collection p.price1 {
    font-size: 18px;
  }

  .ProductSlider .Payment-Collection {
    margin-top: 18px;
  }

  .ProductSlider .Payment-Collection .cart button.cart {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection .cart button.buy {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection button.wish {
    font-size: 14px;
  }

  .ProductSlider .dropdown-menu.show {
    width: 157px;
    transform: translate(4px, 37px) !important;
  }

  .ProductSlider .Policy-Main .form-control {
    width: auto;
    text-align: unset;
  }

  .ProductSlider label.check-text.commmontext.form-label {
    margin-bottom: 9px;
    margin-left: 16px;
  }

  .Policy-Main {
    margin-top: 16px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Desktop-View {
    display: none !important;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .transparent
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .ProductSlider
    .main-price
    .radio-btn-input-holder
    .brown
    .form-check-input:checked[type="radio"] {
    height: 15px;
    width: 15px;
  }

  .sidebar-swiper-mobile-view {
    display: block;
    padding: 13px;
  }

  .ProductSlider .camera-sec .camera-slider .mySwiper {
    height: 81px !important;
    padding: 0px 0px;
  }

  /* .ProductSlider .camera-sec .card-sec .product-detail {
        width: 176px;
        height: 274px;
        padding: 45px 0px;
    } */
  .ProductSlider .card-sec {
    padding: 0px;
  }

  .ProductSlider .camera-sec .card-sec .product-detail .specs {
    padding: 0px 0px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
    bottom: 26px;
    left: -28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
    bottom: 26px;
    right: -28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    font-size: 11px;
  }

  .ProductSlider .dropdown-toggle {
    font-size: 13px;
  }

  .ProductSlider .drop .dropdown-toggle {
    width: 155px;
    background-color: #ebebeb;
    border: none;
    border-radius: 100px;
  }

  .ProductSlider h1.explore-btn {
    line-height: 24px;
    font-size: 19px;
  }

  .ProductSlider label.form-check-label {
    font-size: 14px;
  }

  .ProductSlider .form-check-input[type="radio"] {
    font-size: 15px;
  }

  .ProductSlider .Payment-Collection p.price1 {
    font-size: 18px;
  }

  .ProductSlider .Payment-Collection {
    margin-top: 18px;
  }

  .ProductSlider .Payment-Collection .cart button.cart {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection .cart button.buy {
    font-size: 14px;
  }

  .ProductSlider .Payment-Collection button.wish {
    font-size: 14px;
  }

  .ProductSlider .dropdown-menu.show {
    width: 157px;
    transform: translate(4px, 37px) !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
    bottom: 413px;
    left: 40px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
    top: -62px;
    right: 42px;
  }

  .ProductSlider .camera-sec .camera-slider .mySwiper {
    padding: 60px 0px;
  }

  .ProductSlider .Payment-Collection {
    margin-top: 3%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ProductSlider .camera-sec .camera-slider .mySwiper {
    padding: 45px 0px;
  }

  .Total {
    margin-top: 2%;
  }

  .Total {
    margin-top: 4%;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
    left: 61px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
    right: 61px;
    top: -47px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ProductSlider h1.explore-btn {
    font-size: 17px;
  }

  .ProductSlider .text-p {
    font-size: 16px;
  }

  .ProductSlider label.form-check-label {
    font-size: 14px;
  }

  .ProductSlider .form-check-input[type="radio"] {
    font-size: 17px;
  }

  .ProductSlider .camera-sec .camera-slider .mySwiper {
    height: 442px !important;
    padding: 32px 0px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .back-btn {
    bottom: 419px;
    left: 24px;
  }

  .ProductSlider .Payment-Collection p.price1 {
    font-size: 20px;
  }

  .ProductSlider .Policy-Main .policy {
    padding: 12px 6px;
  }

  .ProductSlider .camera-sec .camera-slider .silder-btn .next-btn {
    top: -39px;
    right: 24px;
  }
}

.thumbnail {
  width: 300px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.magnified {
  width: 300px;
  cursor: pointer;
  transform: scale(1.5);
  background: #fff;
  border: 1px solid;
  z-index: 9;
  position: relative;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  width: 600px;
  cursor: pointer;
}
