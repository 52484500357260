/* Header Css Started */

/* sub header css started */

.Header .sub-header {
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 16px #00000014;
}

.Header .sub-header .mail {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Header .sub-header .Main-sub-header .text {
  margin-bottom: 0px;
}

.Header .sub-header .Main-sub-header .icon {
  color: white;
  margin: 0px 9px;
  font-size: 16px;
}

.Header .helpline {
  display: flex;
  align-items: baseline;
}

.Header .Log-main .Login .text {
  font-weight: 300;
  margin: 2px 14px;
}

.Header .sub-header .Main-sub-header {
  display: flex;
  justify-content: stretch;
  padding: 11px 0px 0px 0px;
}

.Header .sub-header .Main-sub-header .border-bt {
  border-right: 1px solid white;
  margin: 3px 17px 1px 18px;
}

.Header .sub-header .text {
  font-size: 15px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.Header .sub-header .title {
  opacity: 0.5;
  margin-bottom: 0px;
  font-weight: 400;
}

.Header .sub-header .Log-main .Login {
  display: flex;
  align-items: center;
  padding: 9px 7px;
}

.Header .sub-header .Log-main {
  justify-content: space-evenly;
  display: flex;
}

.Header .sub-header .Log-main .Login .text {
  color: #ffaa00;
  font-weight: 500;
}

.Header .sub-header .Log-main .Login .icon {
  color: #ffaa00;
}

.Header .sub-header .Log-main .border-bt {
  border-right: 1px solid #ffaa00;
  margin: 9px 17px 8px 9px;
}

.Header .sub-header .Log-main .Login:hover {
  background-color: #ffaa00;
  cursor: pointer;
}

.Header .sub-header .Log-main .Login:hover .icon {
  color: #000000;
}

.Header .sub-header .Log-main .Login:hover .text {
  color: #000000;
}
.Header .sub-header .Log-main .Login .icon-user {
  font-size: 23px;
  color: white;
}
.Header .sub-header .Log-main .Login p.text.profile {
  color: white !important;
}
.Header .toggle {
  margin: 0px 22px;
}
/* App.css */

.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.357); /* Black with 50% opacity */
  z-index: 1;
}
.content-under-overlay {
  position: relative;
  z-index: 2;
}

/* sub header css End */

/* Search Header started */

.Header .Search-Header {
  border-bottom: 2px solid #c5c5c5;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 16px #00000014;
}

.Header .Search-Header .Logo-main img.logo {
  width: 100%;
  height: 100%;
}

.Header .Search-Header .Logo-main {
  width: 107px;
  height: 93px;
  padding: 5px;
}
.Header .Search-Header .search-input .form-control {
  font-weight: 500;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border-radius: 24px;
  height: 48px;
  color: #000000 !important;
  padding: 0px 22px;
}

.Header .Search-Header .search-input {
  position: relative;
  top: 23px;
}

.Header .Search-Header .search-input .list-group {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 99;
}

.Header .Search-Header .search-input .icon-search {
  right: 18px;
  top: 6px;
  position: absolute;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  background: #ffaa00 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 6px #00000029;
}

.Header .Search-Header .search-input .icon-search .icon {
  color: white;
  padding: 11px 10px;
}

.Header .Search-Header .main-search {
  margin: 0px -17px;
}

.Header .Search-Header .user-drop {
  display: flex;
  align-items: center;
}

.Header .Search-Header .user-drop .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  color: black;
  font-weight: 600;
}

.Header .Search-Header .user-drop .dropdown-menu.show {
  margin: 12px 0px;
  padding: 17px 0px;
  background: #000000 0% 0% no-repeat padding-box;
  color: white;
  /* height: 225px; */
  transform: translate(22px, 37px) !important;
}

.Header .Search-Header .user-drop .dropdown-item {
  width: auto;
  color: white;
  margin-bottom: 8px;
}

.Header .Search-Header .user-drop .dropdown-item:focus,
.Header .Search-Header .user-drop .dropdown-item:hover {
  color: #000000 !important;
  background-color: #fff !important;
  border-radius: 5px;
  margin: 0px 11px;
  margin-bottom: 8px;
  padding: 4px 5px;
}

.sub-text {
  color: white !important;
}

.Header .Search-Header .Cart-shopping .cart p.text {
  font-weight: 600;
  color: #000000;
  margin-bottom: 0px;
}

.Header .Search-Header .Cart-shopping .user-drop .text {
  font-weight: 600;
  color: #000000;
}

.Header .Search-Header .Cart-shopping .cart {
  display: flex;
  align-items: center;
}

.Header .Search-Header .Cart-shopping {
  display: flex;
  padding: 25px 0px;
  align-items: baseline;
  justify-content: space-evenly;
}

.Header .Search-Header .Cart-shopping .cart .icon {
  margin: 0px 9px;
  font-size: 20px;
}

.Header .Search-Header svg.svg-inline--fa.fa-user {
  font-size: 21px;
}

.Header .Search-Header .user-drop .dropdown-menu.show::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 17px solid #040404;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
}

section.Header {
  position: sticky;
  top: 0;
  z-index: 999;
}

/* Search Header End */

/* CMJ Main Header started*/

.Header .header-main-cmj .main-first {
  display: flex;
}

.Header .header-main-cmj .second-header {
  display: flex;
}

.Header .header-main-cmj .desktop-offcanvas.nav {
  justify-content: space-between;
}

.Header .header-main-cmj a.nav-link.active::after {
  content: "";
  border-bottom: 5px solid #24642e;
  position: absolute;
  border-radius: 15px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 35px;
}

.Header .header-main-cmj a.nav-link.active:after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #000000;
}

.Header a.nav-link {
  position: relative;
  color: #000000 !important;
  font-weight: 500;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.Header .header-main-cmj a.active.nav-link {
  color: #000000 !important;
  font-weight: 500;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.Header .header-main-cmj .drop-order {
  border-radius: 8px;
  padding: 12px 17px;
  background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
}

.Header .header-main-cmj .Main-Header {
  box-shadow: 0px 6px 16px #00000014;
}

.Header .Main-Header {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 16px #00000014;
}

/* CMJ Main Header End*/

/* Mobile View */

.justify-content-end.desktop-offcanvas.nav {
  display: block !important;
}

.Desktop-View {
  display: block;
}

.Mobile-View-Main {
  display: none;
}

.sub-header-mobile {
  background-color: black;
  color: white;
  padding: 0px 27px;
}

.Mobile-View-Main .mail {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

.Mobile-View-Main .Login {
  display: flex;
  align-items: center;
}

.Mobile-View-Main p.text {
  margin: 0px 10px;
  color: white;
}

.register {
  display: flex;
  justify-content: end;
}

.Mobile-View-Main {
  overflow: hidden;
  max-width: 100%;
}

.Mobile-View-Main .Log-main .Login .text {
  font-weight: 500;
  color: #ffaa00;
  margin: 2px 14px;
}

.Mobile-View-Main .Log-main .Login .icon {
  color: #ffaa00;
}

.Mobile-View-Main .mobile-logo img.logo {
  height: 119px;
  width: 125px;
  position: relative;
  z-index: 99;
  display: flex;
  margin: 0px auto;
}

.Mobile-View-Main .mobile-logo {
  justify-content: unset;
  position: absolute;
  top: 13px;
  right: 0;
  width: fit-content;
  left: 0;
  margin: 0px auto;
}

.Mobile-View-Main .cart-header .main {
  /* width: 100%; */
  overflow: hidden;
  padding: 4px 14px;
}

.Mobile-View-Main .cart-header .main .cart-main .Cart-shopping .user-drop {
  display: flex;
  justify-content: end;
}

.Mobile-View-Main
  .cart-header
  .main
  .cart-main
  .Cart-shopping
  .user-drop
  .btn-success {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  padding: 1px 5px;
}

.Mobile-View-Main
  .cart-header
  .main
  .cart-main
  .Cart-shopping
  .user-drop
  .search
  .icon {
  font-size: 13px;
  color: white;
  padding: 9px 9px;
}

.Mobile-View-Main
  .cart-header
  .main
  .cart-main
  .Cart-shopping
  .user-drop
  .cart {
  width: 23px;
  margin: 0px 17px;
  padding-top: 6px;
}

.Mobile-View-Main .cart-header {
  padding: 0px 29px;
  margin-top: 2%;
}
.Header .Main-Header.Desktop .dropdown-menu {
  height: 170px;
  overflow-y: scroll;
}
.Header .Main-Header.Desktop .dropdown-menu.show {
  width: auto;
  margin: 12px 0px;
  padding: 17px 8px;
  background: #000000 0% 0% no-repeat padding-box;
  color: white;
  transform: translate(0px, 37px) !important;
}

.Header .Main-Header.Desktop .desktop-offcanvas .nav .dropdown-toggle::after {
  display: none;
}

.Header .Main-Header.Desktop .dropdown-item:hover {
  color: #000000 !important;
  background-color: #fff !important;
  border-radius: 5px;
  /* margin-bottom: 8px; */
  width: -moz-fit-content;
}

.Header .Main-Header.Desktop .dropdown-menu.show::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 17px solid #040404;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
}

.Header .Main-Header.Desktop .dropdown-toggle {
  color: #000000 !important;
  font-weight: 500;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
}

.Header .Main-Header.Desktop .dropdown,
.Header .Main-Header.Desktop .dropdown-center,
.Header .Main-Header.Desktop .dropend,
.Header .Main-Header.Desktop .dropstart,
.Header .Main-Header.Desktop .dropup,
.Header .Main-Header.Desktop .dropup-center {
  padding: 8px 0px;
}

.Header .Mobile-Offcanvas.Mobile .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  color: black;
}

.Header .Mobile-Offcanvas.Mobile .dropdown-menu.show {
  margin: 12px 0px;
  padding: 17px 0px;
  background: #000000 0% 0% no-repeat padding-box;
  color: white;
  transform: translate(22px, 37px) !important;
}

.Header .Mobile-Offcanvas.Mobile .dropdown-item {
  width: auto;
  color: white;
  margin-bottom: 8px;
}

.Header .Mobile-Offcanvas.Mobile .dropdown-item:focus,
.Header .Mobile-Offcanvas.Mobile .dropdown-item:hover {
  color: #000000 !important;
  background-color: #fff !important;
  border-radius: 5px;
  margin: 0px 11px;
  margin-bottom: 8px;
  padding: 4px 5px;
}
.Header .Mobile-View-Main .dropdown-menu.show::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 17px solid #040404;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
}
.Header .Mobile-View-Main .dropdown-menu.show {
  margin: 12px 0px;
  padding: 17px 0px;
  background: #000000 0% 0% no-repeat padding-box;
  color: white;
  /* height: 225px; */
  transform: translate(22px, 37px) !important;
}
.Header .Mobile-View-Main a.small-text.dropdown-item:hover {
  color: #000000 !important;
  background-color: #fff !important;
  border-radius: 5px;
  margin: 0px 14px;
  margin-bottom: 8px;
  padding: 4px 6px;
  width: auto;
}
.Header .Mobile-View-Main a.small-text.dropdown-item {
  color: white !important;
}
/* .Header .Search-Header .user-drop .dropdown-item:focus, .Header .Search-Header .user-drop .dropdown-item:hover {
    color: #000000 !important;
    background-color: #fff !important;
    border-radius: 5px;
    margin: 0px 11px;
    margin-bottom: 8px;
    padding: 4px 5px;
} */
.Mobile-drop.accordion .accordion-item {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
}

.Mobile-drop.accordion .accordion-button:not(.collapsed) {
  color: black !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

li.main {
  color: black;
  margin-bottom: 1%;
}

.accordion-button {
  padding: 9px 17px !important;
}

.accordion-body {
  padding: 5px 16px;
}

/* .Bulk{
   
} */
/* ****************************Media Query started ************************************/

@media (min-width: 1024px) and (max-width: 1600px) {
  .Header .header-help-line-number {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .Header .Search-Header.Mobile-View .Cart-shopping {
    justify-content: end;
    padding: 32px 0px;
  }

  .Header .Search-Header.Mobile-View .icon-search {
    margin: 0px 31px;
  }

  .Desktop-View {
    display: none;
  }

  .Mobile-View-Main {
    display: block;
  }

  svg.svg-inline--fa.fa-cart-shopping.icon {
    font-size: 16px;
  }
}

@media (min-width: 0px) and (max-width: 485px) {
  .register {
    justify-content: start;
  }

  .sub-header-mobile {
    padding: 0px 6px;
  }
  .Header .toggle {
    margin: 0px 0px !important;
  }
  .Mobile-View-Main .mobile-logo {
    top: 11px;
    left: auto;
    right: 22px;
  }

  .Mobile-View-Main .mobile-logo img.logo {
    height: 74px;
    width: 88px;
  }

  .Mobile-View-Main .cart-header {
    padding: 0px 5px;
    margin-top: 0px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .Header .Mobile-View-Main .dropdown-menu.show {
    display: block;
    position: fixed !important;
    transform: translate3d(-32px, 139px, 0px) !important;
  }

  .toggle {
    padding: 8px 0px;
  }

  section.Search-Bar {
    width: 26px;
    height: 26px;
    padding: 1px 9px !important;
  }

  svg.svg-inline--fa.fa-magnifying-glass {
    font-size: 12px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Mobile-View-Main .mobile-logo {
    top: 13px;
    left: auto;
    right: 0;
    /* left: 0; */
    margin-left: auto;
  }
  .Header .toggle {
    margin: 0px 0px !important;
  }
  .Header .Mobile-View-Main .dropdown-menu.show {
    display: block;
    position: fixed !important;
    transform: translate3d(0px, 147px, 0px) !important;
  }

  .register {
    justify-content: start;
  }

  .Mobile-View-Main .mobile-logo img.logo {
    height: 80px;
    width: 93px;
  }

  .sub-header-mobile {
    padding: 0px 3px;
  }

  .Mobile-View-Main .cart-header {
    padding: 0px 5px;
    margin-top: 0px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .Header .Mobile-View-Main .dropdown-menu.show {
    display: block;
    position: fixed !important;
    transform: translate3d(-33px, 147px, 0px) !important;
  }

  section.Search-Bar {
    width: 26px;
    height: 26px;
    padding: 1px 9px !important;
  }

  svg.svg-inline--fa.fa-magnifying-glass {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .Header a.nav-link {
    font-size: 12px;
  }

  .Header .header-main-cmj a.active.nav-link {
    font-size: 12px;
  }

  .Header .sub-header .text {
    font-size: 12px;
  }

  .Header .sub-header .title {
    font-size: 20px;
    text-align: right;
  }

  .Header .sub-header .Log-main {
    justify-content: end;
  }

  .Mobile-View-Main .mobile-logo img.logo {
    height: 86px;
    width: 104px;
    margin: 0px auto;
    display: flex;
  }

  .Header .Mobile-View-Main .dropdown-menu.show {
    display: block;
    position: fixed !important;
    transform: translate3d(-21px, 91px, 0px) !important;
  }

  section.HomeBanner {
    margin-top: 10% !important;
  }

  /* .Header .dropdown-menu.show {
        display: block;
        position: fixed !important;
        transform: translate3d(395px, 107px, 0px) !important;
    } */
  .Mobile-View-Main .cart-header {
    padding: 0px 5px;
    margin-top: 0px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  section.Search-Bar {
    width: 26px;
    height: 26px;
    padding: 1px 9px !important;
  }

  svg.svg-inline--fa.fa-magnifying-glass {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Desktop-View {
    display: none;
  }

  .Header .Mobile-View-Main .dropdown-menu.show {
    display: block;
    position: fixed !important;
    transform: translate3d(-21px, 91px, 0px) !important;
  }

  .Mobile-View-Main .mobile-logo img.logo {
    height: 100px;
    width: 120px;
  }

  .Mobile-View-Main {
    display: block;
  }

  .Header a.nav-link {
    font-size: 12px;
    padding: 10px 7px;
  }

  .Header .Search-Header .search-input .icon-search .icon {
    padding: 0px 0px;
    font-size: 14px;
  }

  .Header .Search-Header .header-logo-holder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header .Search-Header .Logo-main {
    width: 90px;
    height: 80px;
  }

  .Header .Search-Header .user-drop {
    font-size: 13px;
  }

  .Header .Search-Header .Cart-shopping .cart .icon {
    font-size: 13px;
    margin: 0px 6px;
  }

  .Header .Search-Header .Cart-shopping .cart p.text {
    font-size: 13px;
  }

  .Header .Search-Header .Cart-shopping .user-drop .text {
    font-size: 13px;
  }

  .Header .Search-Header .search-input .icon-search {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header .Search-Header .search-input .form-control {
    height: 35px;
  }

  .Header .sub-header .header-cmj-name-holder {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .Header .sub-header .header-help-line-number {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .Header .sub-header .Main-sub-header .icon {
    font-size: 13px;
  }

  .Header .header-main-cmj a.active.nav-link {
    font-size: 12px;
  }

  .Header .sub-header .text {
    font-size: 11px;
  }

  .Header .sub-header .title {
    font-size: 15px;
    text-align: right;
  }

  .Mobile-View-Main .mobile-logo {
    justify-content: unset;
    position: absolute;
    top: 13px;
    /* left: 0%; */
    /* right: 38%; */
    /* text-align: center; */
  }

  .Mobile-View-Main .cart-header {
    padding: 0px 5px;
    margin-top: 0px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  section.Search-Bar {
    width: 26px;
    height: 26px;
    padding: 1px 9px !important;
  }

  svg.svg-inline--fa.fa-magnifying-glass {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .Header a.nav-link {
    font-size: 12px;
  }

  .Header .header-main-cmj a.active.nav-link {
    font-size: 12px;
  }

  .Header .sub-header .text {
    font-size: 13px;
  }

  .Header .sub-header .title {
    font-size: 27px;
  }

  .Header .Main-Header.Desktop .dropdown-toggle {
    font-size: 12px;
  }

  .Header .Main-Header.Desktop .dropdown,
  .Header .Main-Header.Desktop .dropdown-center,
  .Header .Main-Header.Desktop .dropend,
  .Header .Main-Header.Desktop .dropstart,
  .Header .Main-Header.Desktop .dropup,
  .Header .Main-Header.Desktop .dropup-center {
    padding: 3px 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .Header a.nav-link {
    font-size: 15px;
  }

  .Header .header-main-cmj a.active.nav-link {
    font-size: 15px;
  }

  .Header .sub-header .text {
    font-size: 14px;
  }

  .Header .sub-header .title {
    font-size: 27px;
  }

  .Header .Main-Header.Desktop .dropdown-toggle {
    font-size: 15px;
  }

  .Header .Main-Header.Desktop .dropdown,
  .Header .Main-Header.Desktop .dropdown-center,
  .Header .Main-Header.Desktop .dropend,
  .Header .Main-Header.Desktop .dropstart,
  .Header .Main-Header.Desktop .dropup,
  .Header .Main-Header.Desktop .dropup-center {
    padding: 5px 0px;
  }
}
