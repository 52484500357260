.CmjBanner .Cmj-Main-banner img.cmjbanner {
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out;
    border-radius: 10px;
}
.CmjBanner .Cmj-Main-banner .cmjbanner:hover {
    transform: scale(1.1);
    border-radius: 10px;
}
.CmjBanner .Cmj-Main-banner {
    overflow: hidden;
    border-radius: 10px;
}
.CmjBanner .background {
    padding: 42px 57px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
}
.CmjBanner .content {
    margin-top: -3%;
    position: relative;
}



/*********************** Media Query Started *****************************/

@media (min-width:0px) and (max-width:320px) {
    .CmjBanner .content {
        margin-top: 2%;
    }
    .CmjBanner .background {
        padding: 24px 16px;
    }
    .CmjBanner p.sub-title{
        text-align: justify;
    }
}
@media (min-width:321px) and (max-width:485px) {
    .CmjBanner .content {
        margin-top: 2%;
    }
    .CmjBanner .background {
        padding: 24px 18px;
    }
    .CmjBanner p.sub-title{
        text-align: justify;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .CmjBanner .content {
        margin-top: 2%;
    }
    .CmjBanner .background {
        padding: 24px 20px;
    }
    .CmjBanner p.sub-title{
        text-align: justify;
    }
    
}
@media (min-width:576px) and (max-width:767px) {
    .CmjBanner .content {
        margin-top: 2%;
    }
    .CmjBanner .background {
        padding: 24px 20px;
    }
    .CmjBanner p.sub-title{
        text-align: justify;
    }
}