/* ProductCategory css Started */

.ProductCategory h1.title-main {
  text-align: center;
  font-weight: 800;
}
.ProductCategory {
  margin-top: 4%;
  margin-bottom: 3%;
}
.ProductCategory span.main-title-border {
  border-bottom: 4px solid #ffaa00;
}
.ProductCategory .Product-Main .nav-link.active {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 29px;
  text-align: center;
  font-weight: 600;
  color: white !important;
}
.ProductCategory .Product-Main .nav-link {
  color: black;
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: 1px solid #000000;
  text-align: center;
  font-weight: 600;
  width: 177px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  display: -webkit-box;

  overflow: hidden;
}
.ProductCategory .Product-Main .nav-item {
  margin: 0px 14px;
}
.Product-Main {
  margin-top: 5%;
}
.ProductCategory .Product-Main .silder-btn {
  position: relative;
}
.ProductCategory .Product-Main .silder-btn .next-btn {
  padding: 1px 8px;

  left: 50%;
  bottom: -44px;
  position: absolute;
  background-color: #ffaa00;
  height: 27px;
  width: 27px;
  border-radius: 100px;
  z-index: 1;
  color: white;
}
.ProductCategory .Product-Main .silder-btn .back-btn {
  color: white;
  padding: 1px 8px;
  bottom: -44px;
  position: absolute;
  background-color: #ffaa00;
  height: 27px;
  width: 27px;
  right: 51%;
  z-index: 1;
  border-radius: 100px;
}
.ProductCategory
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  padding: 4% 0%;
}
/* ProductCategory css End */

/******************* Media query started *******************/

@media (min-width: 0px) and (max-width: 320px) {
  .ProductCategory .nav.nav-pills {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 3rem;
    scroll-padding-left: 1rem;
    scrollbar-width: thin;
  }

  .ProductCategory .nav.nav-pills::-webkit-scrollbar {
    height: 6px;
  }

  .ProductCategory .nav.nav-pills::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  .ProductCategory .Product-Main .nav-link.active {
    margin-right: 5px !important;
    padding: 6px 5px !important;
  }
  .ProductCategory .Product-Main .nav-link {
    font-size: 12px;
    padding: 5px 20px;
    width: 140px;
  }
  .ProductCategory .Product-Main .nav-link.active {
    font-size: 10px;
  }
  .ProductCategory .Product-Main .nav-link {
    font-size: 10px;
  }
  .ProductCategory .nav.nav-pills .nav-item {
    margin-right: 0.5rem;
  }

  .ProductCard .card-body .Cart-Btn button.cart {
    padding: 6px 6px;
  }
  .ProductCategory {
    margin-top: 8%;
  }
  .Product-Main {
    margin-top: 10%;
  }
  /* .ProductCard .card-body .Cart-Btn {
        display: block !important;
    }
    .ProductCard .cart {
        width: 100%;
    } */
  /* .ProductCard .buy {
   
        margin-top: 2%;
    } */

  .ProductCategory .Product-Main .nav-link {
    padding: 5px 3px;
  }

  .ProductCategory .Product-Main .silder-btn .back-btn {
    left: 52% !important;
    padding: 0px 7px;
    height: 23px;
    width: 23px;
  }
  .ProductCategory .Product-Main .silder-btn .next-btn {
    padding: 0px 7px;
    height: 23px;
    width: 23px;
  }
}
@media (min-width: 321px) and (max-width: 485px) {
  .ProductCategory .Product-Main .silder-btn .back-btn {
    padding: 1px 8px;

    height: 24px !important;
    width: 24px !important;
  }
  .ProductCategory .nav.nav-pills {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 3rem;
    scroll-padding-left: 1rem;
    scrollbar-width: thin;
  }

  .ProductCategory .nav.nav-pills::-webkit-scrollbar {
    height: 6px;
  }

  .ProductCategory .nav.nav-pills::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .ProductCategory .Product-Main .nav-link.active {
    padding: 5px 12px;
    font-size: 12px !important;
  }

  .ProductCategory .Product-Main .nav-link {
    padding: 5px 12px;
    font-size: 12px !important;
    width: 143px;
  }

  .ProductCategory .nav.nav-pills .nav-item {
    margin-right: 0.5rem;
  }
  .ProductCategory .Product-Main .silder-btn .back-btn {
    right: 52% !important;
  }

  .ProductCard .card-body .Cart-Btn button.cart {
    padding: 6px 6px;
  }
  .ProductCategory {
    margin-top: 8%;
  }
  .Product-Main {
    margin-top: 10%;
  }
  .ProductCategory .Product-Main .silder-btn .next-btn {
    height: 24px !important;
    width: 24px !important;
  }
}

