.EditAddress .modal-content {
    /* background-color: transparent !important; */
 }
 .EditAddress .log_in_form {
     background: #ffffff 0% 0% no-repeat padding-box;
     box-shadow: 0px 3px 6px #00000029;
     position: relative;
     padding: 26px 28px;
 }
 .EditAddress   .Button-Main {
     text-align: center;
     display: flex;
     justify-content: space-around;
 }
 .EditAddress  .Button-Main button.cancel {
     padding: 6px 34px;
     background-color: transparent;
     border: 1px solid #707070;
     border-radius: 5px;
 }
 .EditAddress  .Button-Main button.submit.orange {
     padding: 3px 28px;
     border: none;
     border-radius: 5px;
 }

 .EditAddress .login-box-button {
    padding: 8px 0px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    width: 60%;
    margin: 0px auto;
    text-align: center;
    border-radius: 25px;
    position: absolute;
    top: -28px;
    left: 0px;
    right: 0px;
    outline: 10px solid #b2b2b2;
    outline-offset: 0px;
}