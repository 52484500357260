.Deatils-address .OrderDetails .content {
  padding: 2px 14px;
}
.Deatils-address .OrderDetails .content p.commmontext {
  margin-bottom: 10px;
}
.Deatils-address .OrderDetails .content p.price.commmontext {
  font-weight: 500;
  color: #ff0000;
}
.Deatils-address .OrderDetails .content p.small-text {
  margin-bottom: 16px;
}
.Address .Deatils-address {
  display: flex;
  align-items: baseline;
}
.Deatils-address .OrderDetails {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  padding: 30px 31px;
  width: 100%;
  margin-left: 15px;
  margin-bottom: 1%;
}
.Deatils-address .OrderDetails .Details .mini-text {
  display: flex;
}
.Deatils-address .OrderDetails .Details .delete-main {
  padding: 2px 10px;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 100px;
}
.Deatils-address .OrderDetails .Details .delete-main .icon {
  color: #878181;
  font-size: 12px;
}
.Deatils-address .OrderDetails .Details .Delete-order {
  text-align: -webkit-right;
}
.Deatils-address .OrderDetails .Details .delete-main {
  padding: 2px 10px;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 100px;
}
.Deatils-address .OrderDetails .Details .delete-main .icon {
  color: #878181;
  font-size: 12px;
}
.Deatils-address
  .OrderDetails
  .Details
  .content
  .Quantity
  .quantity-no.sub-title {
  padding: 0px 21px;
  margin: 0px 31px;
  width: 51px;
  height: 27px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.Deatils-address .OrderDetails .Details .content .Quantity p.small-text.add {
  margin-left: 39px;
}
.Deatils-address .OrderDetails .Details .content .Quantity {
  display: flex;
  align-items: baseline;
}
.Deatils-address .OrderDetails .Details .main-cart {
  /* padding: 23px 0px; */
  width: 100%;
  height: 100%;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Deatils-address
  .OrderDetails
  .Details
  .main-cart
  .image-product
  .payment-logo {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23000000%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"] {
  border: 1px solid black;
  font-size: 20px;
  border-radius: 4px;
}
.Deatils-address {
  display: flex;
  align-items: baseline;
}

.mrptextt {
  color: #000 !important;
  margin-right: 10px;
  margin-bottom: 0px;
}
.mrptextt span {
  text-decoration: line-through;
}

/* Media Query Started */

@media (min-width: 0px) and (max-width: 320px) {
  .Deatils-address {
    display: block;
  }
  .Deatils-address .OrderDetails {
    margin-bottom: 4%;
    margin-top: 5%;
    margin-left: 0px;
  }
}
@media (min-width: 321px) and (max-width: 485px) {
  .Deatils-address {
    display: block;
    margin-top: 5%;
  }
  .Deatils-address .OrderDetails {
    margin-bottom: 4%;
    margin-top: 5%;
    margin-left: 0px;
  }
}
@media (min-width: 486px) and (max-width: 575px) {
  .Deatils-address {
    display: block;
    margin-top: 5%;
  }
  .Deatils-address .OrderDetails {
    margin-bottom: 4%;
    margin-top: 5%;
    margin-left: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .Deatils-address .OrderDetails {
    margin-bottom: 4%;
    margin-top: 5%;
    margin-left: 0px;
  }
  .Deatils-address {
    display: block;
    margin-top: 5%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
}
