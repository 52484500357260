/* Help Css started */

.Help .help-banner img.help {
  width: 100%;
}

.Help .image-overlay {
  background: transparent linear-gradient(90deg, #ffffff 0%, #ffffff00 36%) 0%
    0% no-repeat padding-box;
  position: absolute;
  top: 0px;
  width: -webkit-fill-available;
  height: 100%;
}
.Help .help-banner .content {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translate(14%, -50%);
}
section.Help {
  position: relative;
}
.Help .help-banner {
  margin-top: 3%;
  position: relative;
  height: 100%;
  margin-bottom: 2%;
}
/* Help content css */

.Help .Content-Help {
  padding-bottom: 1%;
  width: 100%;
  background: #f5f5f5 0% 0% no-repeat padding-box;
}
.Help .Content-Help .nav-pills .nav-link.active {
  background-color: #f5f5f5;
  border-radius: 0px;
}

.Help .Content-Help .nav-pills .nav-link {
  padding: 11px 40px 10px 9px;
}

.Help .Content-Help .nav-link.active div {
  background: #ffaa00 0% 0% no-repeat padding-box;
  border: 1px solid #f0d996;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.Help .Content-Help .nav-link div {
  background: white;
  border: 1px solid #bcbcbc;
  border-radius: 5px;

  font-weight: 500;

  padding: 7px 20px;
}
.Help .Content-Help nav.nav-pills {
  margin: 20px 0px;
}
.Help .Content-Help .nav-item {
  margin: 0px 0px;
  border-radius: 0px;
}
.Help .Content-Help .tab-content {
  margin-top: 3%;
}
.Help .help-main.accordion .accordion-button:not(.collapsed) {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0d996;
  border-radius: 4px;
  color: #000000 !important;
  font-weight: 600;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}
.Help .help-main.accordion button.accordion-button.collapsed {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0d996;
  border-radius: 4px;
  color: #000000 !important;
  font-weight: 600;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}
.Help .help-main.accordion .accordion-button:focus {
  z-index: 3;
  border-color: #f0d996 !important;
  outline: 0;
  box-shadow: none !important;
}
.Help .help-main.accordion .accordion-item {
  border: none !important;
  margin-bottom: 15px;
}
.Help .help-main.accordion .accordion-body {
  color: #000000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5 !important;
}
.Help .help-main.accordion .accordion-button:not(.collapsed)::after {
  background-image: url("../../../public/assests/Policybanners/minus.png") !important;
  background-color: #f5f5f5;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 12px 1px;
}
.Help .help-main.accordion .accordion-button::after {
  background-image: url("../../../public/assests/Policybanners/plus.png") !important;
  background-color: #f5f5f5;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
}
.Help .Content-Help .tabs-main {
  margin: 20px 0px;
  /* padding: 10px; */
  background-color: #fff;
}
.Help .help-main.accordion {
  padding-right: 21px;
}

/*********************** Media Query started ******************************/

@media (min-width: 0px) and (max-width: 320px) {
  .Help .help-main.accordion .accordion-button:not(.collapsed) {
    font-size: 13px;
    padding: 11px 10px;
  }
  .Help .help-main.accordion button.accordion-button.collapsed {
    font-size: 13px;
    padding: 11px 10px;
  }
  .Help .help-main.accordion .accordion-body {
    text-align: justify;
    font-size: 13px;
  }
  .Help .Content-Help .nav-link.active {
    font-size: 14px;
  }
  .Help .Content-Help .nav-link {
    font-size: 14px;
  }
  .Help .help-main.accordion {
    padding: 0px 14px;
  }
  section.Help {
    margin-bottom: 28%;
  }
}
@media (min-width: 321px) and (max-width: 485px) {
  .Help .help-main.accordion .accordion-button:not(.collapsed) {
    font-size: 14px;
    padding: 15px 6px;
  }
  .Help .help-main.accordion button.accordion-button.collapsed {
    font-size: 14px;
    padding: 15px 6px;
  }
  .Help .help-main.accordion .accordion-body {
    text-align: justify;
    font-size: 14px;
  }
  .Help .help-main.accordion {
    padding: 0px 14px;
  }
  .Help .Content-Help .nav-link.active {
    font-size: 15px;
  }
  .Help .Content-Help .nav-link {
    font-size: 15px;
  }
  section.Help {
    margin-bottom: 23%;
  }
}
@media (min-width: 486px) and (max-width: 767px) {
  .Help .help-main.accordion .accordion-button:not(.collapsed) {
    font-size: 16px;
    padding: 15px 10px;
  }
  .Help .help-main.accordion button.accordion-button.collapsed {
    font-size: 16px;
    padding: 15px 10px;
  }
  .Help .help-main.accordion .accordion-body {
    text-align: justify;
    font-size: 16px;
  }
  .Help .help-main.accordion {
    padding: 0px 14px;
  }
  .Help .Content-Help .nav-link.active {
    font-size: 16px;
  }
  .Help .Content-Help .nav-link {
    font-size: 16px;
  }
  section.Help {
    margin-bottom: 22%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .Help .help-main.accordion .accordion-button:not(.collapsed) {
    font-size: 16px;
    padding: 15px 13px;
  }
  .Help .help-main.accordion button.accordion-button.collapsed {
    font-size: 16px;
    padding: 15px 13px;
  }
  .Help .help-main.accordion .accordion-body {
    text-align: justify;
    font-size: 17px;
  }
  .Help .Content-Help .nav-link.active {
    font-size: 17px;
  }
  .Help .Content-Help .nav-link {
    font-size: 17px;
  }
  .Help .help-main.accordion {
    padding: 0px 14px;
  }
  section.Help {
    margin-bottom: 17%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .Help .Content-Help .tab-content {
    margin-top: 5%;
  }
  .Help .help-main.accordion .accordion-button:not(.collapsed) {
    font-size: 17px;
    padding: 15px 13px;
  }
  .Help .help-main.accordion button.accordion-button.collapsed {
    font-size: 17px;
    padding: 15px 13px;
  }
  .Help .help-main.accordion .accordion-body {
    text-align: justify;
    font-size: 17px;
  }
  .Help .Content-Help .nav-link.active {
    font-size: 17px;
  }
  .Help .Content-Help .nav-link {
    font-size: 17px;
  }
  .Help .help-main.accordion {
    padding: 0px 14px;
  }
  section.Help {
    margin-bottom: 17%;
  }
}
