.Bottom_Successfull_Modal .modal-footer {
    background: #202020;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: fit-content;
    border-top: 0px !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    padding: 13px 30px;
    width: 100%;
    border-radius: 5px;
}
.Bottom_Successfull_Modal .close-btn{
    background-color: transparent !important;
    border: none !important;
    
}
.Bottom_Successfull_Modal .modal-content {
    border: none;
    background: none;
    width: auto;
}

.Bottom_Successfull_Modal .modal-dialog {
    transform: none;
    display: flex;
    justify-content: center;
    align-items: end;
}
.Bottom_Successfull_Modal  .modal-backdrop {
 
    --bs-backdrop-bg: #00000045 !important;
   
}
