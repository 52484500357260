.verticalsliderr .slider-container .imgg {
  width: 100%;
}

/* .verticalsliderr .slider-container .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
} */

.verticalsliderr .slider-container {
  touch-action: pan-y;
}

.verticalsliderr .slick-track {
  transform: rotate(90%) I !important;
}
