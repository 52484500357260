.Customer_Support_Details .Customer_Support_Details_holder {
  background: #ffc1073d;
  border-radius: 5px;
  width: 89%;
  margin: 0px auto;
  position: relative;
}

.Customer_Support_Details
  .Customer_Support_Details_holder
  .image-holder
  .terms-img {
  width: 100%;
  height: 100%;
  /* padding: 50px 52px 0px 0px; */
}

.Customer_Support_Details .Customer_Support_Details_holder .image-holder {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 300px;
  right: 40px;
}

.Customer_Support_Details
  .Customer_Support_Details_holder
  .details-holder
  .privcytext {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.Customer_Support_Details
  .Customer_Support_Details_holder
  .details-holder
  .privecy_subtitle {
  font-size: 17px;
  font-weight: 600;
  color: #000000;
}

.Customer_Support_Details .Customer_Support_Details_holder .details-holder {
  padding: 25px 0px 0px 50px;
}

.Customer_Support_Details
  .Customer_Support_Details_holder
  .details-holder
  .address-info-section {
  padding-left: 29px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.Customer_Support_Details
  .Customer_Support_Details_holder
  .details-holder
  .address-info-section
  .info-text {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.Customer_Support_Details
  .Customer_Support_Details_holder
  .details-holder
  .address-info-section
  .info-text
  .icon-font {
  margin-right: 10px;
}

@media (min-width: 0px) and (max-width: 321px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section {
    padding-left: 0px;
  }
  .Customer_Support_Details .Customer_Support_Details_holder .image-holder {
    position: unset;
    width: -moz-fit-content;
    width: fit-content;
    margin: 20px auto;
    padding: 30px 0px;
  }

  .Customer_Support_Details .Customer_Support_Details_holder .details-holder {
    padding: 25px 25px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section {
    padding-left: 0px;
  }

  .Customer_Support_Details .Customer_Support_Details_holder .image-holder {
    position: unset;
    width: -moz-fit-content;
    width: fit-content;
    margin: 20px auto;
    padding: 25px 0px;
}

  .Customer_Support_Details .Customer_Support_Details_holder .details-holder {
    padding: 25px 25px;
  }
}

@media (min-width: 486px) and (max-width: 567px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section {
    padding-left: 0px;
  }
}

@media (min-width: 578px) and (max-width: 677px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section {
    padding-left: 0px;
  }
}

@media (min-width: 678px) and (max-width: 767px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section {
    padding-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section {
    padding-left: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section
    .info-text {
    font-size: 11px;
  }

  .Customer_Support_Details .Customer_Support_Details_holder .image-holder {
    height: 250px;
  }

  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .privcytext {
    font-size: 13px;
  }

  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .privecy_subtitle {
    font-size: 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .address-info-section
    .info-text {
    font-size: 11px;
  }

  .Customer_Support_Details .Customer_Support_Details_holder .image-holder {
    height: 250px;
  }

  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .privcytext {
    font-size: 13px;
  }

  .Customer_Support_Details
    .Customer_Support_Details_holder
    .details-holder
    .privecy_subtitle {
    font-size: 15px;
  }
}

@media (min-width: 485px) and (max-width: 992px) {
  .Customer_Support_Details .Customer_Support_Details_holder .image-holder {
    position: unset;
    width: -moz-fit-content;
    width: fit-content;
    margin: 20px auto;
  }

  .Customer_Support_Details .Customer_Support_Details_holder .details-holder {
    padding: 25px 25px;
  }
}
