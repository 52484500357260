/* Profile css started */

.Profile .Profile-Mail {
  background: #f5f5f5 0% 0% no-repeat padding-box;

  padding-bottom: 8%;
}
.Profile .Profile-Mail .form-control {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 0px !important;
  border: none !important;
  padding: 13px 33px;
  color: #5a5757;
}
section.Profile {
  margin-top: 2%;
}
.Profile .Profile-Mail .Details {
  margin-top: 36px;
}
.Profile .Profile-Mail .Profile-Form {
  padding: 0% 8%;
}
.Profile .Profile-Mail .icon {
  padding: 10px 27px;
  /* text-align: end; */
  color: #ffaa00;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.Profile .Save button.save.orange {
  padding: 6px 55px;
  border: none;
  border-radius: 5px;
}
.Profile .Save {
  margin-top: 3%;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.Profile .no-arrows-filed input::-webkit-outer-spin-button,
.Profile .no-arrows-filed input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.Profile .no-arrows-filed input[type="number"] {
  -moz-appearance: textfield;
}

/* Media query Started */

@media (min-width: 0px) and (max-width: 320px) {
  section.Profile {
    margin-bottom: 30%;
  }
}
@media (min-width: 321px) and (max-width: 485px) {
  section.Profile {
    margin-bottom: 30%;
  }
}
@media (min-width: 486px) and (max-width: 575px) {
  section.Profile {
    margin-bottom: 30%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  section.Profile {
    margin-bottom: 30%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  section.Profile {
    margin-bottom: 25%;
  }
}
