/* OurVission css started */

.OurVission h1.title-mission {
    color: #000000;
    opacity: 0.04;
    font-size: 63px;
    font-weight: bold;
    font-family: merriweather;
    text-align: center;
    margin-bottom: 4%;
}
.OurVission .Content-Mission {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 4% 8%;
}
.OurVission .Content-Mission .yellow-background {
    margin-top: 10%;
    width: 233px;
    height: 214px;
    background: #ffaa00 0% 0% no-repeat padding-box;
    border-radius: 4px;
    position: relative;
}
.OurVission .Content-Mission .image-main {
    top: 31px;
    right: -79px;
    position: absolute;
    height: 159px;
    width: 159px;
}
.OurVission .Content-Mission img.vission-img {
    width: 100%;
    height: 100%;
}
.OurVission .Content-Mission .image-main-two {
    top: 31px;
    left: -79px;
    position: absolute;
    height: 159px;
    width: 159px;
}
.OurVission .Content-Mission .image-main img.vission-img {
    height: 100%;
    width: 100%;
}
.OurVission .Content-Mission .border-choose {
    margin: 39px 0px;
    border-bottom: 1px solid black;
}
.OurVission ul {
    list-style-type: none;
}
.OurVission .Content-Mission .mission span.sub-title.main-mission {
    font-weight: 500;
}
.OurVission .Content-Mission .mission li.sub-title {
    margin-bottom: 4%;
}
.OurVission .Content-Mission .mission {
    margin-top: 10%;
}
.OurVission .second-main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    top: 50%;
    bottom: 0px;
}
section.OurVission {
    margin-top: 6%;
    margin-bottom: 14%;
}
/* OurVission css End */

/*********************** Media Query Started *****************************/

@media (min-width: 0px) and (max-width: 320px) {
    .OurVission h1.title-mission {
        font-size: 30px;
    }
    .OurVission .Content-Mission .yellow-background {
        width: 139px;
        height: 130px;
    }
    .OurVission .Content-Mission .image-main {
        right: -40px;
        height: 83px;
        width: 83px;
    }
    .OurVission p.sub-title {
        text-align: justify;
    }
    .OurVission ul {
        padding: 0px;
    }
    .OurVission .Content-Mission .mission li.sub-title {
        text-align: justify;
    }
    .OurVission .second-main {
        top: 0px;
    }
    .OurVission .Content-Mission .image-main-two {
        top: 24px;
        left: -47px;
        height: 90px;
        width: 90px;
    }
    .OurVission  .Content-Mission .title-main {
      margin-top: 6%;
    }
}
@media (min-width: 321px) and (max-width: 485px) {
    .OurVission h1.title-mission {
        font-size: 34px;
    }
    .OurVission .Content-Mission .yellow-background {
        width: 139px;
        height: 130px;
    }
    .OurVission .Content-Mission .image-main {
        right: -40px;
        height: 83px;
        width: 83px;
    }
    .OurVission p.sub-title {
        text-align: justify;
    }
    .OurVission ul {
        padding: 0px;
    }
    .OurVission .Content-Mission .mission li.sub-title {
        text-align: justify;
    }
    .OurVission .second-main {
        top: 0px;
    }
    .OurVission .Content-Mission .image-main-two {
        top: 24px;
        left: -47px;
        height: 90px;
        width: 90px;
    }
    section.OurVission {
       margin-bottom: 25%;
    }
    .OurVission  .Content-Mission .title-main {
        margin-top: 6%;
      }
}
@media (min-width: 486px) and (max-width: 575px) {
    .OurVission h1.title-mission {
        font-size: 36px;
    }
    .OurVission .Content-Mission .yellow-background {
        width: 185px;
        height: 185px;
    }
    .OurVission .Content-Mission .image-main {
        top: 37px;
        right: -56px;
        height: 112px;
        width: 109px;
    }
    .OurVission .second-main {
        top: 0px;
    }
    .OurVission .Content-Mission .image-main-two {
        top: 40px;
        left: -54px;
        height: 105px;
        width: 105px;
    }
    section.OurVission {
        margin-bottom: 25%;
     }
     .OurVission  .Content-Mission .title-main {
        margin-top: 6%;
      }
}
@media (min-width: 576px) and (max-width: 767px) {
    .OurVission h1.title-mission {
        font-size: 38px;
    }
    .OurVission .second-main {
        top: 0px;
    }
    .OurVission .Content-Mission .yellow-background {
        width: 185px;
        height: 185px;
    }
    .OurVission .Content-Mission .image-main {
        top: 37px;
        right: -56px;
        height: 112px;
        width: 109px;
    }
    .OurVission .Content-Mission .image-main-two {
        top: 40px;
        left: -54px;
        height: 105px;
        width: 105px;
    }
    .OurVission  .Content-Mission .title-main {
        margin-top: 6%;
      }
}
@media (min-width: 768px) and (max-width: 991px) {
    .OurVission h1.title-mission {
        font-size: 42px;
    }
    .OurVission .second-main {
        top: 0px;
    }
    .OurVission  .Content-Mission .title-main {
        margin-top: 6%;
      }
}