/* ProductDescription css started */

.ProductDescription .title-main {
    text-align: center;
}
.ProductDescription .text p.explore-btn {
    color: #000000;
    font-weight: 500;
    text-align: center;
    position: relative;
}
.ProductDescription .industry-main img.industry {
    width: 100%;
    height: 100%;
}
.ProductDescription .transparent-tape {
    margin-top: 3%;
}
.ProductDescription .industry-main {
    aspect-ratio: 4/2;
}
.ProductDescription .description p.explore-btn {
    text-align: start;
    margin: 13px 0px;
    color: black;
    font-weight: 500;
}
.ProductDescription .description p.text-p.main-text {
    font-weight: 400 !important;
    margin-bottom: 0px;
}
.ProductDescription .description .main {
    font-weight: 500;
    margin-bottom: 0px;
}
.ProductDescription .description p.text-p.blue {
    color: #009a8f;
    margin-bottom: 0px;
}
.ProductDescription .description p.text-p.italic {
    font-style: italic;
}
.ProductDescription .description .cmj-description {
    margin-top: 3%;
}
.ProductDescription .transparent-tape .explore-btn {
    position: relative;
    color: black;
    font-weight: 500;
    text-align: center;
}
.ProductDescription .transparent-tape  p.explore-btn:before {
    bottom: 12px;
    margin-left: -116px;
}
.ProductDescription  .transparent-tape p.explore-btn:after {
    bottom: 11px;
    margin-left: 8px;
}
.ProductDescription  .transparent-tape p.explore-btn:after, .ProductDescription .transparent-tape  p.explore-btn:before {
    background-color: black;
    content: "";
    height: 2px;
    position: absolute;
    width: 110px;
}

section.ProductDescription {
    margin-top: 10%;
}

/* ProductDescription css End */

@media (min-width:0px) and (max-width:485px) {
    .ProductDescription .text p.explore-btn {
       font-size: 15px;
    }
    .ProductDescription .description p.explore-btn {
        font-size: 15px;
    }
    .ProductDescription .transparent-tape .explore-btn {
      font-size: 17px;
    }
    .ProductDescription  .transparent-tape p.explore-btn:after, .ProductDescription .transparent-tape  p.explore-btn:before {
       width: 60px;
    }
    .ProductDescription .transparent-tape  p.explore-btn:before {
        bottom: 12px;
        margin-left: -71px;
    }
    .WhyUs .why-us-title p.explore-btn:after, .WhyUs .why-us-title p.explore-btn:before {
      width: 60px;
    }
    .WhyUs .why-us-title p.explore-btn:before {
       
        margin-left: -78px;
    }
}
@media (min-width:486px) and (max-width:767px) {
    .ProductDescription .text p.explore-btn {
        font-size: 16px;
     }
     .ProductDescription .description p.explore-btn {
         font-size: 16px;
     }
     .ProductDescription .transparent-tape .explore-btn {
       font-size: 16px;
     }
}
@media (min-width:768px) and (max-width:991px) {
    .ProductDescription .text p.explore-btn {
        font-size: 17px;
     }
     .ProductDescription .description p.explore-btn {
         font-size: 17px;
     }
     .ProductDescription .transparent-tape .explore-btn {
       font-size: 17px;
     }
}
