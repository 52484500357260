/* ProductInformation css started */

.ProductInformation h1.title-main {
    text-align: center;
}
.ProductInformation .text-p {
    font-weight: bold;
}
.ProductInformation .tableDdemo tr td:first-child {
    background-color: #f1f1f1;
    width: 48%;
}
.ProductInformation .tableDdemo .text-p {
    font-weight: 400 !important;
    border-bottom: 2px solid #cfcfcf;
}
.ProductInformation .star {
    display: flex;
    justify-content: space-between;
}

.ProductInformation .star svg.star-svg {
    width: 18px;
    height: 19px;
}
.ProductInformation .star p.text-p {
    color: #00ADB9;
    border-bottom: none;
}
section.ProductInformation {
    margin-top: 4%;
}
/* ProductInformation css End */


/**************** Media Query started ***************/
@media (min-width:0px) and (max-width:485px) {
    .ProductInformation .text-p {
        font-size: 13px;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .ProductInformation .text-p {
        font-size: 14px;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .ProductInformation .text-p {
        font-size: 15px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .ProductInformation .text-p {
        font-size: 16px;
    }
}
