@import url("https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
body {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", "sans-serif", "SourceSansPro-Light.otf" !important;
}
.searchoffcanva {
  height: 100% !important;
}

.searchoffcanva .list-group {
  height: 400px;
  overflow-y: scroll;
}
.searchoffcanva .list-group .list-group-item {
  font-size: 14px;
}
.merriweather-bold {
  font-family: "Merriweather", serif !important;
  font-weight: 700;
  font-style: normal;
}

.blocks-wrapper {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  margin: auto !important;
}

.form-control:focus {
  box-shadow: none !important;
}
@font-face {
  font-family: merriweather;
  src: url(../public/assests/fonts/Merriweather-Regular.ttf);
}
@font-face {
  font-family: myrid;
  src: url(../public//assests/fonts/MYRIADPRO-REGULAR.OTF);
}
@font-face {
  font-family: lucida;
  src: url(../public/assests/fonts/lucon.ttf);
}
.form-control:focus {
  border-color: grey !important;
  outline: 0;
  box-shadow: transparent !important;
}
.small-text {
  font-size: 16px !important;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}
.sub-title {
  font-size: 18px !important;
  color: #000000;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.sub {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}
.text-p {
  font-size: 17px;
  color: #000000;
  font-family: "Roboto", sans-serif;
}
.title {
  font-size: 33px;
  color: #f0d996;
  font-family: "Roboto", sans-serif;
}
.sub-text {
  font-size: 15px !important;
  color: #000000 !important;
  font-family: "Roboto", sans-serif !important;
}
.cmj-text {
  font-family: lucida;
  color: #ffffff;
  opacity: 0.32;
  font-size: 119px;
  margin-bottom: -16px;
}
.footer-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  font-family: merriweather;
}
.cmj-main {
  margin-bottom: 0px;
  font-size: myrid;
  color: #ffffff;
  opacity: 0.32;
  font-size: 40px;
  font-weight: 600;
}
.explore-btn {
  font-size: 20px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}
.choose {
  font-size: 80px;
  color: #000000;
  font-family: merriweather;
  font-weight: bold;
}
.title-main {
  font-size: 34px;
  font-weight: bold;
  color: #000000;
  font-family: merriweather;
}
.sale-price {
  color: #ff0000;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 0px;
}
.modal-backdrop.show {
  opacity: 0.3 !important;
}
.commmontext {
  color: #000;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
span.main-title-border {
  border-bottom: 5px solid #ffaa00;
}
.main-price {
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}
.orange {
  background-color: #ffaa00;
}
.back {
  background: #b7b7b7;
}
.swiper_holder .swiper-button-prev:after,
.swiper_holder .swiper-button-next:after {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  background: #ffaa00;
  padding: 5px 9px;
  border-radius: 50%;
}
a {
  font-size: 14px;
  color: black !important;
  font-weight: 600;
}
.swiper_holder .swiper-button-next,
.swiper_holder .swiper-rtl .swiper-button-prev {
  right: 0px;
  left: 52%;
}
.form-check-input:checked {
  /* background-color: white !important; */
  border-color: black !important;
}
.swiper_holder .swiper-button-prev,
.swiper_holder .swiper-rtl .swiper-button-next {
  left: 49%;
  right: auto;
}

.swiper_holder .swiper-button-prev,
.swiper_holder .swiper-button-next {
  top: 95% !important;
}
.form-control:focus {
  background-color: #f5f5f5 !important;
}

. .form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid red !important;
  background-image: url("") !important;
}

@media (min-width: 0px) and (max-width: 485px) {
  .swiper_holder .swiper-button-prev:after,
  .swiper_holder .swiper-button-next:after {
    font-size: 10px;
    padding: 5px 7px;
    border-radius: 50%;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-button-next {
    top: 80% !important;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-rtl .swiper-button-next {
    left: 45%;
  }
  .small-text {
    font-size: 14px !important;
  }
  .title-main {
    font-size: 20px;
  }
  .choose {
    font-size: 30px;
  }
  .commmontext {
    font-size: 14px;
  }
  .text-p {
    font-size: 13px;
  }
  .sub {
    font-size: 12px;
  }
  .sub-title {
    font-size: 13px !important;
  }
  .footer-text {
    font-size: 14px;
  }
  .cmj-text {
    font-size: 71px;
  }
  .cmj-main {
    font-size: 28px;
  }
  .sub-text {
    font-size: 12px !important;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .swiper_holder .swiper-button-prev:after,
  .swiper_holder .swiper-button-next:after {
    font-size: 10px;
    padding: 5px 7px;
    border-radius: 50%;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-button-next {
    top: 80% !important;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-rtl .swiper-button-next {
    left: 47%;
  }

  .choose {
    font-size: 48px;
  }
  .title-main {
    font-size: 22px;
  }
  .commmontext {
    font-size: 15px;
  }
  .text-p {
    font-size: 14px;
  }
  .small-text {
    font-size: 14px !important;
  }
  .sub-title {
    font-size: 14px !important;
  }

  .cmj-text {
    font-size: 74px;
  }
  .cmj-main {
    font-size: 30px;
  }
  .sub-text {
    font-size: 13px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .swiper_holder .swiper-button-prev:after,
  .swiper_holder .swiper-button-next:after {
    font-size: 10px;
    padding: 5px 7px;
    border-radius: 50%;
  }
  .sub-title {
    font-size: 15px !important;
  }
  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-button-next {
    top: 80% !important;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-rtl .swiper-button-next {
    left: 47%;
  }
  .choose {
    font-size: 50px;
  }
  .title-main {
    font-size: 24px;
  }
  .commmontext {
    font-size: 16px;
  }
  .small-text {
    font-size: 15px !important;
  }

  .footer-text {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    font-family: merriweather;
  }
  .text-p {
    font-size: 15px;
  }
  .cmj-text {
    font-size: 76px;
  }
  .cmj-main {
    font-size: 32px;
  }
  .sub-text {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .swiper_holder .swiper-button-prev:after,
  .swiper_holder .swiper-button-next:after {
    font-size: 10px;
    padding: 5px 7px;
    border-radius: 50%;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-button-next {
    top: 85% !important;
  }
  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-rtl .swiper-button-next {
    left: 48%;
  }
  .choose {
    font-size: 52px;
  }
  .title-main {
    font-size: 30px;
  }
  .commmontext {
    font-size: 17px;
  }
  .text-p {
    font-size: 16px;
  }
  .small-text {
    font-size: 15px !important;
  }
  .cmj-text {
    font-size: 78px;
  }
  .cmj-main {
    font-size: 34px;
  }
  .sub-title {
    font-size: 16px !important;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .swiper_holder .swiper-button-prev:after,
  .swiper_holder .swiper-button-next:after {
    font-size: 10px;
    padding: 5px 7px;
    border-radius: 50%;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-button-next {
    top: 90% !important;
  }
  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-rtl .swiper-button-next {
    left: 49%;
  }
  .title-main {
    font-size: 32px;
  }
  .commmontext {
    font-size: 18px;
  }
  .text-p {
    font-size: 17px;
  }
  .small-text {
    font-size: 15px !important;
  }
  .cmj-text {
    font-size: 80px;
  }
  .cmj-main {
    font-size: 36px;
  }
  .choose {
    font-size: 54px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .swiper_holder .swiper-button-prev:after,
  .swiper_holder .swiper-button-next:after {
    font-size: 10px;
    padding: 5px 7px;
    border-radius: 50%;
  }

  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-button-next {
    top: 90% !important;
  }
  .swiper_holder .swiper-button-prev,
  .swiper_holder .swiper-rtl .swiper-button-next {
    left: 49%;
  }
  .title {
    font-size: 28px;
  }
  .text {
    font-size: 13px;
  }

  .cmj-text {
    font-size: 105px;
  }

  .footer-text {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    font-family: merriweather;
  }

  .cmj-text {
    font-size: 80px;
  }
  .cmj-main {
    font-size: 36px;
  }

  .cmj-text {
    font-size: 100px;
  }
  .explore-btn {
    font-size: 16px;
  }
  .choose {
    font-size: 56px;
  }
  .title-main {
    font-size: 28px;
  }
  .commmontext {
    font-size: 19px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .choose {
    font-size: 60px;
  }
  .text {
    font-size: 13px;
  }

  .cmj-text {
    font-size: 105px;
  }

  .cmj-main {
    font-size: 33px;
  }
}
