.WhyChoose .title-main {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.WhyChoose .Choose-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F0D996;
    border-radius: 10px;
    margin-bottom: 5%;
}
.WhyChoose  .Choose-card .Main-img   img.choose1 {
    height: 100%;
    width: 100%;
}
.WhyChoose  .Choose-card .Main-img  .image {
    width: 213px;
    height: 194px;
}
.WhyChoose  .Choose-card .Main-img {
    text-align: -webkit-center;
}
.WhyChoose  .Choose-card  h1.no {
    font-size: 71px;
    color: #FFAA00;
    opacity: 0.12;
    font-family: "Roboto", sans-serif;
    margin: 0px 16px;
}
.WhyChoose  p.commmontext {
    font-weight: 600;
}
.WhyChoose  .Choose-card .Main-img  .image2 {
    width: 100%;
    height: 196px;
}
.WhyChoose  .Choose-card .Main-img  .image3 {
    width: 100%;
    height: 197px;
}
.WhyChoose  .Choose-card .Main-img  .image4 {
    width: 100%;
    height: 225px;
}


/*********************** Media Query Started *****************************/

@media (min-width:0px) and (max-width:320px) {
    .WhyChoose  .Choose-card .Main-img  .image {
        width: 185px;
        height: 181px;
    }
    .WhyChoose  .Choose-card  h1.no {
        font-size: 46px;
    }
    section.WhyChoose {
        margin-top: 11%;
    }
    .choose-Main {
        margin-top: 9% !important;
    }
}
@media (min-width:321px) and (max-width:485px) {
    .WhyChoose  .Choose-card .Main-img  .image {
        width: 185px;
        height: 181px;
    }
  
    .WhyChoose  .Choose-card .Main-img  .image2 {
        width: 71%;
        height: 219px;
    }
    .WhyChoose  .Choose-card .Main-img  .image3 {
        width: 66%;
        
    }
    .WhyChoose  .Choose-card .Main-img  .image4 {
        width: 69%;
       
    }
    .WhyChoose  .Choose-card  h1.no {
        font-size: 49px;
    }
    section.WhyChoose {
        margin-top: 9%;
    }
    .choose-Main {
        margin-top: 15% !important;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .WhyChoose  .Choose-card .Main-img  .image {
        width: fit-content;
        height: 181px;
    }
    
    .WhyChoose  .Choose-card .Main-img  .image2 {
        width: fit-content;
        height: 219px;
    }
    .WhyChoose  .Choose-card .Main-img  .image3 {
        width: fit-content;
    }
    .WhyChoose  .Choose-card .Main-img  .image4 {
        width: fit-content;
    }
    .WhyChoose  .Choose-card  h1.no {
        font-size: 52px;
    }
    section.WhyChoose {
        margin-top: 7%;
    }
    .choose-Main {
        margin-top: 10% !important;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .WhyChoose  .Choose-card .Main-img  .image {
        width: fit-content;
       
    }
    
    .WhyChoose  .Choose-card .Main-img  .image2 {
        width: fit-content;
      
    }
    .WhyChoose  .Choose-card .Main-img  .image3 {
        width: fit-content;
    }
    .WhyChoose  .Choose-card .Main-img  .image4 {
        width: fit-content;
    }
    .WhyChoose  .Choose-card  h1.no {
        font-size: 56px;
    }
    
}
@media (min-width:768px) and (max-width:1199px) {
    .WhyChoose  .Choose-card .Main-img  .image {
        width: fit-content;
       
    }
    
    .WhyChoose  .Choose-card .Main-img  .image2 {
        width: fit-content;
      
    }
    .WhyChoose  .Choose-card .Main-img  .image3 {
        width: fit-content;
    }
    .WhyChoose  .Choose-card .Main-img  .image4 {
        width: fit-content;
    }
    .WhyChoose  .Choose-card  h1.no {
        font-size: 60px;
    }
}