.Order .Main-Order-Card {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  padding: 41px 42px;
}
.Order .Main-Order-Card .image-main img.tape {
  height: 100%;
  width: 100%;
}
.Order .Main-Order-Card .image-main {
  height: 122px;
  width: 150px;
}
.Order .steps {
    margin: 2% 0px;
}
.step{
    margin: 2% 0px;
}
.Order  span.rate-price {
    text-decoration: line-through;
}
.Order .Main-Order-Card .main-back {
  padding: 23px 21px;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Order .Main-Order-Card .Content .commmontext {
  font-weight: 500;
}
.Order .Main-Order-Card .Content .small-text {
  margin-bottom: 4px;
  font-weight: 400;
}
.Order .Main-Order-Card .Content p.commmontext.price {
  color: #ff0000;
}
.Order .Main-Order-Card .Content .price-main {
  display: flex;
}

.Order .Main-Order-Card .Content .step {
  width: 173px;
  padding: 8px 11px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #808080;
  border-radius: 5px;
  position: relative;
  height: 100%;
}
/* .Order .Main-Order-Card .Content .steps .step1 {
    width: 173px;
    padding: 8px 11px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #808080;
    border-radius: 5px;
    position: relative;
    height: 100%;
} */

.Order .Main-Order-Card .Content .steps  .step-name .circle {
  color: #000;
  padding: 3px 8px;
  border-radius: 100px;
  border: none;
  width: 30px;
  height: 29px;
  background: #fff 0% 0% no-repeat padding-box;
}

.Order .Main-Order-Card .Content .steps  .step-name .circle-fill {
  color: #fff;
  padding: 3px 8px;
  border-radius: 100px;
  border: none;
  width: 30px;
  height: 29px;
  background: #ffaa00 0% 0% no-repeat padding-box;
}

.Order .Main-Order-Card .Content .steps .step1 .step-name {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
/* .Order .Main-Order-Card .Content .steps .step1 .border-card::after {
    content: "";
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 100%;
    background-color: #ffaa00;
    position: absolute;
    top: 29px;
    right: -15px;
} */
.Order .Main-Order-Card .Content .steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.Order .Main-Order-Card .Content .steps .step-name .sub {
  text-align: center;
}
.Order .Main-Order-Card .Content .steps .border-card {
  border-style: dashed;
  border-width: 1px;
  border-color: #808080;
  width: 130px;
  height: 100%;
  margin: 0px auto;
  position: relative;
}
/* .Order .Main-Order-Card .Content .steps .step2 {
    width: 173px;
    padding: 8px 11px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #808080;
    border-radius: 5px;
    position: relative;
    height: 100%;
} */
/* .Order .Main-Order-Card .Content .steps .step3 {
    width: 173px;
    padding: 8px 11px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #808080;
    border-radius: 5px;
    position: relative;
    height: 100%;
} */

/* .Order .Main-Order-Card .Content .steps .step4 {
    width: 173px;
    padding: 8px 11px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #808080;
    border-radius: 5px;
    position: relative;
    height: 100%;
} */
.Order .Main-Order-Card .Content .steps .step-name {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.Order .Main-Order-Card .Content .steps .completed {
  border: 1px solid #000;
}

.Order .Main-Order-Card .Content .steps .incompleted {
  border: 1px solid #808080;
}

.Order .Main-Order-Card .Content .steps .step-name .circle {
  display: flex;
  text-align: center;

  border-radius: 100px;
  border: none;
  width: 30px;
  height: 29px;
  border: 1px solid #ffaa00;
  align-items: center;
  justify-content: center;
}
/* .Order .Main-Order-Card .Content .steps  .border-card::after {
    content: "";
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    top: 27px;
    right: -19px;
    border: 1px solid #ffaa00;
}
.Order .Main-Order-Card .Content .steps  .border-card::before {
    content: "";
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    top: 27px;
    left: -19px;
    border: 1px solid #ffaa00;
} */

/* .Order .Main-Order-Card .Content .steps  .border-card-before-after::before {
    content: "";
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 100%;
    background-color: #ffaa00;
    position: absolute;
    top: 27px;
    left: -19px;
    border: 1px solid #ffaa00;
}


.Order .Main-Order-Card .Content .steps  .border-card::before {
    content: "";
    display: block;
    height: 9px; 
    width: 9px;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    top: 27px;
    left: -19px;
    border: 1px solid #ffaa00;
} */

.Order .Main-Order-Card .Content .steps .border-card::before {
  content: "";
  display: block;
  height: 9px;
  width: 9px;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  top: -4px;
  left: -10px;
  border: 1px solid #ffc107;
}
.Order .Main-Order-Card .Content .steps .border-card::after {
  content: "";
  display: block;
  height: 9px;
  width: 9px;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  top: -4px;
  right: -10px;
  border: 1px solid #ffc107;
}

.Order .Main-Order-Card .Content .steps .border-card-before-after {
  border-color: #ffaa00;
}

.Order .Main-Order-Card .Content .steps .border-card-before-after::before,
.Order .Main-Order-Card .Content .steps .border-card-before-after::after {
  background-color: orange;
  border: 1px solid #ffaa00;
}
.Order .Main-Order-Card .Content .steps .border-card-before-after-incomplete {
  border-color: #808080;
}

.Order
  .Main-Order-Card
  .Content
  .steps
  .border-card-before-after-incomplete::before,
.Order
  .Main-Order-Card
  .Content
  .steps
  .border-card-before-after-incomplete::after {
  background-color: #fff;
  border: 1px solid #ffaa00;
}

/* .Order .Main-Order-Card .Content .steps .step3.completed .border-card::before,
.Order .Main-Order-Card .Content .steps .step3.completed .border-card::after {
    background-color: orange;
    border: 1px solid #ffaa00;
} */

/* .Order .Main-Order-Card .Content .steps  .border-card::before {
    left: -19px;
}

.Order .Main-Order-Card .Content .steps  .border-card::after {
    right: -19px;
} */
.Order .Main-Order-Card .Content .Btn-main {
  display: flex;
  justify-content: space-between;
}
.Order .Main-Order-Card .Content .Btn-main button.return {
  padding: 6px 100px;
  border-radius: 5px;
  border: 1px solid grey;
}
.Order .Main-Order-Card .Content .Btn-main button.orange.cancel {
  padding: 10px 100px;
  border: none;
  border-radius: 5px;
}
.Order .Main-Order-Card .Content .Btn-main button.back.return1 {
  border-radius: 5px;
  border: none;
  padding: 10px 100px;
  color: white;
}
svg.svg-inline--fa.fa-download.heart-icon {
  color: #3d3d3d;
}
.order-content {
  display: flex;
  justify-content: space-between;
}
.Order .view p.small-text {
  font-weight: bold !important;
  border-bottom: 1px solid black;
  width: fit-content;
  margin-bottom: 0px !important;
}
.Order .steps .sub {
  text-align: center;
}
/* Common card css */
.Order .card-product {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  padding: 41px 42px;
  margin-top: 3%;
}
.Order .card-product .image-main img.tape {
  height: 100%;
  width: 100%;
}
.Order .card-product .image-main {
  height: 122px;
  width: 150px;
}
.Order .card-product .main-back {
  padding: 23px 21px;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Order p.commmontext.price {
  color: #ff0000;
  font-weight: 600;
}
.Order .card-product .price-main {
  display: flex;
}
.Order .view {
  text-align: -webkit-right;
  height: 88%;
}
.Order .download {
  text-align: end;
}
.Order .sub {
  margin-bottom: 8px;
  /* text-align: center; */
}
.Order button.return {
  border: 1px solid #b5b4b4;
  border-radius: 5px;
  padding: 5px 20px;
}
.Order p.sub-title.d {
  color: #195400;
}

.Order p.sub-title.r {
  color: #ffaa00;
}

.Order p.sub-title.red {
  color: #ff0000;
}

.Order .Delivered {
  display: flex;
  justify-content: space-between;
}
.Order .Delivered .rate {
  display: flex;
}
.Order .Delivered .rate svg.star-svg {
  height: 24px;
  width: 24px;
}

/********************** Media Query Started **********************/

@media (min-width: 0px) and (max-width: 485px) {
  .Order .Main-Order-Card .Content .steps {
    display: block;
    text-align: -webkit-center;
  }
  section.Order {
    margin-bottom: 32%;
}
  .Order .Main-Order-Card .Content .Btn-main {
    display: grid;
    margin-top: 8%;
    justify-content: center;
  }
  .Order .Main-Order-Card .Content .steps .border-card {
    width: 43px;
    margin-top: 28px;
    height: 100%;
    margin: 33px 0px;
    transform: rotate(90deg);
}
.Order .steps {
    margin-top: 13%;
}
  /* .Order .Main-Order-Card .Content .steps .step1::after {
    top: 80px;
    right: 0px;
    left: 50%;
  } */
  /* .Order .Main-Order-Card .Content .steps .step2::before {
    left: 50%;
  } */
  /* .Order .Main-Order-Card .Content .steps .step2::before {
    top: -10px;
  } */
  /* .Order .Main-Order-Card .Content .steps .step2::after {
    top: 79px;
  }
  .Order .Main-Order-Card .Content .steps .step2::after {
    right: 45%;
  } */
  /* .Order .Main-Order-Card .Content .steps .step3::before {
        top: -11px;
    } */
  /* .Order .Main-Order-Card .Content .steps .step3::before {
    top: -9px;
    left: 50%;
  } */
  .Order .Main-Order-Card .Content .price-main {
    display: block;
  }
  .order-content {
    display: block;
  }
  .Order .Main-Order-Card .Content .Btn-main button.back.return1 {
    padding: 7px 50px;
    width: auto;
  }
  .Order .Main-Order-Card .Content .Btn-main button.orange.cancel {
    padding: 7px 49px;
    width: auto;
  }
  .Order .Main-Order-Card .Content .Btn-main button.return {
    padding: 7px 21px;
    width: auto;
  }
  .Order .card-product .price-main {
    display: block;
  }
  .Order button.return {
    width: auto;
  }
  .Order .Delivered {
    display: block;
  }
  .Order .main {
    text-align: -webkit-center;
  }
  .Order .Main-Order-Card .main-back {
    width: 100%;
  }
  .Content-main {
    margin-top: 5%;
  }
  .Order .view {
    text-align: -webkit-left;
  }
  .Order .download {
    text-align: start;
  }
  .Order .card-product .main-back {
    width: 100%;
  }
  .Order .Main-Order-Card {
    padding: 41px 20px;
  }
  .Order .steps {
    margin-top: 13%;
}
}
@media (min-width: 486px) and (max-width: 767px) {
  .Order .Main-Order-Card .Content .steps {
    display: block;
    text-align: -webkit-center;
  }
  section.Order {
    margin-bottom: 32%;
}
  .Order .steps {
    margin-top: 13%;
}
  .Order .Main-Order-Card .Content .Btn-main {
    display: grid;
    margin-top: 8%;
    justify-content: center;
  }
  .Order .Main-Order-Card .Content .steps .border-card {
    width: 43px;
    margin-top: 28px;
    height: 100%;
    margin: 33px 0px;
    transform: rotate(90deg);
}
  /* .Order .Main-Order-Card .Content .steps .step1::after {
    top: 80px;
    right: 0px;
    left: 50%;
  } */
  /* .Order .Main-Order-Card .Content .steps .step2::before {
    left: 50%;
  } */
  /* .Order .Main-Order-Card .Content .steps .step2::before {
    top: -9px;
  } */
  /* .Order .Main-Order-Card .Content .steps .step2::after {
    top: 79px;
  }
  .Order .Main-Order-Card .Content .steps .step2::after {
    right: 44%;
  } */
  /* .Order .Main-Order-Card .Content .steps .step3::before {
    top: -10px;
  }
  .Order .Main-Order-Card .Content .steps .step3::before {
    left: 50%;
  } */

  .order-content {
    display: block;
  }
  .Order .Main-Order-Card .Content .Btn-main button.back.return1 {
    padding: 7px 50px;
    width: auto;
  }
  .Order .Main-Order-Card .Content .Btn-main button.orange.cancel {
    padding: 7px 49px;
    width: auto;
  }
  .Order .Main-Order-Card .Content .Btn-main button.return {
    padding: 7px 21px;
    width: auto;
  }

  .Order button.return {
    width: auto;
  }

  .Order .main {
    text-align: -webkit-center;
  }
  .Order .Main-Order-Card .main-back {
    width: 100%;
  }
  .Content-main {
    margin-top: 5%;
  }
  .Order .view {
    text-align: -webkit-left;
  }
  .Order .download {
    text-align: start;
  }
  .Order .card-product .main-back {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .Order .Main-Order-Card .Content .steps .border-card {
    margin-top: 26px;
    height: 12px;
  }
  .Order .Main-Order-Card .Content .Btn-main {
    margin-top: 3%;
  }
  .Order .steps {
    margin-top: 13%;
}
  .Order .Main-Order-Card .Content .steps {
    display: block;
    text-align: -webkit-center;
  }

  .Order .Main-Order-Card .Content .steps .border-card {
    width: 43px;
    margin-top: 28px;
    height: 100%;
    margin: 33px 0px;
    transform: rotate(90deg);
}
section.Order {
  margin-bottom: 25%;
}
  /* .Order .Main-Order-Card .Content .steps .step1::after {
    top: 27px;
    right: -16px;
  } */

  .Order .Main-Order-Card .Content .Btn-main button.orange.cancel {
    padding: 10px 30px;
  }
  
  
  .Order .Main-Order-Card .Content .Btn-main button.back.return1 {
    padding: 10px 30px;
  }
  
  .Order .Main-Order-Card .Content .Btn-main button.return {
    padding: 10px 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* .Order .Main-Order-Card .Content .steps .border-card {
    margin-top: 24px;
    height: 18px;
  } */
  /* .Order .Main-Order-Card .Content .steps .step1::after {
    top: 27px;
    right: -18px;
  } */
  .Order .Main-Order-Card .Content .Btn-main {
    margin-top: 4%;
  }
  .Order .Main-Order-Card .Content .steps .border-card {
    width: 20px;
}
section.Order {
  margin-bottom: 25%;
}

.Order .Main-Order-Card .Content .Btn-main button.orange.cancel {
  padding: 10px 48px;
}


.Order .Main-Order-Card .Content .Btn-main button.back.return1 {
  padding: 10px 48px;
}

.Order .Main-Order-Card .Content .Btn-main button.return {
  padding: 10px 48px;
}
}
@media (min-width: 1200px) and (max-width: 1399px) {
  /* .Order .Main-Order-Card .Content .steps .border-card {
    margin-top: -12px;
    height: 88px;
  } */

  .Order .Main-Order-Card .Content .steps .border-card {
    width: 70px;
}
  /* .Order .Main-Order-Card .Content .steps .step1::after {
    top: 27px;
    right: -19px;
  } */
  .Order .Main-Order-Card .Content .Btn-main {
    margin-top: 4%;
  }
 
}
