/* TrackOrder css started */

.TrackOrder .track-order img.order {
    height: 100%;
    width: 100%;
}

.TrackOrder .image-overlay {
    background: transparent linear-gradient(90deg, #fffffff7 0%, #fffbfbab 34%, #ffffff00 83%) 0% 0% no-repeat
        padding-box;
    position: absolute;
    top: 0px;
    width: -webkit-fill-available;
    height: 100%;
}
.TrackOrder .track-order {
    position: relative;
    height: 100%;
}

.TrackOrder .track-order .content {
    transform: translate(4%, -50%);
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 100%;
}
.TrackOrder .track-order .content h1.order-title {
    color: #000000;
    font-size: 50px;
    font-family: merriweather;
    font-weight: 600;
}
.TrackOrder .track-order .content h2.sub-title-order {
    font-size: 31px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

/* Track Code */
.TrackOrder .Track-Code {
    height: 377px;
    width: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 17px;
    padding: 15px 44px;
    margin: 0px 29px 0px 0px;
}

.TrackOrder .Track-Code_two {
    height: 100%;
    width: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 17px;
    padding: 33px 44px;
    margin: 0px 29px 0px 0px;
}

.TrackOrder .Track-Code h4.code-title {
    text-align: center;
    color: #000000;
    font-size: 20px;
    margin-top: 14px;
    font-family: "Roboto", sans-serif;
}
.TrackOrder .Track-Code label.form-label {
    font-size: 18px;
    color: #000000;
    font-family: "Roboto", sans-serif;
}
.TrackOrder .Track-Code .form-control {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: none;
    color: #444141;
    font-size: 14px;
    font-weight: 600;
    padding: 13px 24px;
}
.TrackOrder .Track-Code h4.product {
    margin-top: 7%;
    font-size: 18px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    text-align: center;
}
.TrackOrder .Track-Code form {
    margin: 46px 0px;
}
.TrackOrder .Track-Code .submit button.submit {
    padding: 7px 117px;
    border: none;
    background: #faaa00 0% 0% no-repeat padding-box;
    border-radius: 5px;
}
.TrackOrder .Track-Code .submit {
    text-align: center;
    margin-top: 6%;
}
/* TrackOrder css started */

/* progressbar css */
.TrackOrder .stepper-div .ranges-holder {
    display: flex;
    justify-content: space-between;
}
.TrackOrder .stepper-div {
    height: 69px;
    position: relative;
    left: -111px;
}
.TrackOrder .stepper-div .indexedStep {
    top: -1px;
    left: -36px;
    position: relative;
    color: white;
    width: 20px;
    height: 20px;
    margin: 0px 29px;
    font-size: 12px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #5b4020;
    border-radius: 50%;
    display: flex;
    justify-content: normal;
    align-items: revert-layer;
}
.TrackOrder .stepper-div .indexedStep.accomplished {
    padding: 8px 4px;
    background-color: orange;
    width: 20px;
    height: 20px;
}

.TrackOrder .RSPBprogressBar {
    margin: 0px -1px;
    top: 140px;
    height: 3px;
    border-top: 1px dashed black;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    transform: rotate(90deg);
    width: 230px;
}
.TrackOrder .range-text-div {
    position: absolute;
    width: 300px;
    top: -181px;
    left: -134px;
    transform: rotateZ(270deg);
}

.TrackOrder .track-order .content .main-content-holder {
    display: flex;
    justify-content: start;
    align-items: center;
}

.TrackOrder h6.mini-text {
    color: black;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

.TrackOrder .flip-vertical-right {
    -webkit-animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
}
@keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
}

/* ******************Media Query started **************************/

@media (min-width: 0px) and (max-width: 396px) {
    .TrackOrder .track-order .content {
        transform: translate(1%, -14%);
        position: relative;
    }
    .TrackOrder .track-order .content h1.order-title {
        font-size: 18px;
    }
    .TrackOrder .track-order .content h2.sub-title-order {
        font-size: 15px;
    }
    .TrackOrder .Track-Code {
        width: auto;
        margin: 0px;
        margin-top: 2%;
    }
    .TrackOrder .Track-Code .form-control {
        font-size: 11px;
    }
    .TrackOrder .Track-Code h4.code-title {
        font-size: 16px;
    }
    .TrackOrder .Track-Code h4.product {
        font-size: 15px;
    }
    .TrackOrder .Track-Code .submit button.submit {
        font-size: 14px;
        padding: 5px 66px;
    }

    .TrackOrder .stepper-div {
        /* margin-top: 47%; */
        margin-bottom: 5%;
        left: -80px;
    }
    .TrackOrder .range-text-div {
        text-align: left;
        width: 214px;
        top: -134px;
        left: -89px;
        transform: rotateZ(270deg);
    }

    .TrackOrder .RSPBprogressBar {
        margin: 0px -1px;
        top: 140px;
        left: -38px;
    }

    .TrackOrder .stepper-div .indexedStep {
        width: 18px;
        height: 18px;
    }
    .TrackOrder .stepper-div .indexedStep.accomplished {
        width: 17px;
        height: 17px;
    }

    .Footer .logo-footer {
        top: -18%;
        height: 35%;
        margin: 0px auto;
    }

    .TrackOrder h6.mini-text {
        font-size: 13px;
    }

    .main-content {
        margin-top: -15%;
    }
}
@media (min-width: 397px) and (max-width: 485px) {
    .TrackOrder .track-order .content {
        transform: translate(0%, -11%);
        position: relative;
    }
    .main-content {
        margin-top: -20%;
        margin-left: 18px;
    }
    .TrackOrder .track-order .content h1.order-title {
        font-size: 24px;
    }
    .TrackOrder .track-order .content h2.sub-title-order {
        font-size: 18px;
    }
    .TrackOrder .Track-Code {
        width: auto;
        padding: 37px 25px;
        margin: 0px;
        margin-top: 8%;
        padding-top: 13px;
    }
    .TrackOrder .Track-Code h4.code-title {
        font-size: 18px;
    }
    .TrackOrder .Track-Code h4.product {
        font-size: 15px;
    }
    .TrackOrder .Track-Code .submit button.submit {
        font-size: 16px;
        padding: 5px 91px;
    }
    .TrackOrder .Track-Code label.form-label {
        font-size: 16px;
    }

    .TrackOrder .stepper-div {
        margin-top: 40%;
        margin-bottom: 16%;
        left: -78px;
    }
    .TrackOrder .range-text-div {
        text-align: justify;
        width: 262px;
        top: -163px;
        left: -117px;
        transform: rotateZ(270deg);
    }
    .TrackOrder .RSPBprogressBar {
        top: 0px;
    }
    .TrackOrder h6.mini-text {
        font-size: 14px;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
    .TrackOrder .track-order .content {
        transform: translate(0%, -30%);
        position: relative;
    }
    .TrackOrder .track-order .content h1.order-title {
        font-size: 26px;
    }
    .TrackOrder .track-order .content h2.sub-title-order {
        font-size: 18px;
    }
    .TrackOrder .Track-Code {
        width: auto;
        padding: 37px 25px;
        margin: 0px;
        margin-top: 8%;
    }
    .TrackOrder .Track-Code h4.code-title {
        font-size: 19px;
    }
    .TrackOrder .Track-Code h4.product {
        font-size: 16px;
    }
    .TrackOrder .Track-Code .submit button.submit {
        font-size: 16px;
        padding: 5px 93px;
    }
    .TrackOrder .Track-Code label.form-label {
        font-size: 17px;
    }
    .TrackOrder .stepper-div {
        left: -78px;
        top: 161px;
    }
    .TrackOrder .range-text-div {
        text-align: justify;
        width: 262px;
        top: -163px;
        left: -117px;
        transform: rotateZ(270deg);
    }
    .TrackOrder .RSPBprogressBar {
        top: 0px;
        width: 190px;
    }
    .TrackOrder h6.mini-text {
        font-size: 14px;
    }
    .main-content {
        margin-top: 0%;
    }

    .TrackOrder .stepper-div .indexedStep {
        width: 18px;
        height: 18px;
        margin: 0px 22px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .TrackOrder .track-order .content h1.order-title {
        font-size: 30px;
    }
    .TrackOrder .track-order .content h2.sub-title-order {
        font-size: 22px;
    }
    .TrackOrder .Track-Code {
        width: auto;
        padding: 37px 25px;
        margin: 0px;
        margin-top: 6%;
        padding-top: 10px;
    }

    .TrackOrder .Track-Code .submit button.submit {
        font-size: 17px;
        padding: 6px 106px;
    }
    .TrackOrder .Track-Code label.form-label {
        font-size: 17px;
    }
    .TrackOrder .stepper-div {
        left: -78px;
        top: 40%;
    }
    .TrackOrder .range-text-div {
        text-align: justify;
        width: 262px;
        top: -163px;
        left: -117px;
        transform: rotateZ(270deg);
    }
    .TrackOrder .RSPBprogressBar {
        top: 0px;
    }
    .TrackOrder h6.mini-text {
        font-size: 14px;
    }
    .TrackOrder .track-order .content {
        transform: translate(0%, -30%);
        position: relative;
    }
    .main-content {
        margin-top: 0%;
        margin-left: 5%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .TrackOrder .track-order .content {
        transform: translate(0%, -33%);
        position: relative;
    }
    .TrackOrder .track-order .content h1.order-title {
        font-size: 36px;
    }
    .TrackOrder .track-order .content h2.sub-title-order {
        font-size: 26px;
    }
    .TrackOrder .Track-Code {
        margin-top: 10%;
    }
    .TrackOrder .stepper-div {
        left: -78px;
        top: 180px;
    }
    .TrackOrder .range-text-div {
        text-align: justify;
        width: 288px;
        top: -198px;
        left: -127px;
        transform: rotateZ(270deg);
    }
    .TrackOrder .RSPBprogressBar {
        top: -35px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .TrackOrder .track-order .content h1.order-title {
        font-size: 38px;
    }
    section.TrackOrder {
        margin-bottom: 21%;
    }
    .TrackOrder .track-order .content h2.sub-title-order {
        font-size: 26px;
    }

    .TrackOrder .Track-Code {
        padding: 14px 33px;
        margin: 15px 0px 15px 0px;
        height: 327px;
    }
    .TrackOrder .stepper-div {
        left: -111px;
        top: 40%;
    }
    .TrackOrder .range-text-div {
        text-align: justify;
        width: 288px;
        top: -184px;
        left: -130px;
        transform: rotateZ(270deg);
    }
    .TrackOrder .RSPBprogressBar {
        top: 0px;
        width: 163px;
        left: 42px;
    }

    .TrackOrder .stepper-div .indexedStep {
        width: 15px;
        height: 15px;
        margin: 0px 20px;
    }

    .TrackOrder .stepper-div .indexedStep.accomplished {
        width: 17px;
        height: 15px;
    }
    .TrackOrder .Track-Code .submit {
        margin-top: 4%;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
}
