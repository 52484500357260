/* Deals css started */

.Deals .Deals_swiper .Deals_mySwiper .deals_img_holder .deals_img {
    width: 100%;
    height: 100%;
}
section.Deals {
    margin-top: 4%;
}
.Deals .Deals_swiper .Deals_mySwiper {
    padding: 90px 0px;
}
.Deals .Deals_swiper img.deals_img {
    height: 100%;
    width: 100%;
}
.Deals .Deals_swiper .silder-btn {
    position: relative;
}
.Deals .Deals_swiper .silder-btn .next-btn {
    padding: 1px 8px;
    left: 50%;
    bottom: 0px;
    position: absolute;
    background-color: #ffaa00;
    height: 27px;
    width: 27px;
    border-radius: 100px;
    z-index: 1;
    color: white;
}
.Deals .Deals_swiper .silder-btn .back-btn {
    color: white;
    padding: 1px 8px;
    bottom: 0px;
    position: absolute;
    background-color: #ffaa00;
    height: 27px;
    width: 27px;
    right: 51%;
    z-index: 1;
    border-radius: 100px;
}
.Deals .swiper {
    padding: 72px 0px;
}
/* Deals css end */

/* Media Query started */

@media (min-width: 0px) and (max-width: 485px) {
    .Deals .Deals_swiper .silder-btn .back-btn {
     
        right: 50%;
    }
    .Deals .swiper {
      padding: 47px 0px;
  }
  .Deals .Deals_swiper .silder-btn .next-btn {
    left: 51%;
 
  
}
}
