.privacy_section .Privacy_main .privacybanner_div {
    width: 100%;
    position: relative;
}
.privacy_section .Privacy_main .privacybanner_div .privacybanner {
    width: 100%;
}
.privacy_section .Privacy_main .banoverlays {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #000000de 0%, #00000000 40%) 0% 0% no-repeat padding-box;
    border-radius: 11px 11px 0px 0px;
    text-align: center;
}
.privacy_section .Privacy_main .banoverlays .bannertitle {
    font-size: 27px;
    font-weight: 500;
    color: #fff;
    padding-top: 10px;
}
.privacy_section .Privacy_main .bannertext_div {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 30px 45px;
}

.privacy_section .Privacy_main .bannertext_main_div {
    position: relative;
    width: 88%;
    margin: 0px auto;
    margin-top: -60px;
    z-index: 12;
}

.privacy_section .Privacy_main .bannertext_main_div .bannersubtext_div {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 25px 5%;
}

.privacy_section .Privacy_main .privcytext {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    padding-left: 15px;
}
.privacy_section .Privacy_main .privecy_subtitle {
    font-size: 17px;
    font-weight: 600;
    color: #000000;
}
.privacy_section .Privacy_main .borderss {
    border-top: 1px solid #cecdcd;
}

.privacy_section .Privacy_main .bigtextt {
    font-weight: 600;
    font-size: 80px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.03;
    text-align: center;
}

.privacy_section .Privacy_main .boldtext {
    font-weight: 600 !important;
}

.enjoytext_div {
    border-bottom: 2px solid #ffaa00;
}

@media (min-width: 0px) and (max-width: 320px) {
    .privacy_section .Privacy_main .banoverlays .bannertitle {
        font-size: 15px;
    }
    .privacy_section .Privacy_main .bannertext_div {
        padding: 15px 15px;
    }
    .privacy_section .Privacy_main .bannertext_main_div {
        margin-top: -20px;
    }
    .privacy_section .Privacy_main .privecy_subtitle {
        font-size: 15px;
    }
    .privacy_section .Privacy_main .privcytext {
        font-size: 13px;
        padding-left: 12px;
    }
    .privacy_section .Privacy_main .bigtextt {
        font-size: 25px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .privacy_section .Privacy_main .banoverlays .bannertitle {
        font-size: 15px;
    }
    .privacy_section .Privacy_main .bannertext_div {
        padding: 15px 15px;
    }
    .privacy_section .Privacy_main .bannertext_main_div {
        margin-top: -20px;
    }
    .privacy_section .Privacy_main .privecy_subtitle {
        font-size: 15px;
    }
    .privacy_section .Privacy_main .privcytext {
        font-size: 13px;
        padding-left: 12px;
    }
    .privacy_section .Privacy_main .bigtextt {
        font-size: 27px;
    }
    section.privacy_section {
        margin-bottom: 30%;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .privacy_section .Privacy_main .banoverlays .bannertitle {
        font-size: 17px;
    }
    .privacy_section .Privacy_main .bannertext_div {
        padding: 18px 20px;
    }
    .privacy_section .Privacy_main .bannertext_main_div {
        margin-top: -30px;
    }
    .privacy_section .Privacy_main .privecy_subtitle {
        font-size: 16px;
    }
    .privacy_section .Privacy_main .privcytext {
        font-size: 14px;
        padding-left: 12px;
    }
    .privacy_section .Privacy_main .bigtextt {
        font-size: 35px;
    }
    section.privacy_section {
        margin-bottom: 28%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .privacy_section .Privacy_main .banoverlays .bannertitle {
        font-size: 19px;
    }
    .privacy_section .Privacy_main .bannertext_div {
        padding: 21px 28px;
    }
    .privacy_section .Privacy_main .bannertext_main_div {
        margin-top: -40px;
    }
    .privacy_section .Privacy_main .privecy_subtitle {
        font-size: 17px;
    }
    .privacy_section .Privacy_main .privcytext {
        font-size: 15px;
        padding-left: 13px;
    }
    .privacy_section .Privacy_main .bigtextt {
        font-size: 35px;
    }
    section.privacy_section {
        margin-bottom: 25%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .privacy_section .Privacy_main .banoverlays .bannertitle {
        font-size: 22px;
    }
    .privacy_section .Privacy_main .bannertext_div {
        padding: 25px 35px;
    }
    .privacy_section .Privacy_main .bannertext_main_div {
        margin-top: -45px;
    }
    .privacy_section .Privacy_main .bigtextt {
        font-size: 43px;
    }
    section.privacy_section {
        margin-bottom: 20%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    section.privacy_section {
        margin-bottom: 20%;
    }
}
