/* .FAQ css started */

.FAQ .faq-banner img.faq {
    width: 100%;
}

.FAQ .image-overlay {
    background: transparent linear-gradient(90deg, #ffffff 0%, #ffffff00 27%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0px;
    width: -webkit-fill-available;
    height: 100%;
}
.FAQ .faq-banner .content {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translate(14%, -50%);
}
section.FAQ {
    position: relative;
    margin-bottom: 17%;
}
.FAQ .faq-banner {
    margin-top: 3%;
    position: relative;
    height: 100%;
    margin-bottom: 2%;
}

/* Faq content css */
.FAQ .faq-main.accordion .accordion-button:not(.collapsed) {
    background: #f5f5f5 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #000000 !important;
}
.FAQ .faq-main.accordion button.accordion-button.collapsed {
    color: #000000 !important;
    font-weight: 600;
    font-size: 20px;
    background: #f5f5f5 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
}
.FAQ .faq-main.accordion .accordion-button:focus {
    z-index: 3;
    border-color: white !important;
    outline: 0;
    box-shadow: none !important;
}
.FAQ .faq-main.accordion .accordion-item {
    border: none !important;
}
.FAQ .faq-main.accordion .accordion-body {
    color: #000000;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
}
.FAQ .faq-main.accordion .accordion-button:not(.collapsed)::after {
    background-image: url("../../../public/assests/Policybanners/minus.png") !important;
    background-color: white;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: 12px 1px;
}
.FAQ .faq-main.accordion .accordion-button::after {
    background-image: url("../../../public/assests/Policybanners/plus.png") !important;
    background-color: white;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
}




/*********************** Media Query started ******************************/

@media (min-width:0px) and (max-width:320px) {
    .FAQ .faq-main.accordion .accordion-button:not(.collapsed) {
       font-size: 15px;
       padding: 11px 6px;
    }
    .FAQ .faq-main.accordion button.accordion-button.collapsed {
        font-size: 15px;
        padding: 11px 6px;
    }
    .FAQ .faq-main.accordion .accordion-body {
        text-align: justify;
        font-size: 13px;
    }
    section.FAQ {
      margin-bottom: 23%;
    }
    .FAQ .faq-banner {
       margin-bottom: 9%;
    }
}
@media (min-width:321px) and (max-width:485px) {
    .FAQ .faq-main.accordion .accordion-button:not(.collapsed) {
        font-size: 16px;
        padding: 15px 6px;
     }
     .FAQ .faq-main.accordion button.accordion-button.collapsed {
         font-size: 16px;
         padding: 15px 6px;
     }
     .FAQ .faq-main.accordion .accordion-body {
         text-align: justify;
         font-size: 14px;
     }
     section.FAQ {
        margin-bottom: 23%;
      }
      .FAQ .faq-banner {
        margin-bottom: 6%;
    }
}
@media (min-width:486px) and (max-width:767px) {
    .FAQ .faq-main.accordion .accordion-button:not(.collapsed) {
        font-size: 17px;
        padding: 15px 10px;
     }
     .FAQ .faq-main.accordion button.accordion-button.collapsed {
         font-size: 17px;
         padding: 15px 10px;
     }
     .FAQ .faq-main.accordion .accordion-body {
         text-align: justify;
         font-size: 15px;
     }
     section.FAQ {
        margin-bottom: 23%;
      }
      .FAQ .faq-banner {
        margin-bottom: 4%;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .FAQ .faq-main.accordion .accordion-button:not(.collapsed) {
        font-size: 18px;
        padding: 15px 13px;
     }
     .FAQ .faq-main.accordion button.accordion-button.collapsed {
         font-size: 18px;
         padding: 15px 13px;
     }
     .FAQ .faq-main.accordion .accordion-body {
         text-align: justify;
         font-size: 17px;
     }
     section.FAQ {
        margin-bottom: 23%;
      }
}